
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Column, useFilters, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import { IconCheck, IconDots, IconFilter, IconPlus, IconSearch, IconX } from '@tabler/icons-react'
import { Box, Button, Divider, Drawer, IconButton, InputAdornment, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { formatDate, theme } from '~/utils'
import { useDebounce, useToggle } from '~/hooks'
import { useIndications } from '~/contexts/indications'
import { Card, CheckboxMultiple, ContentTitle, Input, OriginStatus, IndicationsStatus, TableApi, TableHeaderApi, Dropdown } from '~/components'
import { useNavigate } from 'react-router-dom'
import { indicationOriginStatusOptions, indicationsStatusOptions } from '~/utils/options'
import { EIndicationOrigin, EIndicationStatus, useGetIndicationProposalByIdLazyQuery } from '~/graphql/types'
import { usePartner } from '~/contexts/partner'
import { toast } from 'react-toastify'
import { StyledA, StyledLink } from '../PartnerIndications/styles'

type FilterTableData = {
  nameCustomerOrSalesman: string | null,
  status: EIndicationStatus[]
  origin: EIndicationOrigin[]
  begin: '' | null,
  end: '' | null,
}

export const SubAffiliateIndications: React.FC = () => {
  const navigate = useNavigate()
  const { myPartner } = usePartner()
  const { indicationsListIsLoading, paginationData, indications } = useIndications()
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const isLowerLg = useMediaQuery(theme.breakpoints.down('lg'))
  const formMethods = useForm<FilterTableData>()

  const { isTrue: isVisible, toggle: toggleModal } = useToggle(false)

  const [filterTable, setFilterTable] = useState<FilterTableData>({
    nameCustomerOrSalesman: '',
    status: [],
    origin: [],
    begin: null,
    end: null,
  })

  const [getProposalByIndicationId] = useGetIndicationProposalByIdLazyQuery({
    onCompleted(data) {
      if(data.getIndicationProposalById.preProposals) {
        const fileKey = data.getIndicationProposalById.preProposals[data.getIndicationProposalById.preProposals?.length - 1].file.key
        window.open(`${process.env.VITE_APP_REST_API_URL}files/download/file/${fileKey}`)
      }
    },
    onError(error) {
      toast.error(error.message)
    },
  })

  const debouncedFilterTable = useDebounce(filterTable, 1000)

  useEffect(() => {
    paginationData.setFiltersTable(filterTable)
    paginationData.setMetadata((prev) => ({ ...prev, currentPage: 0 }))
  }, [debouncedFilterTable])

  const columns = useMemo((): Column[] => {
    return [
      {
        Header: 'Cliente',
        accessor: 'customerRef.name',
        Cell: ({ value }) => <Tooltip title={value}><div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', width: '100%' }}>{value}</div></Tooltip>,
        
      },
      {
        Header: <div style={{ textAlign: 'center' }}>Origem</div>,
        accessor: 'origin',
        Cell: ({ value }) => {
          return <div style={{ textAlign: 'center' }}><OriginStatus status={value} /></div>
        },
        minWidth: isLowerLg ? undefined : 80,
      },
      {
        Header: <div style={{ textAlign: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Criado em</div>,
        accessor: 'createdAt',
        Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{formatDate(value)}</div>,
        minWidth: isLowerLg ? undefined : 160,
      },
      {
        Header: <div style={{ textAlign: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Atualizado em</div>,
        accessor: 'updatedAt',
        Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{formatDate(value)}</div>,
        minWidth: isLowerLg ? undefined : 160,
      },
      {
        Header: <div style={{ textAlign: 'start' }}>Status</div>,
        accessor: 'status',
        
        Cell: ({ value, row }: any) => <div style={{ textAlign: 'start' }}><IndicationsStatus errorMessage={row.original?.errorMessage} status={value} /></div>,
        minWidth: isLowerLg ? undefined : 150,

      },
      {
        Header: 'Ações',
        accessor: '_id',
        
        Cell: ({ value, row }: any) => {
          return (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              {EIndicationStatus.requestedProposal === row.original.status ? (
                <Dropdown $options={{ left: '-98px' }} elementClick={<IconButton><IconDots /></IconButton>}>
                  <Card sx={{ width: '100%', padding: '1rem' }}>
                    <StyledA onClick={() => getProposalByIndicationId({ variables: { id: value } })}>Baixar Proposta</StyledA>
                    <StyledLink to={`/app/indications/update/${value}`}>Atualizar proposta</StyledLink>
                  </Card>
                </Dropdown>
              ) : 
                <IconButton><IconDots /></IconButton>
              }
            </Box>
            
          )
        },
        minWidth: isLowerLg ? undefined : 70,
      },
    ]
  }, [])
  
  const dataWithMemo = useMemo(() => indications || [], [indications])

  const tableMethods = useTable({
    columns,
    data: dataWithMemo,
    manualPagination: true,
    initialState: {
      hiddenColumns: []
    },
    defaultColumn: {
      Filter: <></>,
    },
  },
  useFilters,
  useSortBy,
  usePagination,
  useRowSelect,
    //disable checkboxColumn
    // (hooks) => renderTableCheckbox(hooks)
  )

  const onApplyFilters = useCallback((formData: FilterTableData) => {
    setFilterTable(formData)
    toggleModal()
  }, [toggleModal, filterTable])

  const onRemoveFilters = useCallback(() => {
    formMethods.reset({
      begin: null,
      end: null,
      status: [],
      nameCustomerOrSalesman: null
    })
    setFilterTable({ nameCustomerOrSalesman: '', status: [], origin: [], begin: null, end: null })
    toggleModal()
  }, [toggleModal])

  useEffect(() => {
    if(isVisible) {
      formMethods.setValue('nameCustomerOrSalesman', filterTable.nameCustomerOrSalesman)
    }
  }, [isVisible])

  return (
    <Card>
      <ContentTitle
        title='Suas Indicações'
        style={{ padding: '0px 0px 1.6rem 0px' }}
        description='Acompanhe os indicados e solicite proposta pra gerar indicação'
        breadcrumbLinks={{ currentLink: 'Suas indicações', links: [{ href: '/app/indications', label: 'Área do Afiliado' }] }}
        rightContent={(
          <Box sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'end',
            gap: isLowerSm ? 1 : 3,
            flexFlow: isLowerSm ? 'column' : 'row',
            alignItems: isLowerSm ? 'none' : 'center'
          }}>
            {myPartner?.configuration.allowManualProposal &&(
              <Button
                endIcon={<IconPlus size={24} />}
                onClick={() => navigate('/app/indications/create')}
              >
                Solicitar proposta
              </Button>
            )}
          </Box>
        )}
      />

      <TableHeaderApi
        startElement={(
          <>
            <Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
              <TextField
                fullWidth
                sx={{ maxWidth: '400px' }}
                value={filterTable?.nameCustomerOrSalesman}
                onChange={(e) => setFilterTable({ ...filterTable, nameCustomerOrSalesman: e.target.value })}
                placeholder='Busque por nome do cliente ou vendedor'
                InputProps={{
                  endAdornment: (
                    <>
                      <InputAdornment position='end'>
                        <IconSearch />
                      </InputAdornment>
                    </>
                  )
                }}
              />

              <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>  
                <Button
                  color='secondary'
                  onClick={toggleModal}
                  startIcon={<IconFilter size={24} />}
                >
                  {paginationData?.filtersTable && Object.values(paginationData.filtersTable).filter(item => item).filter(item => item.length > 0)?.length || ''} Filtros
                </Button>
              </Box>
            </Box>

            <Drawer PaperProps={{ sx: { maxWidth: '560px', width: '100%', padding: '2rem' } }} anchor='right' open={isVisible} onClose={toggleModal}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography fontWeight={500} variant='h5'>Filtrar por</Typography>
                <IconButton type='button' onClick={toggleModal}>
                  <IconX />
                </IconButton>
              </Box>

              <FormProvider {...formMethods}>
                <form onSubmit={formMethods.handleSubmit((formData) => onApplyFilters(formData))}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: 4, gap: 4 }}>
                      <Typography variant='h5' fontWeight={500}>Cliente</Typography>
                      <Input placeholder='Nome do cliente' fullWidth name='nameCustomerOrSalesman' icons={{ end: { element: <IconSearch size={24} /> } }} />
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                      <Typography variant='h5' fontWeight={500}>Período de indicação</Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                        <Input placeholder='Data inicial' fullWidth name='begin' type='date' />
                        <Typography>Até</Typography>
                        <Input fullWidth name='end' type='date' />
                      </Box>
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant='h5' fontWeight={500}>Status</Typography>
                      <CheckboxMultiple name='status' options={indicationsStatusOptions} />
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant='h5' fontWeight={500}>Origem de indicação</Typography>
                      <CheckboxMultiple name='origin' options={indicationOriginStatusOptions} />
                    </Box>

                    <Divider />

                    <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'flex-end' }}>
                      <Button onClick={onRemoveFilters} type='button' color='secondary'>Limpar</Button>
                      <Button type='submit' color='primary' startIcon={<IconCheck size={24} />}>Aplicar</Button>
                    </Box>
                  </Box>
                </form>
              </FormProvider>
            </Drawer>
          </>
        )}
      />
      <TableApi
        tableLayout={isLowerSm ? 'auto' : 'fixed'}
        columns={columns}
        tableMethods={tableMethods}
        paginationData={paginationData}
        isLoading={indicationsListIsLoading}
      />
    </Card>
  )
}
