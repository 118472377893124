import styled from '@emotion/styled'
import { Stepper } from '@mui/material'

const getBackground = (props: CustomIconContainerProps) => {
  if (props.error) {
    return '#efe8ef'
  }

  if (props.completed) {
    return 'rgba(122, 191, 255, 0.25)'
  }

  if (props.active) {
    return '#3BA1FF'
  }

  return '#E6E6E6'
}

export type CustomIconContainerProps = {
  active: boolean
  completed: boolean
  error?: boolean
}
export const CustomIconContainer = styled.div<CustomIconContainerProps>`
  width: 30px;
  height: 30px;
  display: flex;
  border-radius: 4px;
  align-items: center; 
  justify-content: center;
  background: ${props => getBackground(props)};

  @media(max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    width: 24px;
    height: 24px;

    button {
      padding: 0px !important;
    }

    p {
      font-size: .95rem;
    }
  }
`

export const StyledStepper = styled(Stepper)`
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  padding: 1rem 0 2rem 0;
  margin-bottom: 20px;

  &.MuiStepLabel-label {
    font-size: 16px;
    font-weight: 500;
    color: #CDCDCD;
  };
  &.MuiStepLabel-label.Mui-active {
    font-size: 16px;
    font-weight: 600;
    color: #434343;
  };
  &.MuiStepLabel-label.Mui-completed:not(&.Mui-active) {
    font-size: 16px;
    color: #CDCDCD
  }

  @media(max-width: 1000px) {
    overflow-x: auto;
    max-width: calc(100vw - 180px);
    
    &::-webkit-scrollbar {
      height: 6px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px ${(props) => props.theme.palette.grey[300]};
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.palette.grey[500]};
      border-radius: 10px;
    }
  }

  @media(max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    overflow-x: auto;
    max-width: calc(100vw - 130px);
  }

  @media(max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    padding: 0;
    &::-webkit-scrollbar {
      height: 6px;
    }
  }
`
