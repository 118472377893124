import { Box } from '@mui/material'
import { Card, ContentTitle } from '~/components'

export const Dashboard: React.FC = () => {
  return (
    <Box sx={{ minHeight: '100vh', height: '100%' }}>
      <Card>

        <ContentTitle
          title='Dashboard'
          style={{ padding: '0px 0px 1.6rem 0px' }}
          breadcrumbLinks={{ currentLink: 'Dashboard', links: [{ href: '/app', label: 'Área do Afiliado' }] }}
        />
      </Card>
    </Box>
  )
}
