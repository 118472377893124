import { Typography } from '@mui/material'
import { Card } from '~/components'
import { LoginBackground } from './styles'
import { FirstAccessForm } from './components'
import DespertaLogo from '~/assets/images/logo/desperta-logo.svg'

export const FirstAccess: React.FC = () => {

  return (
    <>
      <LoginBackground>
        <Card sx={{ width: '100%', maxWidth: '470px' }}>
          <img style={{ alignSelf: 'start' }} src={DespertaLogo} height={40} />

          <Typography
            variant='h5'
            fontWeight={600}
            margin='24px 0px 8px 0px'
          >
            Definição de senha
          </Typography>

          <Typography
            variant='body1'
            color='grey.400'
            margin='9px 0px 0px 0px'
            sx={{ maxWidth: '300px' }}
          >
            Defina uma senha de acesso para sua área do sub-afiliado
          </Typography>

          <FirstAccessForm />

        </Card>
      </LoginBackground>
    </>
  )
}
