import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

export const StyledA = styled.a`
  color: ${(props) => props.theme.palette.grey[800]};
  cursor: pointer;
  transition: color .2s ease-in-out;
  
  &:hover {
    color: ${(props) => props.theme.palette.info.dark};
  }
`

export const StyledLink = styled(Link)`
  color: ${(props) => props.theme.palette.grey[800]};
  cursor: pointer;
  transition: color .2s ease-in-out;
  text-decoration: none;
  width: 100%;
  white-space: nowrap;
  
  &:hover {
    color: ${(props) => props.theme.palette.info.dark};
  }
`

