import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react'
import { useApiPagination } from '~/hooks'
import { ComissionsContextData, ComissionsListData, ComissionsMetricsData } from './types'
import { useGetCommissionsWithMetricsQuery } from '~/graphql/types'
import { toast } from 'react-toastify'

const ComissionsContext = createContext({} as ComissionsContextData)

export const ComissionsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [comissions, setComissions] = useState<ComissionsListData>([])
  const [metricsComissions, setMetricsComissions] = useState<ComissionsMetricsData | null>(null)
  const paginationData = useApiPagination({ pageSize: 20, currentPage: 0 })

  const { data, loading: comissionsListIsLoading } = useGetCommissionsWithMetricsQuery({
    variables: {
      params: {
        pageSize: paginationData.metadata.pageSize,
        pageNumber: paginationData.metadata.currentPage,
        filter: {
          ...paginationData.filtersTable?.nameCustomerOrSalesman?.length > 0 ? { nameCustomerOrSalesman: paginationData.filtersTable?.nameCustomerOrSalesman } : {},
          ...paginationData.filtersTable?.status?.length > 0 ? { status: paginationData.filtersTable?.status } : {},
          ...paginationData.filtersTable?.begin?.length > 0 ? { begin: new Date(paginationData.filtersTable?.begin) } : {},
          ...paginationData.filtersTable?.end?.length > 0 ? { end: new Date(`${paginationData.filtersTable?.end}Z23:59:59`) } : {}
        },
        sort: {
          field: 'createdAt',
          order: -1
        },
      },
      metricParams: {
        pageSize: paginationData.metadata.pageSize,
        pageNumber: paginationData.metadata.currentPage,
        filter: {
          ...paginationData.filtersTable?.nameCustomerOrSalesman?.length > 0 ? { nameCustomerOrSalesman: paginationData.filtersTable?.nameCustomerOrSalesman } : {},
          ...paginationData.filtersTable?.status?.length > 0 ? { status: paginationData.filtersTable?.status } : {},
          ...paginationData.filtersTable?.begin?.length > 0 ? { begin: new Date(paginationData.filtersTable?.begin) } : {},
          ...paginationData.filtersTable?.end?.length > 0 ? { end: new Date(`${paginationData.filtersTable?.end}Z23:59:59`) } : {}
        },
        sort: {
          field: 'createdAt',
          order: -1
        },
      }
    },
    onError(error) {
      toast.error(error.message)
    },
    onCompleted(dataResponse) {
      paginationData.setMetadata({ ...paginationData.metadata, documentCounts: dataResponse.getCommissions.meta.documentCounts })
    },
  })

  useEffect(() => {
    if(data) {
      setComissions(data.getCommissions.data as ComissionsListData)
      setMetricsComissions(data.getCommissionMetrics)
    }
  }, [data])

  return (
    <ComissionsContext.Provider value={{
      comissions,
      setComissions,
      paginationData,
      comissionsListIsLoading,
      metricsComissions,
      setMetricsComissions
    }}>
      {children}
    </ComissionsContext.Provider>
  )
}

export const useComissions = () => useContext(ComissionsContext)
