import { Box, Typography, useMediaQuery } from '@mui/material'

import { theme } from '~/utils'
import { ApiKeySectionCard, WebhooksSectionCard } from './components'

export const IntegrationArea: React.FC = () => {
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box sx={{ display: 'flex', flexFlow: 'column', gap: 3 }}>
      <Typography fontSize={isLowerSm ? '1rem' : '1.5rem'} variant='h3' fontWeight={500}>
        Área de integração
      </Typography>

      <ApiKeySectionCard />

      <WebhooksSectionCard />
    </Box>
  )
}
