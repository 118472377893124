/* eslint-disable no-nested-ternary */
import styled from '@emotion/styled'
import { darken } from 'polished'

export const Container = styled.div`
  position: relative;
  width: 100%;

  a.eyeIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 999;
    width: 42px;
    height: 42px;
    background: #F0FAF6;
    border-radius: .3rem;
    color: ${(props) => props.theme.palette.text.secondary};
    transition: background .2s ease-in-out;

    &:hover {
      background: ${darken('0.1', '#F0FAF6')};
    }

  }
`
type ContainerDropProps = {
  $isDragActive: boolean
  $hasError: boolean
  $isValid: boolean
  $qtdFiles: number
  $variant: 'primary' | 'secondary'
  $completed?: boolean
  disabled: boolean
  $isRejected?: boolean
}

const variantStyle = (props: ContainerDropProps) => {
  if (props.$variant === 'secondary') {
    return `
      border: 2px dashed #3BA1FF;
      background: ${props.$isDragActive ? darken('0.05', '#F0F5FB') : '#F0F5FB'};
    `
  }

  return `
    border: 2px dashed #D4DADF;
    background: ${props.$isDragActive ? darken('0.05', '#F4F6F8') : '#F4F6F8'};
  `
}

const cursorStyle = (props: ContainerDropProps) => {
  if (props.$isRejected) {
    return 'pointer'
  }
  if (props.$completed ||  props.disabled) {
    return 'default'
  }

  return 'pointer'
}

export const ContainerDrop = styled.div<ContainerDropProps>`
  display: flex;
  padding: 2rem;
  gap: 2rem;
  cursor: ${props => cursorStyle(props)};
  transition: background .1s ease-in-out;
  border-radius: 1rem;
  align-items: center;
  height: ${props => props.$completed ? 'auto' : props.$qtdFiles * 38 + 140}px;

  ${props => variantStyle(props)};


  ${props => props.disabled && `
    background: #FAFAFA;
    border: 2px dashed #999999;
  `}
  
  ${props => props.$completed && `
    background: ${props.$isDragActive ? darken('0.05', '#00A86B') : '#F0FAF6'};
    border: 2px dashed #00A86B;
  `}

  ${props => props.$isValid && `
    background: ${props.$isDragActive ? darken('0.05', '#00A86B') : '#F0FAF6'};
    border: 2px dashed '#00A86B';
  `}

  ${props => props.$hasError && `
    background: ${props.$isDragActive ? darken('0.05', '#00A86B') : '#F0FAF6'};
    border: 2px dashed '#00A86B';
  `}



  ${props => props.$isRejected && `
    background: ${props.$isDragActive ? darken('0.05', 'rgba(239, 68, 68, 0.1)') : 'rgba(239, 68, 68, 0.1)'};
    border: 2px dashed #EF4444;
  `}

  @media(max-width: 980px) {
    flex-flow: ${props => props.$completed ? 'row' : 'column'};
    align-items: center;
    justify-content: flex-start;
    height: ${props => props.$completed ? 'auto' : props.$qtdFiles * 36 + 220}px;
    gap: .4rem;
  }
`

export const RenderFiles = styled.ul`
  display: flex;
  margin: 0px;
  padding: 0px;

  li {
    display: flex;
    align-items: center;
    gap: .6rem;

    span {
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: ''
    }

    font-size: 1rem;
    color: #777;

    button {
      display: flex;
      align-items: center;
      gap: .2rem;
      background: transparent;
      border: none;
      color: #777;
      cursor: pointer;
      font-size: 1rem;
    }
  }

  @media(max-width: 980px) {
    top: 11rem;
    left: 1rem;
    li {
      max-width: 280px;
      svg {
        min-width: 20px;
      }
    }
  }
`

export type ContentDropProps = {
  $variant: 'primary' | 'secondary'
  $completed?: boolean
  disabled: boolean
}
export const ContentDrop = styled.div<ContentDropProps>`
  display: flex;
  flex-flow: column;
  gap: .6rem;

  strong {
    font-size: 1rem;
    font-weight: 500;
    color: ${(props) => props.disabled ? '#999999' : props.$completed ? '#999999' : props.$variant === 'secondary' ? '#3BA1FF' : '#777'};
  }

  span {
    font-size: 1rem;
    color: ${(props) => props.disabled ? '#999999' : props.$completed ? '#999999' : props.$variant === 'secondary' ? '#3BA1FF' : '#777'};
  }
`
