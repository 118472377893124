import { Box, Button, CircularProgress, Divider, IconButton, TextField, Typography, useMediaQuery } from '@mui/material'
import { IconCheck, IconLink, IconPencil, IconWebhook, IconX } from '@tabler/icons-react'
import { theme } from '~/utils'
import { Card } from '~/components'
import { useToggle } from '~/hooks'
import { useCallback, useEffect, useState } from 'react'
import { usePartner } from '~/contexts/partner'
import { useUpdatePartnerWebhookMutation } from '~/graphql/types'
import { toast } from 'react-toastify'

export const WebhooksSectionCard: React.FC = () => {
  const { myPartner, setMyPartner } = usePartner()
  const [webhookURL, setWebhookURL] = useState('Ex: http://salesforce.production.despertaenergia')
  const { isTrue: canEdit, toggle } = useToggle(false)
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const [updatePartnerWebook, { loading: updatePartnerWebookIsLoading }] = useUpdatePartnerWebhookMutation({
    onError(error) {
      toast.error(error.message)
    },
    onCompleted(data) {
      if(myPartner) {
        toast.success('Sua URL de webhook foi atualizada!')
        setMyPartner({ ...myPartner, configuration: { ...myPartner.configuration, webhookUrl: data.updatePartnerWebhook.configuration.webhookUrl } })
        toggle()
      }
    },
  })

  const onUpdatePartnerWebhookUrl = useCallback(() => {
    updatePartnerWebook({ variables: {
      params: {
        url: webhookURL
      }
    } })
  }, [webhookURL])

  useEffect(() => {
    setWebhookURL(myPartner?.configuration.webhookUrl || 'Ex: http://salesforce.production.despertaenergia')
  }, [])

  return (
    <Card style={{
      padding: '24px',
      flexFlow: isLowerSm ? 'column' : 'row',
      gap: '28px',
      borderLeft: `6px solid ${theme.palette.yellow.light}`,
    }} >
      <Box sx={{
        display: 'flex',
        borderRadius: '50%',
        height: 40,
        width: 40,
        padding: 1,
        background: 'rgba(86, 24, 133, 0.06)'
      }}>
        <IconLink size={24} color='#9E78BC' />
      </Box>

      <Box sx={{
        display: 'flex',
        flexFlow: 'column',
        paddingTop: '8px',
        gap: 3,
        flexGrow: 1
      }}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '4px'
        }}>
          <IconWebhook size={24} />
          <Typography fontWeight={500} variant={isLowerSm ? 'body2' : 'body1'}>
            webhooks
          </Typography>
        </Box>
        <Typography color='grey.400' variant={isLowerSm ? 'body2' : 'body1'}>
          Informe o link webhook para fazer a comunicação automática entre nosso sistema e outra ferramenta.
        </Typography>

        <Divider />

        <Typography fontWeight={500} variant={isLowerSm ? 'body2' : 'body1'}>
          URL <span style={{ color: theme.palette.grey['400'] }}>Cole a URL abaixo</span>
        </Typography>

        <TextField
          sx={{
            display: 'flex',
            flexGrow: 1
          }}
          disabled={!canEdit}
          value={webhookURL}
          onChange={(e) => setWebhookURL(e.target.value)}
          InputProps={{
            sx: {
              background: canEdit ? 'white' : theme.palette.grey['100'],
              color: theme.palette.grey['400']
            },
            startAdornment: <IconButton disabled><IconWebhook size={24} /></IconButton>
          }}
        />

        <Box sx={{
          gap: 3,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: isLowerSm ? 'normal' : 'center',
          flexFlow: isLowerSm ? 'column' : 'row',
        }}>
          <Button
            onClick={toggle}
            color='secondary'
            size={isLowerSm ? 'small' : 'medium'}
            startIcon={canEdit ? <IconX size={24} /> : <IconPencil size={24} />}
          >
            {canEdit ? 'Cancelar edição' : 'Editar link'}
          </Button>
          <Button
            onClick={() => onUpdatePartnerWebhookUrl()}
            disabled={!canEdit || updatePartnerWebookIsLoading}
            startIcon={updatePartnerWebookIsLoading ? <CircularProgress size={24} color='inherit' /> : <IconCheck size={24} />}
            size={isLowerSm ? 'small' : 'medium'}
          >
            Confirmar
          </Button>
        </Box>
      </Box>
    </Card>
  )
}
