import { IconCheck, IconLink, IconPencil } from '@tabler/icons-react'
import { Box, Button, CircularProgress, Typography, useMediaQuery } from '@mui/material'
import { theme } from '~/utils'
import { PermissionItem, PermissionItemProps } from './components/PermissionItem'
import { FormProvider, useForm } from 'react-hook-form'
import { useCallback, useEffect } from 'react'
import { useUpdatePartnerDefaultConfigurationMutation } from '~/graphql/types'
import { toast } from 'react-toastify'
import { usePartner } from '~/contexts/partner'
import { MyPartnerData } from '~/contexts/partner/types'

type FormData = {
  allowAffiliateLink: boolean
  allowManualProposal: boolean
}

const permissions: PermissionItemProps[] = [
  {
    title: 'Link único',
    name: 'allowAffiliateLink',
    icon: <IconLink color='#999999' size={24} />,
    description: 'Permitir que os usuários visualizem e compartilhem seus links únicos de indicação'
  },
  {
    title: 'Indicações manuais',
    name: 'allowManualProposal',
    icon: <IconPencil color='#999999' size={24} />,
    description: 'Permitir que os usuários gerem indicações manuais através dos seus painéis'
  }
]

export const Permissions: React.FC = () => {
  const { setMyPartner, myPartner } = usePartner()
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const formMethods = useForm<FormData>()
  const [updatePartnerConfig, { loading: updatePartnerIsLoading }] = useUpdatePartnerDefaultConfigurationMutation({
    onCompleted(data) {
      if(myPartner) {
        const partnerUpdated: MyPartnerData = {
          ...myPartner,
          configuration: {
            ...myPartner.configuration,
            ...data.updatePartnerDefaultConfiguration.configuration
          } 
        }
        toast.success('Suas configurações foram salvas!')
        setMyPartner(partnerUpdated) 
      }
    },
    onError(error) {
      toast.error(error.message)
    },
  })

  const permissionsList = permissions.map(permission => 
    <PermissionItem key={permission.title} {...permission} />
  )

  const onSubmit = useCallback((data: FormData) => {
    updatePartnerConfig({
      variables: {
        params: data
      }
    })
  }, [])

  useEffect(() => {
    if(myPartner) {
      formMethods.setValue('allowAffiliateLink', myPartner.configuration.allowAffiliateLink)
      formMethods.setValue('allowManualProposal', myPartner.configuration.allowManualProposal)
    }
  }, [myPartner])

  return (
    <Box sx={{ display: 'flex', flexFlow: 'column', gap: 3 }}>
      <Typography fontSize={isLowerSm ? '1rem' : '1.5rem'} variant='h3' fontWeight={500}>
        Permissões
      </Typography>

      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit((data) => onSubmit(data))} style={{ display: 'flex', flexFlow: 'column', gap: '24px' }}>
          {permissionsList}

          <Button
            type='submit'
            startIcon={updatePartnerIsLoading ? <CircularProgress size={24} color='inherit' /> : <IconCheck size={24} />}
            disabled={updatePartnerIsLoading}
            sx={{ alignSelf: isLowerSm ? 'normal' : 'end', marginTop: 2 }}
          >
            Salvar alterações
          </Button>
        </form>
      </FormProvider>
    </Box>
  )
}
