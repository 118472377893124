import { Box, Typography, useMediaQuery } from '@mui/material'
import { IndicationsStatus } from '~/components'
import { formatDate, theme } from '~/utils'
import { CSSProperties } from 'react'
import { ProposalByIndicationFlow } from '~/contexts/indicationFlow/types'
import { EIndicationStatus } from '~/graphql/types'

export type IndicationProposalInformationsProps = {
  proposal: ProposalByIndicationFlow
  indicationStatus: EIndicationStatus
  align?: CSSProperties['alignItems']
}
export const IndicationProposalInformations: React.FC<IndicationProposalInformationsProps> = ({ proposal, indicationStatus, align = 'end' }) => {
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: isLowerSm ? 'flex-start' : align }}>
      <Box sx={{ display: 'flex', alignItems: isLowerSm ? 'flex-start' : 'center', gap: 1, flexFlow: isLowerSm ? 'column' : 'row' }}>
        <Typography color='grey.400'>Status da indicação:</Typography>
        <IndicationsStatus status={indicationStatus} />
      </Box>
      <Box sx={{ display: 'flex', alignItems: isLowerSm ? 'flex-start' : 'end', gap: 1, flexFlow: 'column' }}>
        <Typography variant='body2' color='grey.400'>Gerada em: <b>{formatDate(proposal.createdAt)}</b></Typography>
        <Typography variant='body2' color='grey.400'>por: <b>{proposal.indicationRef?.salesman.name}</b></Typography>
      </Box>
    </Box>
  )
}
