import Slider, { Settings, CustomArrowProps } from 'react-slick'
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react'
import { Container } from './styles'
import { HowItWorksBanner, HowItWorksBannerProps } from './components'

import HowItWorksFirstImage from '~/assets/images/banners/sub-affiliate-home/how-it-works-banner-1.png'
import HowItWorksSecondImage from '~/assets/images/banners/sub-affiliate-home/how-it-works-banner-2.png'
import HowItWorksThirdImage from '~/assets/images/banners/sub-affiliate-home/how-it-works-banner-3.png'

import 'slick-carousel/slick/slick.css'

const bannersContent: HowItWorksBannerProps[] = [
  {
    title: 'Compartilhe seu link',
    description: 'Você compartilha seu link de indicação com pessoas e/ou empresas.',
    backgroundImageUrl: HowItWorksFirstImage
  },
  {
    title: 'Seus indicados assinam',
    description: 'Pelo link compartilhado, seus indicados assinam energia mais barata e limpa.',
    backgroundImageUrl: HowItWorksSecondImage
  },
  {
    title: 'Recompensa pra você',
    description: 'Receba suas recompensas quando seu indicado pagar a 1ª fatura da Desperta!',
    backgroundImageUrl: HowItWorksThirdImage
  }
]

export const HowItWorksBanners: React.FC = () => {
  const SampleNextArrow = (props: CustomArrowProps) => {
    const { className, onClick } = props
    return (
      <button
        className={className}
        onClick={onClick}
      >
        <IconChevronRight size={24} color='#F9DC45' />
      </button>
    )
  }

  const SamplePrevArrow = (props: CustomArrowProps) => {
    const { className, onClick } = props
    return (
      <button
        className={className}
        onClick={onClick}
      >
        <IconChevronLeft size={24} color='#F9DC45' />
      </button>
    )
  }

  const settings: Settings = {
    arrows: true,
    infinite: true,
    autoplay: true,
    fade: true,
    autoplaySpeed: 5000,
    speed: 500,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    verticalSwiping: false,
    pauseOnHover: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: 'progressive',
    dots: true,
  }

  const banners = bannersContent.map((banner, index) =>
    <HowItWorksBanner key={`how-it-works-banner-${index}`} {...banner} />
  )

  return (
    <Container>
      <Slider {...settings}>
        {banners}
      </Slider>
    </Container>
  )
}
