import { Box, Button, Divider, Typography, useMediaQuery } from '@mui/material'
import { IconCheck, IconHelpCircleFilled } from '@tabler/icons-react'
import { useCallback } from 'react'
import { toast } from 'react-toastify'
import { Dialog, DialogProps } from '~/components'
import { usePartner } from '~/contexts/partner'
import { useRegenerateApiKeyFromPartnerMutation } from '~/graphql/types'
import { theme } from '~/utils'

export const ApiKeyRedefinitionModal: React.FC<DialogProps> = ({ isVisible, onClose }) => {
  const { myPartner, setMyPartner } = usePartner()
  const [regenerateApiKey, { loading: regenerateApiKeyIsLoading }] = useRegenerateApiKeyFromPartnerMutation({
    onError(error) {
      toast.error(error.message)
    },
    onCompleted(data) {
      if(myPartner) {
        toast.success('Sua API key foi redefinida!')
        setMyPartner({ ...myPartner, configuration: { ...myPartner.configuration, apiKey: data.regenerateApiKeyFromPartner.configuration.apiKey } })
        onClose && onClose()
      }
    },
  })
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const onRegenerateApikey = useCallback(() => {
    regenerateApiKey()
  }, [])

  return (
    <Dialog
      isVisible={isVisible}
      title='Confirmar redefinição'
      onClose={onClose}
      minWidth={700}
      loading={regenerateApiKeyIsLoading}
      titleIcon={<IconHelpCircleFilled size={30} style={{ color: '#F3CC04' }} />}
    >
      <Box sx={{
        gap: 4,
        display: 'flex',
        flexFlow: 'column',
      }}>
        <Divider />

        <Typography variant={isLowerSm ? 'body2' : 'body1'}>
          <b>Redefinir a chave API</b> resultará na <b>desativação</b> da chave atual, afetando qualquer serviço ou integração que dependa dela.
        </Typography>

        <Typography variant={isLowerSm ? 'body2' : 'body1'}>
          Deseja continuar e redefinir a chave atual?
        </Typography>

        <Divider />

        <Box sx={{ flexFlow: isLowerSm ? 'column' : 'row', gap: 3, display: 'flex', alignSelf: isLowerSm ? 'normal' : 'end' }}>
          <Button
            color='secondary'
            onClick={onClose}
            size={isLowerSm ? 'small' : 'medium'}
          >
            Cancelar
          </Button>

          <Button
            onClick={() => onRegenerateApikey()}
            startIcon={<IconCheck size={24} />}
            size={isLowerSm ? 'small' : 'medium'}
          >
            Sim, redefinir
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}
