import { Box, useMediaQuery } from '@mui/material'
import { theme } from '~/utils'
import { ReferralLinkCard, HowItWorksBanners } from './components'
import { usePartner } from '~/contexts/partner'

export const HeaderSection: React.FC = () => {
  const { myPartner } = usePartner()
  const isLowerLg = useMediaQuery(theme.breakpoints.down('lg'))

  return (
    <Box sx={{
      gap: '32px',
      flexGrow: 1,
      display: 'flex',
      flexDirection: isLowerLg ? 'column' : 'row',
    }}>
      {myPartner?.configuration.allowAffiliateLink && (
        <>
          <ReferralLinkCard />
          <HowItWorksBanners />
        </>
      )}
    </Box>
  )
}
