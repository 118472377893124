import { useTheme } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, CircularProgress, Divider } from '@mui/material'
import { IconCircleFilled, IconQuestionMark } from '@tabler/icons-react'
import { FormProvider, useForm } from 'react-hook-form'
import { Dialog, Input } from '~/components'
import { DealershipPartnerData } from '~/contexts/dealershipsPartner/types'
import * as yup from 'yup'
import { useCallback, useEffect } from 'react'
import { useUpdatePartnerDiscountsOfferedMutation } from '~/graphql/types'
import { toast } from 'react-toastify'

type UpdateBenefitsModalProps = {
  isVisible: boolean
  onClose: Function
  selectedBenefit: DealershipPartnerData | null
  setSelectedBenefit: React.Dispatch<React.SetStateAction<DealershipPartnerData | null>>
  setDealershipConditions: React.Dispatch<React.SetStateAction<DealershipPartnerData[]>>
}

type FormData = {
  discountPF: number | null
  discountPJ: number | null
}

export const UpdateBenefitsModal: React.FC<UpdateBenefitsModalProps> = ({ isVisible, onClose, selectedBenefit, setSelectedBenefit, setDealershipConditions }) => {
  const theme = useTheme()

  const [updatePartnerBenefits, { loading: updatePartnerBenefitsIsLoading }] = useUpdatePartnerDiscountsOfferedMutation({
    onError(error) {
      toast.error(error.message)
    },
    onCompleted(data) {
      const newValues = data.updatePartnerDiscountsOffered
      clearAndClose()
      setDealershipConditions((prev) => prev.map(item => item.dealershipId === selectedBenefit?.dealershipId ? { ...item, customerPFDiscountOffered: newValues.customerPFDiscountOffered, customerPJDiscountOffered: newValues.customerPJDiscountOffered } : item))
      toast.success('O benefício da concessionária foi salvo!')
    },
  }) 

  const schema = yup.object({
    discountPF: yup.number().test('percentage', (value, { createError }) => {
      if(value && selectedBenefit?.customerPFDiscountMin && selectedBenefit?.customerPFDiscountMin > value) {
        return createError({
          message: `O percentual não deve ser menor de ${selectedBenefit?.customerPFDiscountMin}%`,
        })
      }
      if(value && selectedBenefit?.customerPFDiscountMax && selectedBenefit?.customerPFDiscountMax < value) {
        return createError({
          message: `O percentual não deve ser maior de ${selectedBenefit?.customerPFDiscountMax}%`,
        })
      }
      return true
    }).required(),
    discountPJ: yup.number().test('percentage', (value, { createError }) => {
      if(value && selectedBenefit?.customerPJDiscountMin && selectedBenefit?.customerPJDiscountMin > value) {
        return createError({
          message: `O percentual não deve ser menor de ${selectedBenefit?.customerPJDiscountMin}%`,
        })
      }
      if(value && selectedBenefit?.customerPJDiscountMax && selectedBenefit?.customerPJDiscountMax < value) {
        return createError({
          message: `O percentual não deve ser maior de ${selectedBenefit?.customerPJDiscountMax}%`,
        })
      }
      return true
    }).required(),
  }).required()

  const formMethods = useForm<FormData>({
    resolver: yupResolver(schema)
  })

  const clearAndClose = () => {
    onClose()
    setSelectedBenefit(null)
    formMethods.reset({
      discountPF: null,
      discountPJ: null,
    })
  }
  
  const onSubmit = useCallback((data: FormData) => {
    if(data.discountPF && data.discountPJ) {
      updatePartnerBenefits({
        variables: {
          params: {
            discountOffered: {
              dealershipId: selectedBenefit?.dealershipId || '',
              customerPFDiscountOffered: data.discountPF,
              customerPJDiscountOffered: data.discountPJ,
            }
          }
        }
      })
    } else {
      toast.error('Preencha os campos do formulário!')
    }
  }, [selectedBenefit])

  useEffect(() => {
    formMethods.setValue('discountPF', selectedBenefit?.customerPFDiscountOffered || 0)
    formMethods.setValue('discountPJ', selectedBenefit?.customerPJDiscountOffered || 0)
  }, [selectedBenefit])

  return (
    <Dialog 
      titleIcon={
        <Box sx={{ position: 'relative' }}>
          <IconCircleFilled style={{ color: theme.palette.primary.main }} size={36} />
          <IconQuestionMark color='white' style={{ position: 'absolute', top: '7px', left: '7px' }} size={22} />
        </Box>
      } 
      title={`Editar benefício padrão - ${selectedBenefit?.dealershipName}`} 
      isVisible={isVisible} 
      onClose={() => clearAndClose()}
    >
      <Divider />
      <FormProvider {...formMethods}>

        <form onSubmit={formMethods.handleSubmit((data) => onSubmit(data))}>
          <Box sx={{ display: 'flex', gap: '.8rem', padding: '2rem 0' }}>
            <Input mask='percentage' label='Benefício padrão PF' name='discountPF' />
            <Input mask='percentage' label='Benefício padrão PJ' name='discountPJ' />
          </Box>

          <Divider />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '.8rem', paddingTop: '1.2rem' }}>
            <Button onClick={() => clearAndClose()} color='secondary'>Cancelar</Button>
            <Button
              startIcon={updatePartnerBenefitsIsLoading && <CircularProgress size={24} color='inherit' /> }
              disabled={updatePartnerBenefitsIsLoading} 
              type='submit'
            >
              Salvar novo benefício
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Dialog>
  )
}
