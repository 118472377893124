import styled from '@emotion/styled'

export const Container = styled.div`
  border: 1px solid ${(props) => props.theme.palette.grey[300]};
  padding: 1rem;
  border-radius: 8px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  gap: .6rem;

  img {
    max-height: 60px;
    display: block;
    border: 1px solid ${(props) => props.theme.palette.grey[300]};
    padding: .4rem .8rem;
    border-radius: 10px;
  }

  strong {
    font-weight: 400;
    color: ${(props) => props.theme.palette.grey[800]};
  }

  & > span {
    color: ${(props) => props.theme.palette.grey[400]};
    font-size: 0.9rem;
  }
`
