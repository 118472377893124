import { Box, Typography, useMediaQuery } from '@mui/material'
import { Card } from '~/components'
import { usePartner } from '~/contexts/partner'
import { theme } from '~/utils'

export const ProfilePicture: React.FC = () => {
  const { myPartner } = usePartner()
  
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box sx={{ display: 'flex', flexFlow: 'column', gap: 3 }}>
      <Typography fontSize={isLowerSm ? '1rem' : '1.5rem'} variant='h3' fontWeight={500}>
        Imagem de exibição
      </Typography>

      <Card style={{ padding: '24px', flexFlow: isLowerSm ? 'column' : 'row', alignItems: 'center', gap: '32px' }}>
        <Box sx={{
          height: 140,
          width: 140,
          padding: 10,
          display: 'flex',
          borderRadius: '50%',
          backgroundSize: '140px',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          border: `4px solid ${theme.palette.yellow.dark}`,
          backgroundImage: `url(${'https://despertaenergia.com/logo/desperta-yellow.svg'})`,
        }} />

        <Box sx={{ display: 'flex', flexFlow: 'column' }}>
          <Typography variant={isLowerSm ? 'body1' : 'h5'} sx={{ marginBottom: 2, textAlign: isLowerSm ? 'center' : 'start' }} fontWeight={500}>
            {myPartner?.name}
          </Typography>
          <Typography variant={isLowerSm ? 'body2' : 'body1'}>
            Sua imagem de exibição
          </Typography>
          <Typography variant={isLowerSm ? 'body3' : 'body2'} color='grey.400' sx={{ margin: '8px 0px' }}>
            Esta é a imagem que aparece nos e-mails institucionais ligados a você.
          </Typography>
          <Typography variant='body3' color='grey.400'>
            Para alterá-la, entre em contato com nossa equipe.
          </Typography>
        </Box>
      </Card>
    </Box>
  )
}
