import { SvgIcon, SvgIconProps } from '@mui/material'

export const ManWithForm: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width={332} height={281} viewBox='0 0 332 281' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path fillRule='evenodd' clipRule='evenodd' d='M168.186 263.983C232.979 263.378 298.21 241.54 320.564 190.819C345.553 134.117 329.939 66.6021 270.272 32.3479C214.119 0.111481 142.767 23.6266 87.691 57.1307C33.0388 90.3774 -15.962 140.109 4.91967 193.77C25.8167 247.47 100.494 264.616 168.186 263.983Z' fill='url(#paint0_linear_10832_18884)' fillOpacity='0.5' />
      <path d='M258 281C291.689 281 319 276.747 319 271.5C319 266.253 291.689 262 258 262C224.311 262 197 266.253 197 271.5C197 276.747 224.311 281 258 281Z' fill='#CDCDCD' fillOpacity='0.4' />
      <path d='M227.601 45.8076H74.4257C70.9645 45.8076 68.1587 48.6131 68.1587 52.0739V232.41C68.1587 235.871 70.9645 238.677 74.4257 238.677H227.601C231.062 238.677 233.868 235.871 233.868 232.41V52.0739C233.868 48.6131 231.062 45.8076 227.601 45.8076Z' fill='#6600B4' />
      <path opacity='0.73' d='M227.601 45.8076H74.4257C70.9645 45.8076 68.1587 48.6131 68.1587 52.0739V232.41C68.1587 235.871 70.9645 238.677 74.4257 238.677H227.601C231.062 238.677 233.868 235.871 233.868 232.41V52.0739C233.868 48.6131 231.062 45.8076 227.601 45.8076Z' fill='#EDE9E9' />
      <path d='M207.027 134.264H95.0078V153.439H207.027V134.264Z' fill='white' />
      <g opacity='0.4'>
        <path d='M105.024 145.718V146.223H101.138V140.727H104.904V141.224H101.72V143.184H104.562V143.672H101.72V145.718H105.024Z' fill='#263238' />
        <path d='M105.804 143.852H107.867V144.331H105.804V143.852Z' fill='#263238' />
        <path d='M115.923 143.826V146.223H115.366V143.877C115.366 143.021 114.93 142.559 114.185 142.559C113.992 142.55 113.799 142.584 113.62 142.657C113.441 142.73 113.281 142.842 113.149 142.983C113.018 143.125 112.919 143.294 112.86 143.478C112.801 143.662 112.783 143.857 112.807 144.049V146.223H112.242V143.877C112.242 143.021 111.805 142.559 111.052 142.559C110.859 142.55 110.666 142.584 110.487 142.657C110.308 142.73 110.147 142.842 110.016 142.983C109.885 143.125 109.786 143.294 109.727 143.478C109.668 143.662 109.649 143.857 109.673 144.049V146.223H109.108V142.088H109.648V142.842C109.809 142.589 110.034 142.384 110.3 142.245C110.566 142.107 110.864 142.041 111.163 142.054C111.465 142.028 111.768 142.097 112.03 142.25C112.293 142.403 112.501 142.634 112.627 142.91C112.798 142.634 113.04 142.409 113.327 142.259C113.615 142.108 113.938 142.038 114.262 142.054C114.494 142.036 114.728 142.071 114.945 142.156C115.162 142.24 115.357 142.372 115.517 142.542C115.676 142.712 115.795 142.916 115.866 143.138C115.936 143.36 115.955 143.595 115.923 143.826Z' fill='#263238' />
        <path d='M120.563 143.655V146.223H120.024V145.572C119.871 145.804 119.658 145.99 119.408 146.11C119.158 146.231 118.879 146.281 118.603 146.257C117.67 146.257 117.096 145.769 117.096 145.059C117.096 144.348 117.507 143.886 118.688 143.886H119.998V143.637C119.998 142.927 119.604 142.542 118.834 142.542C118.331 142.536 117.843 142.709 117.456 143.03L117.207 142.619C117.691 142.23 118.299 142.029 118.92 142.054C119.138 142.027 119.36 142.05 119.568 142.12C119.777 142.191 119.967 142.307 120.125 142.461C120.282 142.614 120.404 142.801 120.48 143.008C120.555 143.214 120.584 143.435 120.563 143.655ZM119.998 144.981V144.305H118.706C117.909 144.305 117.644 144.613 117.644 145.041C117.644 145.469 118.029 145.82 118.688 145.82C118.968 145.839 119.246 145.767 119.481 145.616C119.717 145.465 119.898 145.243 119.998 144.981Z' fill='#263238' />
        <path d='M121.951 140.787C121.952 140.734 121.963 140.682 121.985 140.634C122.006 140.585 122.036 140.541 122.075 140.505C122.113 140.468 122.158 140.44 122.207 140.42C122.256 140.401 122.309 140.392 122.362 140.393C122.413 140.392 122.465 140.401 122.513 140.42C122.561 140.439 122.605 140.467 122.643 140.502C122.68 140.538 122.71 140.581 122.731 140.628C122.752 140.676 122.763 140.727 122.764 140.778C122.765 140.832 122.756 140.885 122.736 140.935C122.716 140.985 122.687 141.03 122.649 141.068C122.612 141.107 122.567 141.137 122.518 141.158C122.468 141.179 122.415 141.189 122.362 141.189C122.308 141.19 122.255 141.181 122.205 141.161C122.155 141.141 122.11 141.112 122.072 141.075C122.033 141.037 122.003 140.992 121.982 140.943C121.961 140.894 121.951 140.841 121.951 140.787ZM122.079 142.088H122.636V146.223H122.079V142.088Z' fill='#263238' />
        <path d='M124.186 140.393H124.742V146.223H124.186V140.393Z' fill='#263238' />
      </g>
      <path d='M207.027 160.459H95.0078V179.635H207.027V160.459Z' fill='white' />
      <g opacity='0.4'>
        <path d='M105.435 168.831C105.435 170.004 104.579 170.714 103.192 170.714H101.72V172.427H101.138V166.931H103.192C104.588 166.948 105.435 167.658 105.435 168.831ZM104.853 168.831C104.853 167.975 104.279 167.453 103.141 167.453H101.686V170.201H103.141C104.279 170.201 104.853 169.696 104.853 168.831Z' fill='#263238' />
        <path d='M109.656 169.884V172.452H109.117V171.802C108.963 172.033 108.751 172.219 108.5 172.34C108.25 172.461 107.972 172.511 107.696 172.487C106.763 172.487 106.189 171.999 106.189 171.28C106.189 170.561 106.6 170.09 107.781 170.09H109.091V169.833C109.091 169.131 108.689 168.746 107.918 168.746C107.418 168.739 106.932 168.912 106.549 169.234L106.292 168.814C106.779 168.434 107.385 168.237 108.004 168.258C108.225 168.229 108.451 168.251 108.662 168.322C108.874 168.394 109.067 168.512 109.226 168.669C109.385 168.825 109.507 169.016 109.581 169.227C109.656 169.438 109.681 169.662 109.656 169.884ZM109.1 171.203V170.526H107.781C106.985 170.526 106.728 170.843 106.728 171.271C106.728 171.699 107.105 172.042 107.773 172.042C108.055 172.062 108.336 171.992 108.575 171.841C108.814 171.69 108.998 171.466 109.1 171.203Z' fill='#263238' />
        <path d='M110.598 171.973L110.846 171.536C111.278 171.838 111.792 171.999 112.319 171.999C113.098 171.999 113.423 171.733 113.423 171.322C113.423 170.244 110.744 171.091 110.744 169.465C110.744 168.788 111.326 168.283 112.379 168.283C112.892 168.279 113.398 168.412 113.843 168.669L113.594 169.114C113.234 168.876 112.81 168.753 112.379 168.763C111.634 168.763 111.3 169.054 111.3 169.448C111.3 170.56 113.98 169.721 113.98 171.305C113.98 172.024 113.355 172.487 112.267 172.487C111.67 172.502 111.084 172.322 110.598 171.973Z' fill='#263238' />
        <path d='M114.434 171.973L114.69 171.536C115.117 171.841 115.63 172.003 116.154 171.999C116.942 171.999 117.259 171.733 117.259 171.322C117.259 170.244 114.588 171.091 114.588 169.465C114.588 168.788 115.17 168.283 116.214 168.283C116.728 168.277 117.234 168.41 117.678 168.669L117.438 169.114C117.075 168.877 116.648 168.755 116.214 168.763C115.469 168.763 115.144 169.054 115.144 169.448C115.144 170.561 117.824 169.722 117.824 171.305C117.824 172.024 117.19 172.487 116.111 172.487C115.511 172.501 114.923 172.321 114.434 171.973Z' fill='#263238' />
        <path d='M124.827 168.318L123.269 172.444H122.746L121.479 169.063L120.178 172.444H119.656L118.055 168.318H118.586L119.887 171.828L121.214 168.318H121.693L123.012 171.828L124.321 168.318H124.827Z' fill='#263238' />
        <path d='M125.136 170.381C125.136 169.964 125.259 169.557 125.491 169.211C125.722 168.865 126.051 168.595 126.436 168.435C126.821 168.276 127.244 168.234 127.653 168.315C128.061 168.397 128.436 168.597 128.731 168.892C129.025 169.186 129.226 169.561 129.307 169.97C129.389 170.378 129.347 170.802 129.187 171.187C129.028 171.571 128.758 171.9 128.412 172.132C128.066 172.363 127.658 172.487 127.242 172.487C126.963 172.496 126.685 172.448 126.425 172.346C126.165 172.243 125.929 172.089 125.732 171.891C125.534 171.694 125.379 171.458 125.277 171.198C125.174 170.938 125.126 170.66 125.136 170.381ZM128.774 170.381C128.761 170.079 128.659 169.788 128.481 169.543C128.304 169.299 128.058 169.112 127.775 169.006C127.492 168.9 127.184 168.88 126.89 168.948C126.595 169.015 126.327 169.168 126.119 169.387C125.911 169.606 125.771 169.881 125.718 170.178C125.665 170.476 125.7 170.782 125.82 171.06C125.94 171.337 126.138 171.573 126.391 171.739C126.644 171.904 126.94 171.992 127.242 171.99C127.451 171.994 127.658 171.955 127.851 171.874C128.044 171.794 128.218 171.674 128.362 171.523C128.506 171.371 128.617 171.192 128.688 170.995C128.759 170.798 128.788 170.589 128.774 170.381Z' fill='#263238' />
        <path d='M132.558 168.283V168.823H132.421C132.225 168.813 132.03 168.847 131.849 168.922C131.667 168.997 131.505 169.11 131.372 169.255C131.24 169.4 131.141 169.572 131.083 169.759C131.024 169.946 131.008 170.144 131.034 170.338V172.444H130.478V168.318H131.009V169.122C131.153 168.843 131.378 168.614 131.654 168.464C131.931 168.315 132.246 168.252 132.558 168.283Z' fill='#263238' />
        <path d='M137.292 166.666V172.487H136.762V171.673C136.596 171.944 136.361 172.166 136.081 172.316C135.801 172.467 135.487 172.54 135.169 172.53C134.635 172.495 134.134 172.259 133.768 171.869C133.402 171.478 133.198 170.963 133.198 170.428C133.198 169.893 133.402 169.377 133.768 168.987C134.134 168.597 134.635 168.36 135.169 168.326C135.483 168.319 135.792 168.395 136.067 168.545C136.342 168.695 136.573 168.915 136.736 169.182V166.666H137.292ZM136.745 170.424C136.731 170.122 136.629 169.831 136.452 169.586C136.274 169.342 136.028 169.155 135.745 169.049C135.462 168.943 135.155 168.923 134.86 168.99C134.566 169.058 134.298 169.211 134.089 169.43C133.881 169.648 133.741 169.924 133.688 170.221C133.635 170.519 133.671 170.825 133.79 171.102C133.91 171.38 134.109 171.616 134.361 171.781C134.614 171.947 134.91 172.034 135.212 172.033C135.425 172.038 135.637 171.997 135.833 171.914C136.029 171.831 136.205 171.707 136.35 171.551C136.494 171.395 136.605 171.21 136.673 171.008C136.741 170.807 136.765 170.593 136.745 170.381V170.424Z' fill='#263238' />
      </g>
      <path opacity='0.2' d='M207.027 199.195H95.0078V218.371H207.027V199.195Z' fill='black' />
      <g opacity='0.5'>
        <path d='M122.25 190.386H120.418L120.025 191.243H119.665L121.163 187.964H121.506L123.004 191.243H122.644L122.25 190.386ZM122.122 190.104L121.334 188.341L120.547 190.104H122.122Z' fill='black' />
        <path d='M123.483 187.784H123.817V191.26H123.483V187.784Z' fill='black' />
        <path d='M125.992 188.777V189.102H125.906C125.787 189.092 125.666 189.109 125.554 189.152C125.442 189.195 125.341 189.262 125.259 189.349C125.177 189.437 125.115 189.541 125.079 189.656C125.043 189.77 125.033 189.891 125.05 190.01V191.268H124.716V188.803H125.042V189.282C125.127 189.109 125.265 188.968 125.436 188.877C125.606 188.787 125.801 188.752 125.992 188.777Z' fill='black' />
        <path d='M128.714 190.13H126.617C126.624 190.25 126.654 190.367 126.706 190.475C126.759 190.584 126.832 190.68 126.922 190.76C127.012 190.84 127.117 190.901 127.23 190.939C127.344 190.978 127.465 190.994 127.584 190.986C127.727 190.991 127.868 190.965 128 190.91C128.131 190.856 128.249 190.773 128.346 190.669L128.526 190.883C128.349 191.069 128.121 191.198 127.871 191.255C127.621 191.312 127.359 191.293 127.12 191.201C126.88 191.11 126.673 190.95 126.524 190.741C126.375 190.532 126.291 190.283 126.283 190.027C126.275 189.864 126.3 189.702 126.357 189.549C126.415 189.397 126.503 189.258 126.616 189.14C126.729 189.023 126.865 188.93 127.015 188.868C127.166 188.806 127.327 188.775 127.49 188.777C127.653 188.773 127.815 188.803 127.966 188.865C128.116 188.928 128.252 189.02 128.365 189.138C128.478 189.256 128.565 189.395 128.621 189.548C128.676 189.701 128.7 189.864 128.689 190.027C128.689 190.027 128.714 190.096 128.714 190.13ZM126.651 189.882H128.363C128.363 189.654 128.273 189.437 128.113 189.276C127.952 189.116 127.734 189.025 127.507 189.025C127.28 189.025 127.063 189.116 126.902 189.276C126.742 189.437 126.651 189.654 126.651 189.882Z' fill='black' />
        <path d='M131.189 189.779V191.302H130.898V190.917C130.807 191.058 130.679 191.171 130.528 191.244C130.377 191.316 130.208 191.345 130.042 191.328C129.485 191.328 129.186 191.037 129.186 190.609C129.186 190.181 129.425 189.907 130.136 189.907H130.898V189.762C130.898 189.342 130.658 189.111 130.196 189.111C129.884 189.099 129.579 189.203 129.34 189.402L129.194 189.154C129.478 188.927 129.833 188.809 130.196 188.82C130.328 188.797 130.464 188.806 130.593 188.846C130.721 188.885 130.838 188.954 130.935 189.048C131.032 189.141 131.105 189.256 131.149 189.383C131.193 189.51 131.207 189.646 131.189 189.779ZM130.863 190.566V190.155H130.084C129.605 190.155 129.451 190.344 129.451 190.601C129.451 190.857 129.682 191.063 130.076 191.063C130.253 191.076 130.43 191.031 130.579 190.933C130.727 190.836 130.84 190.692 130.898 190.523L130.863 190.566Z' fill='black' />
        <path d='M134.322 187.784V191.26H134.005V190.772C133.908 190.935 133.768 191.069 133.601 191.16C133.434 191.25 133.245 191.294 133.055 191.285C132.74 191.259 132.447 191.116 132.233 190.884C132.019 190.651 131.9 190.347 131.9 190.031C131.9 189.716 132.019 189.411 132.233 189.179C132.447 188.947 132.74 188.803 133.055 188.777C133.241 188.772 133.424 188.814 133.589 188.9C133.754 188.985 133.894 189.111 133.997 189.265V187.784H134.322ZM133.997 190.027C133.988 189.848 133.928 189.675 133.822 189.53C133.716 189.385 133.571 189.275 133.403 189.212C133.235 189.149 133.052 189.137 132.877 189.177C132.702 189.217 132.543 189.307 132.419 189.437C132.296 189.567 132.213 189.73 132.181 189.906C132.149 190.083 132.169 190.265 132.239 190.43C132.31 190.594 132.427 190.735 132.577 190.834C132.726 190.933 132.901 190.986 133.081 190.986C133.208 190.993 133.336 190.974 133.455 190.928C133.575 190.883 133.683 190.812 133.773 190.721C133.863 190.631 133.932 190.522 133.977 190.402C134.021 190.282 134.04 190.154 134.031 190.027H133.997Z' fill='black' />
        <path d='M137.361 188.794L136.129 191.559C135.923 192.039 135.658 192.193 135.273 192.193C135.052 192.199 134.837 192.119 134.673 191.97L134.827 191.722C134.948 191.843 135.111 191.91 135.281 191.91C135.504 191.91 135.649 191.808 135.795 191.491L135.897 191.26L134.793 188.794H135.178L136.112 190.892L137.036 188.794H137.361Z' fill='black' />
        <path d='M140.923 189.779V191.302H140.606V190.917C140.515 191.058 140.387 191.171 140.236 191.244C140.085 191.316 139.917 191.345 139.75 191.328C139.194 191.328 138.894 191.037 138.894 190.609C138.894 190.181 139.134 189.907 139.844 189.907H140.623V189.762C140.623 189.342 140.392 189.111 139.93 189.111C139.618 189.099 139.314 189.203 139.074 189.402L138.92 189.154C139.207 188.927 139.564 188.809 139.93 188.82C140.062 188.797 140.198 188.806 140.327 188.846C140.455 188.885 140.573 188.954 140.669 189.048C140.766 189.141 140.839 189.256 140.883 189.383C140.927 189.51 140.941 189.646 140.923 189.779ZM140.589 190.566V190.155H139.819C139.339 190.155 139.185 190.344 139.185 190.601C139.185 190.857 139.416 191.063 139.81 191.063C139.981 191.07 140.149 191.021 140.29 190.923C140.43 190.826 140.535 190.686 140.589 190.523V190.566Z' fill='black' />
        <path d='M147.164 189.83V191.26H146.839V189.864C146.839 189.342 146.574 189.077 146.128 189.077C146.014 189.068 145.898 189.084 145.79 189.124C145.683 189.163 145.584 189.226 145.503 189.308C145.422 189.389 145.359 189.487 145.319 189.595C145.279 189.703 145.263 189.818 145.272 189.933V191.225H144.947V189.864C144.947 189.342 144.682 189.077 144.228 189.077C144.113 189.068 143.998 189.084 143.89 189.124C143.782 189.163 143.684 189.226 143.603 189.308C143.521 189.389 143.459 189.487 143.419 189.595C143.379 189.703 143.363 189.818 143.372 189.933V191.225H143.038V188.76H143.355V189.214C143.449 189.06 143.583 188.936 143.743 188.853C143.903 188.77 144.083 188.732 144.262 188.743C144.442 188.729 144.621 188.772 144.775 188.866C144.928 188.959 145.049 189.099 145.118 189.265C145.218 189.097 145.361 188.96 145.533 188.868C145.705 188.776 145.899 188.733 146.094 188.743C146.242 188.717 146.394 188.727 146.537 188.772C146.68 188.818 146.81 188.897 146.916 189.005C147.021 189.112 147.099 189.243 147.142 189.387C147.185 189.53 147.193 189.683 147.164 189.83Z' fill='black' />
        <path d='M150.195 190.13H148.132C148.15 190.372 148.262 190.598 148.445 190.758C148.627 190.918 148.866 191 149.108 190.986C149.249 190.991 149.39 190.966 149.52 190.911C149.65 190.856 149.767 190.774 149.862 190.669L150.05 190.883C149.93 191.014 149.782 191.118 149.619 191.188C149.455 191.257 149.278 191.29 149.1 191.285C148.93 191.296 148.76 191.272 148.6 191.213C148.441 191.154 148.296 191.062 148.174 190.943C148.052 190.825 147.956 190.682 147.893 190.524C147.83 190.366 147.801 190.197 147.807 190.027C147.807 189.709 147.933 189.404 148.158 189.18C148.383 188.955 148.688 188.829 149.005 188.829C149.323 188.829 149.628 188.955 149.853 189.18C150.078 189.404 150.204 189.709 150.204 190.027C150.204 190.027 150.195 190.096 150.195 190.13ZM148.132 189.882H149.887C149.887 189.649 149.795 189.426 149.63 189.261C149.466 189.097 149.242 189.004 149.01 189.004C148.777 189.004 148.554 189.097 148.389 189.261C148.225 189.426 148.132 189.649 148.132 189.882Z' fill='black' />
        <path d='M154.955 189.83V191.26H154.621V189.864C154.621 189.342 154.365 189.077 153.919 189.077C153.804 189.066 153.688 189.081 153.58 189.12C153.471 189.16 153.372 189.223 153.291 189.304C153.209 189.386 153.146 189.485 153.107 189.593C153.068 189.702 153.053 189.818 153.063 189.933V191.225H152.73V189.864C152.73 189.342 152.473 189.077 152.019 189.077C151.904 189.066 151.788 189.081 151.679 189.12C151.571 189.16 151.472 189.223 151.39 189.304C151.309 189.386 151.246 189.485 151.207 189.593C151.167 189.702 151.152 189.818 151.163 189.933V191.225H150.829V188.76H151.146V189.214C151.239 189.06 151.374 188.934 151.534 188.851C151.694 188.768 151.873 188.731 152.053 188.743C152.233 188.729 152.412 188.772 152.566 188.866C152.719 188.959 152.84 189.099 152.909 189.265C153.009 189.097 153.153 188.96 153.325 188.868C153.497 188.776 153.69 188.733 153.885 188.743C154.033 188.717 154.185 188.727 154.328 188.772C154.472 188.818 154.601 188.897 154.707 189.005C154.812 189.112 154.89 189.243 154.933 189.387C154.976 189.53 154.984 189.683 154.955 189.83Z' fill='black' />
        <path d='M158.363 190.027C158.371 190.192 158.346 190.356 158.287 190.51C158.229 190.664 158.14 190.805 158.025 190.923C157.91 191.041 157.772 191.134 157.62 191.197C157.467 191.259 157.303 191.289 157.139 191.285C156.949 191.294 156.76 191.25 156.593 191.16C156.426 191.069 156.286 190.935 156.189 190.772V191.26H155.863V187.784H156.197V189.265C156.299 189.11 156.44 188.984 156.604 188.899C156.769 188.813 156.953 188.771 157.139 188.777C157.303 188.772 157.467 188.801 157.619 188.863C157.772 188.924 157.91 189.017 158.025 189.134C158.14 189.251 158.229 189.391 158.287 189.545C158.346 189.699 158.371 189.863 158.363 190.027ZM158.029 190.027C158.021 189.848 157.96 189.675 157.854 189.53C157.749 189.385 157.603 189.275 157.435 189.212C157.267 189.149 157.084 189.137 156.91 189.177C156.735 189.217 156.576 189.307 156.452 189.437C156.328 189.567 156.245 189.73 156.213 189.906C156.181 190.083 156.201 190.265 156.272 190.43C156.342 190.594 156.459 190.735 156.609 190.834C156.759 190.933 156.934 190.986 157.113 190.986C157.238 190.988 157.362 190.965 157.477 190.917C157.592 190.87 157.696 190.798 157.782 190.708C157.868 190.618 157.935 190.511 157.977 190.394C158.02 190.276 158.037 190.151 158.029 190.027Z' fill='black' />
        <path d='M161.197 190.13H159.151C159.158 190.25 159.188 190.367 159.24 190.476C159.293 190.584 159.366 190.68 159.456 190.76C159.546 190.84 159.651 190.901 159.764 190.939C159.878 190.978 159.999 190.994 160.118 190.986C160.261 190.986 160.401 190.954 160.53 190.894C160.659 190.833 160.772 190.745 160.863 190.635L161.052 190.849C160.875 191.035 160.647 191.164 160.396 191.221C160.146 191.277 159.885 191.259 159.645 191.167C159.406 191.076 159.198 190.915 159.049 190.707C158.901 190.498 158.817 190.249 158.809 189.993C158.8 189.83 158.826 189.668 158.883 189.515C158.94 189.362 159.028 189.223 159.141 189.106C159.254 188.989 159.39 188.896 159.541 188.834C159.691 188.771 159.853 188.74 160.016 188.743C160.178 188.739 160.34 188.769 160.49 188.831C160.64 188.893 160.776 188.986 160.888 189.104C161 189.222 161.086 189.362 161.141 189.515C161.196 189.668 161.218 189.831 161.206 189.993L161.197 190.13ZM159.134 189.882H160.846C160.846 189.655 160.756 189.437 160.595 189.276C160.435 189.116 160.217 189.026 159.99 189.026C159.763 189.026 159.545 189.116 159.385 189.276C159.224 189.437 159.134 189.655 159.134 189.882Z' fill='black' />
        <path d='M163.123 188.777V189.102H163.046C162.927 189.092 162.807 189.109 162.694 189.152C162.582 189.194 162.481 189.262 162.399 189.349C162.317 189.436 162.255 189.541 162.219 189.656C162.183 189.77 162.173 189.891 162.19 190.01V191.268H161.856V188.803H162.173V189.282C162.26 189.11 162.398 188.969 162.568 188.879C162.738 188.788 162.932 188.753 163.123 188.777Z' fill='black' />
        <path d='M165.281 188.82C165.281 188.477 165.007 188.246 164.527 188.246C164.359 188.237 164.19 188.266 164.035 188.331C163.879 188.396 163.74 188.496 163.629 188.623L163.38 188.435C163.526 188.27 163.708 188.141 163.911 188.056C164.114 187.971 164.334 187.934 164.553 187.947C165.212 187.947 165.632 188.263 165.632 188.803C165.632 189.599 164.776 189.659 164.776 190.309H164.425C164.425 189.505 165.281 189.428 165.281 188.82ZM164.356 191.037C164.367 190.98 164.397 190.929 164.442 190.892C164.486 190.855 164.542 190.835 164.6 190.835C164.658 190.835 164.714 190.855 164.759 190.892C164.803 190.929 164.834 190.98 164.844 191.037C164.851 191.073 164.85 191.11 164.841 191.145C164.831 191.181 164.815 191.213 164.791 191.241C164.768 191.27 164.739 191.292 164.706 191.308C164.673 191.323 164.637 191.331 164.6 191.331C164.564 191.331 164.528 191.323 164.495 191.308C164.462 191.292 164.432 191.27 164.409 191.241C164.386 191.213 164.369 191.181 164.36 191.145C164.351 191.11 164.35 191.073 164.356 191.037Z' fill='black' />
        <path d='M167.601 187.981H168.363V190.635H170.007V191.251H167.601V187.981Z' fill='black' />
        <path d='M170.178 190.001C170.161 189.724 170.228 189.448 170.369 189.209C170.511 188.97 170.721 188.779 170.973 188.661C171.224 188.542 171.505 188.502 171.779 188.546C172.054 188.589 172.309 188.714 172.511 188.904C172.714 189.094 172.855 189.341 172.916 189.612C172.977 189.883 172.955 190.166 172.853 190.425C172.751 190.683 172.573 190.905 172.344 191.061C172.114 191.218 171.843 191.302 171.565 191.302C171.387 191.314 171.209 191.289 171.041 191.229C170.873 191.169 170.719 191.075 170.589 190.953C170.459 190.831 170.356 190.683 170.285 190.52C170.214 190.356 170.178 190.18 170.178 190.001ZM172.207 190.001C172.197 189.877 172.151 189.758 172.075 189.66C171.999 189.561 171.895 189.487 171.777 189.446C171.66 189.405 171.532 189.399 171.411 189.43C171.29 189.46 171.181 189.525 171.096 189.616C171.011 189.708 170.955 189.822 170.934 189.945C170.913 190.068 170.929 190.194 170.979 190.309C171.028 190.423 171.11 190.52 171.214 190.589C171.319 190.658 171.441 190.694 171.565 190.695C171.654 190.696 171.743 190.679 171.825 190.644C171.907 190.609 171.981 190.557 172.041 190.492C172.102 190.426 172.148 190.349 172.177 190.264C172.205 190.18 172.216 190.09 172.207 190.001Z' fill='black' />
        <path d='M176.043 188.734V190.84C176.066 191.029 176.045 191.222 175.982 191.401C175.919 191.581 175.814 191.744 175.677 191.877C175.541 192.01 175.375 192.109 175.193 192.166C175.011 192.224 174.819 192.239 174.63 192.21C174.19 192.227 173.755 192.104 173.389 191.859L173.68 191.337C173.93 191.516 174.229 191.614 174.536 191.619C175.05 191.619 175.281 191.388 175.281 190.934V190.823C175.178 190.93 175.054 191.013 174.917 191.068C174.78 191.122 174.632 191.147 174.485 191.14C174.316 191.154 174.146 191.133 173.986 191.078C173.825 191.023 173.678 190.936 173.554 190.821C173.429 190.706 173.329 190.567 173.261 190.412C173.193 190.257 173.158 190.089 173.158 189.92C173.158 189.751 173.193 189.583 173.261 189.428C173.329 189.273 173.429 189.134 173.554 189.019C173.678 188.904 173.825 188.817 173.986 188.762C174.146 188.707 174.316 188.686 174.485 188.7C174.646 188.687 174.807 188.713 174.956 188.776C175.105 188.838 175.237 188.936 175.341 189.06V188.734H176.043ZM175.324 189.924C175.326 189.839 175.311 189.753 175.278 189.674C175.246 189.594 175.197 189.523 175.134 189.464C175.072 189.405 174.998 189.36 174.917 189.331C174.836 189.303 174.75 189.292 174.664 189.299C174.577 189.289 174.488 189.297 174.404 189.324C174.32 189.35 174.242 189.394 174.176 189.453C174.11 189.512 174.057 189.584 174.021 189.664C173.985 189.745 173.966 189.832 173.966 189.92C173.966 190.008 173.985 190.095 174.021 190.176C174.057 190.256 174.11 190.328 174.176 190.387C174.242 190.446 174.32 190.49 174.404 190.516C174.488 190.543 174.577 190.551 174.664 190.541C174.749 190.548 174.834 190.537 174.915 190.509C174.995 190.481 175.069 190.437 175.131 190.379C175.193 190.321 175.242 190.25 175.276 190.172C175.309 190.094 175.325 190.009 175.324 189.924Z' fill='black' />
        <path d='M177.986 187.981C177.987 187.924 178 187.867 178.024 187.815C178.047 187.763 178.082 187.716 178.124 187.677C178.167 187.639 178.217 187.609 178.271 187.591C178.325 187.573 178.383 187.565 178.44 187.57C178.496 187.564 178.553 187.57 178.606 187.587C178.66 187.604 178.71 187.632 178.752 187.668C178.795 187.705 178.829 187.751 178.854 187.801C178.878 187.852 178.892 187.908 178.894 187.964C178.894 188.084 178.846 188.2 178.761 188.285C178.676 188.37 178.56 188.418 178.44 188.418C178.32 188.418 178.204 188.37 178.119 188.285C178.034 188.2 177.986 188.084 177.986 187.964V187.981ZM178.072 188.734H178.8V191.303H178.072V188.734Z' fill='black' />
        <path d='M182.044 189.779V191.225H181.316V189.898C181.316 189.488 181.128 189.299 180.803 189.299C180.477 189.299 180.195 189.513 180.195 189.975V191.225H179.467V188.657H180.169V188.957C180.28 188.843 180.413 188.754 180.561 188.697C180.709 188.639 180.867 188.614 181.025 188.623C181.173 188.615 181.321 188.641 181.457 188.699C181.594 188.757 181.715 188.844 181.813 188.955C181.911 189.066 181.983 189.198 182.023 189.34C182.063 189.483 182.07 189.633 182.044 189.779Z' fill='black' />
      </g>
      <path d='M207.027 198.022H95.0078V217.198H207.027V198.022Z' fill='#561885' />
      <path d='M136.548 210.589L135.178 208.612H134.767H133.141V210.606H132.199V204.041H134.767C136.479 204.041 137.498 204.897 137.498 206.335C137.52 206.79 137.391 207.24 137.131 207.615C136.871 207.989 136.494 208.267 136.06 208.406L137.618 210.606L136.548 210.589ZM136.548 206.309C136.548 205.367 135.923 204.837 134.716 204.837H133.124V207.79H134.716C135.897 207.799 136.522 207.251 136.522 206.318L136.548 206.309Z' fill='white' />
      <path d='M143.329 208.406H139.297C139.36 208.83 139.58 209.214 139.913 209.484C140.245 209.753 140.667 209.888 141.094 209.862C141.363 209.871 141.631 209.823 141.879 209.719C142.127 209.616 142.35 209.46 142.532 209.263L143.029 209.845C142.663 210.216 142.197 210.475 141.687 210.588C141.178 210.702 140.646 210.667 140.157 210.486C139.667 210.305 139.24 209.987 138.926 209.57C138.613 209.152 138.426 208.653 138.389 208.133C138.371 207.796 138.423 207.46 138.542 207.145C138.66 206.83 138.842 206.543 139.077 206.302C139.312 206.06 139.594 205.87 139.906 205.743C140.218 205.617 140.552 205.556 140.889 205.564C141.224 205.555 141.557 205.616 141.866 205.743C142.176 205.871 142.455 206.062 142.687 206.303C142.918 206.545 143.097 206.833 143.21 207.148C143.324 207.463 143.37 207.799 143.346 208.133C143.346 208.21 143.337 208.321 143.329 208.406ZM139.297 207.756H142.481C142.44 207.362 142.255 206.997 141.961 206.732C141.667 206.467 141.285 206.32 140.889 206.32C140.493 206.32 140.111 206.467 139.817 206.732C139.523 206.997 139.337 207.362 139.297 207.756Z' fill='white' />
      <path d='M149.356 205.624V209.905C149.356 211.66 148.457 212.473 146.788 212.473C145.936 212.509 145.098 212.249 144.416 211.737L144.844 211.043C145.392 211.469 146.068 211.695 146.762 211.685C147.935 211.685 148.474 211.146 148.474 210.016V209.622C148.252 209.876 147.977 210.078 147.667 210.211C147.357 210.344 147.022 210.406 146.685 210.393C146.047 210.393 145.435 210.139 144.984 209.688C144.533 209.237 144.279 208.625 144.279 207.987C144.279 207.349 144.533 206.737 144.984 206.286C145.435 205.835 146.047 205.582 146.685 205.582C147.037 205.571 147.387 205.643 147.707 205.791C148.026 205.94 148.307 206.161 148.525 206.438V205.667L149.356 205.624ZM148.5 207.979C148.518 207.637 148.434 207.297 148.258 207.003C148.082 206.709 147.821 206.475 147.511 206.331C147.2 206.187 146.853 206.14 146.515 206.195C146.177 206.25 145.863 206.405 145.614 206.64C145.365 206.875 145.193 207.18 145.119 207.514C145.044 207.849 145.072 208.198 145.199 208.516C145.325 208.834 145.544 209.107 145.827 209.3C146.111 209.493 146.445 209.596 146.788 209.597C147.007 209.61 147.226 209.577 147.432 209.501C147.638 209.426 147.826 209.308 147.984 209.156C148.143 209.004 148.268 208.821 148.352 208.618C148.436 208.416 148.478 208.198 148.474 207.979H148.5Z' fill='white' />
      <path d='M150.915 204.083C150.916 204.005 150.932 203.927 150.963 203.855C150.995 203.782 151.04 203.717 151.096 203.662C151.153 203.607 151.219 203.564 151.292 203.535C151.366 203.506 151.444 203.491 151.522 203.493C151.605 203.483 151.689 203.49 151.769 203.515C151.849 203.54 151.923 203.581 151.985 203.637C152.048 203.692 152.098 203.76 152.132 203.837C152.166 203.913 152.184 203.995 152.184 204.079C152.184 204.163 152.166 204.245 152.132 204.321C152.098 204.398 152.048 204.466 151.985 204.521C151.923 204.576 151.849 204.618 151.769 204.643C151.689 204.668 151.605 204.675 151.522 204.665C151.444 204.668 151.366 204.654 151.293 204.626C151.22 204.598 151.153 204.556 151.097 204.502C151.04 204.447 150.995 204.382 150.964 204.311C150.932 204.239 150.916 204.162 150.915 204.083ZM151.077 205.624H151.976V210.589H151.077V205.624Z' fill='white' />
      <path d='M153.063 210.067L153.44 209.357C153.95 209.688 154.544 209.866 155.152 209.871C156.008 209.871 156.334 209.614 156.334 209.194C156.334 208.081 153.192 209.049 153.192 207.063C153.192 206.207 153.988 205.573 155.264 205.573C155.895 205.563 156.518 205.72 157.07 206.027L156.685 206.737C156.257 206.468 155.76 206.331 155.255 206.344C154.467 206.344 154.082 206.635 154.082 207.028C154.082 208.201 157.232 207.243 157.232 209.177C157.232 210.033 156.376 210.65 155.084 210.65C154.368 210.66 153.664 210.458 153.063 210.067Z' fill='white' />
      <path d='M161.188 210.324C160.877 210.562 160.493 210.686 160.101 210.675C159.89 210.699 159.676 210.676 159.475 210.606C159.274 210.537 159.091 210.423 158.94 210.274C158.789 210.124 158.673 209.943 158.602 209.743C158.53 209.542 158.504 209.329 158.526 209.117V206.386H157.67V205.65H158.526V204.563H159.382V205.65H160.863V206.386H159.433V209.083C159.413 209.193 159.419 209.306 159.45 209.414C159.48 209.522 159.536 209.621 159.611 209.704C159.686 209.787 159.78 209.851 159.884 209.892C159.988 209.933 160.101 209.949 160.212 209.939C160.465 209.942 160.712 209.86 160.914 209.708L161.188 210.324Z' fill='white' />
      <path d='M166.582 208.406H162.576C162.639 208.831 162.86 209.217 163.195 209.487C163.529 209.756 163.953 209.89 164.382 209.862C164.649 209.872 164.916 209.823 165.163 209.72C165.41 209.616 165.631 209.46 165.812 209.262L166.308 209.845C165.943 210.217 165.477 210.477 164.968 210.591C164.459 210.705 163.927 210.67 163.438 210.489C162.948 210.308 162.522 209.989 162.209 209.571C161.897 209.153 161.712 208.653 161.677 208.132C161.659 207.796 161.711 207.46 161.829 207.145C161.948 206.83 162.13 206.543 162.365 206.302C162.6 206.06 162.882 205.87 163.193 205.743C163.505 205.617 163.84 205.556 164.176 205.564C164.511 205.556 164.843 205.618 165.152 205.746C165.461 205.874 165.74 206.065 165.972 206.306C166.203 206.548 166.381 206.835 166.495 207.15C166.609 207.464 166.656 207.799 166.633 208.132C166.624 208.225 166.607 208.317 166.582 208.406ZM162.576 207.756H165.769C165.719 207.367 165.53 207.01 165.236 206.751C164.942 206.492 164.564 206.349 164.172 206.349C163.78 206.349 163.402 206.492 163.108 206.751C162.814 207.01 162.625 207.367 162.576 207.756Z' fill='white' />
      <path d='M170.529 205.573V206.429H170.323C170.101 206.413 169.877 206.448 169.67 206.531C169.463 206.613 169.277 206.742 169.126 206.907C168.976 207.071 168.864 207.268 168.8 207.482C168.736 207.695 168.721 207.921 168.757 208.141V210.615H167.858V205.65H168.714V206.506C168.895 206.193 169.162 205.938 169.484 205.772C169.806 205.607 170.169 205.538 170.529 205.573Z' fill='white' />
      <g opacity='0.6'>
        <path d='M131.403 67.0294L131.788 66.2247C132.452 66.7815 133.293 67.0849 134.159 67.0807C135.401 67.0807 135.931 66.6013 135.931 65.9764C135.931 64.2643 131.583 65.3258 131.583 62.6635C131.583 61.5506 132.439 60.609 134.331 60.609C135.146 60.5957 135.947 60.8155 136.642 61.2424L136.299 62.0985C135.711 61.7221 135.029 61.5202 134.331 61.5164C133.106 61.5164 132.618 62.03 132.618 62.6549C132.618 64.367 136.976 63.3055 136.976 65.9507C136.976 67.0465 136.12 67.9881 134.194 67.9881C133.179 68.0094 132.19 67.6697 131.403 67.0294Z' fill='#263238' />
        <path d='M138.124 60.7202C138.125 60.634 138.143 60.5489 138.177 60.4698C138.211 60.3908 138.261 60.3193 138.323 60.2596C138.385 60.1998 138.459 60.153 138.539 60.1219C138.619 60.0907 138.705 60.0759 138.791 60.0782C138.876 60.0747 138.961 60.0882 139.041 60.1179C139.121 60.1475 139.194 60.1928 139.256 60.2509C139.318 60.3091 139.368 60.379 139.403 60.4566C139.438 60.5342 139.457 60.618 139.459 60.7031C139.459 60.8802 139.389 61.05 139.263 61.1752C139.138 61.3004 138.968 61.3708 138.791 61.3708C138.614 61.3708 138.444 61.3004 138.319 61.1752C138.194 61.05 138.124 60.8802 138.124 60.7031V60.7202ZM138.295 62.4323H139.288V67.8768H138.295V62.4323Z' fill='#263238' />
        <path d='M146.368 62.4067V67.1064C146.368 69.0325 145.392 69.9057 143.551 69.9057C142.628 69.935 141.722 69.6481 140.983 69.0925L141.454 68.3306C142.05 68.8022 142.791 69.0533 143.551 69.0411C144.835 69.0411 145.418 68.4419 145.418 67.2092V66.7811C145.175 67.0609 144.874 67.283 144.534 67.4311C144.195 67.5792 143.827 67.6497 143.457 67.6372C143.099 67.6576 142.74 67.6047 142.403 67.4816C142.066 67.3586 141.757 67.168 141.497 66.9216C141.236 66.6751 141.028 66.378 140.886 66.0484C140.744 65.7188 140.67 65.3637 140.67 65.0048C140.67 64.6459 140.744 64.2908 140.886 63.9612C141.028 63.6316 141.236 63.3345 141.497 63.0881C141.757 62.8416 142.066 62.651 142.403 62.528C142.74 62.4049 143.099 62.352 143.457 62.3724C143.836 62.3528 144.213 62.4196 144.562 62.568C144.911 62.7163 145.221 62.9422 145.469 63.2285V62.3724L146.368 62.4067ZM145.409 64.9749C145.428 64.6006 145.334 64.2293 145.14 63.9089C144.946 63.5885 144.66 63.3336 144.319 63.1771C143.979 63.0207 143.599 62.9699 143.23 63.0312C142.86 63.0926 142.517 63.2633 142.246 63.5213C141.974 63.7794 141.786 64.1129 141.705 64.4789C141.625 64.8449 141.656 65.2266 141.795 65.5747C141.934 65.9228 142.174 66.2213 142.484 66.4318C142.794 66.6422 143.16 66.755 143.534 66.7554C143.779 66.7769 144.026 66.747 144.259 66.6675C144.491 66.588 144.705 66.4607 144.885 66.2939C145.066 66.127 145.21 65.9242 145.307 65.6985C145.405 65.4729 145.454 65.2293 145.452 64.9834L145.409 64.9749Z' fill='#263238' />
        <path d='M153.422 64.7179V67.851H152.429V64.7949C152.429 63.7248 151.899 63.2026 150.966 63.2026C150.729 63.1871 150.491 63.224 150.27 63.3107C150.049 63.3973 149.849 63.5317 149.686 63.7041C149.522 63.8765 149.399 64.0828 149.324 64.3083C149.249 64.5338 149.225 64.773 149.254 65.0089V67.8168H148.269V62.3723H149.219V63.2283C149.453 62.9382 149.753 62.7089 150.094 62.5599C150.436 62.4108 150.808 62.3464 151.18 62.3723C151.488 62.3515 151.798 62.3989 152.087 62.5109C152.375 62.6229 152.636 62.7969 152.849 63.0207C153.063 63.2444 153.226 63.5123 153.325 63.8056C153.424 64.0988 153.457 64.4103 153.422 64.7179Z' fill='#263238' />
        <path d='M163.088 62.4066V67.8511H162.155V66.9951C161.938 67.2752 161.657 67.4991 161.335 67.6481C161.013 67.797 160.66 67.8667 160.306 67.8511C159.988 67.8839 159.666 67.8449 159.365 67.7369C159.064 67.6289 158.79 67.4548 158.565 67.2273C158.34 66.9998 158.169 66.7247 158.064 66.4222C157.96 66.1198 157.924 65.7978 157.961 65.4798V62.3467H158.945V65.3685C158.945 66.4301 159.484 66.9694 160.417 66.9694C160.655 66.9864 160.893 66.9506 161.115 66.8645C161.337 66.7783 161.537 66.644 161.701 66.4713C161.865 66.2985 161.988 66.0916 162.062 65.8653C162.136 65.6391 162.159 65.3993 162.13 65.1631V62.3467L163.088 62.4066Z' fill='#263238' />
        <path d='M170.605 65.1291C170.625 65.4966 170.567 65.8642 170.436 66.2082C170.306 66.5522 170.105 66.8652 169.846 67.127C169.587 67.3889 169.277 67.5939 168.934 67.7289C168.592 67.864 168.225 67.9261 167.858 67.9113C167.496 67.9254 167.136 67.8554 166.807 67.7068C166.477 67.5583 166.186 67.3352 165.957 67.0552V69.8631H164.973V62.4325H165.914V63.2886C166.14 62.9905 166.435 62.7516 166.774 62.5921C167.112 62.4326 167.484 62.3573 167.858 62.3726C168.223 62.3567 168.588 62.4173 168.93 62.5503C169.271 62.6833 169.58 62.886 169.839 63.1453C170.097 63.4046 170.299 63.7149 170.431 64.0565C170.563 64.398 170.622 64.7633 170.605 65.1291ZM169.612 65.1291C169.596 64.7712 169.474 64.4262 169.264 64.1366C169.053 63.8469 168.762 63.6255 168.426 63.4996C168.091 63.3737 167.726 63.3489 167.377 63.4283C167.027 63.5077 166.709 63.6877 166.461 63.9462C166.213 64.2046 166.046 64.5301 165.981 64.8824C165.916 65.2347 165.956 65.5983 166.095 65.9282C166.235 66.2582 166.468 66.54 166.766 66.7387C167.064 66.9375 167.414 67.0446 167.772 67.0466C168.022 67.0519 168.27 67.0052 168.501 66.9097C168.731 66.8141 168.94 66.6718 169.113 66.4916C169.286 66.3113 169.419 66.0972 169.505 65.8627C169.591 65.6282 169.628 65.3784 169.612 65.1291Z' fill='#263238' />
      </g>
      <path d='M207.027 108.06H95.0078V127.236H207.027V108.06Z' fill='white' />
      <g opacity='0.4'>
        <path d='M101.138 114.523H101.72V119.514H104.793V120.019H101.138V114.523Z' fill='#263238' />
        <path d='M108.774 117.459V120.028H108.244V119.386C108.088 119.615 107.875 119.798 107.625 119.919C107.376 120.039 107.099 120.091 106.823 120.07C105.889 120.07 105.316 119.582 105.316 118.863C105.316 118.144 105.718 117.699 106.908 117.699H108.218V117.451C108.218 116.74 107.816 116.355 107.045 116.355C106.545 116.348 106.059 116.522 105.675 116.843L105.419 116.424C105.907 116.045 106.513 115.848 107.131 115.867C107.348 115.841 107.569 115.863 107.777 115.933C107.985 116.003 108.175 116.119 108.332 116.271C108.49 116.424 108.611 116.61 108.688 116.815C108.764 117.021 108.794 117.241 108.774 117.459ZM108.218 118.778V118.101H106.925C106.121 118.101 105.864 118.418 105.864 118.846C105.864 119.274 106.249 119.617 106.908 119.617C107.187 119.634 107.465 119.562 107.7 119.411C107.935 119.26 108.117 119.038 108.218 118.778Z' fill='#263238' />
        <path d='M109.716 119.582L109.965 119.146C110.396 119.447 110.91 119.609 111.437 119.608C112.225 119.608 112.541 119.343 112.541 118.932C112.541 117.853 109.87 118.701 109.87 117.074C109.87 116.398 110.444 115.893 111.497 115.893C112.011 115.886 112.517 116.019 112.961 116.278L112.712 116.723C112.352 116.485 111.928 116.363 111.497 116.372C110.752 116.372 110.427 116.663 110.427 117.057C110.427 118.17 113.098 117.331 113.098 118.915C113.098 119.634 112.473 120.096 111.386 120.096C110.788 120.109 110.203 119.929 109.716 119.582Z' fill='#263238' />
        <path d='M116.343 119.771C116.099 119.964 115.797 120.067 115.486 120.062C115.324 120.079 115.159 120.06 115.005 120.006C114.85 119.951 114.71 119.863 114.594 119.747C114.478 119.631 114.39 119.491 114.335 119.337C114.281 119.182 114.262 119.018 114.279 118.855V116.364H113.535V115.893H114.279V114.985H114.836V115.893H116.086V116.364H114.836V118.829C114.821 118.928 114.829 119.028 114.859 119.123C114.889 119.218 114.94 119.306 115.008 119.378C115.077 119.451 115.161 119.507 115.254 119.542C115.347 119.577 115.447 119.591 115.546 119.582C115.771 119.589 115.991 119.513 116.163 119.368L116.343 119.771Z' fill='#263238' />
        <path d='M124.048 114.523V120.019H123.569L120.042 115.559V120.019H119.459V114.523H119.939L123.474 118.983V114.523H124.048Z' fill='#263238' />
        <path d='M128.877 117.459V120.028H128.329V119.385C128.175 119.616 127.961 119.801 127.712 119.921C127.462 120.041 127.184 120.093 126.908 120.07C125.975 120.07 125.401 119.582 125.401 118.863C125.401 118.144 125.812 117.699 127.002 117.699H128.329V117.451C128.329 116.74 127.927 116.355 127.156 116.355C126.656 116.348 126.17 116.522 125.787 116.843L125.53 116.424C126.018 116.043 126.624 115.846 127.242 115.867C127.459 115.842 127.679 115.866 127.886 115.936C128.092 116.007 128.281 116.123 128.437 116.275C128.594 116.427 128.715 116.613 128.791 116.818C128.867 117.022 128.896 117.242 128.877 117.459ZM128.321 118.778V118.101H127.019C126.223 118.101 125.966 118.418 125.966 118.846C125.966 119.274 126.352 119.617 127.011 119.617C127.291 119.636 127.57 119.564 127.808 119.414C128.045 119.263 128.227 119.04 128.329 118.778H128.321Z' fill='#263238' />
        <path d='M137.207 117.622V120.019H136.65V117.682C136.65 116.826 136.205 116.364 135.46 116.364C135.267 116.355 135.075 116.388 134.896 116.461C134.717 116.534 134.556 116.646 134.425 116.788C134.294 116.93 134.195 117.099 134.136 117.283C134.077 117.467 134.058 117.661 134.082 117.853V120.019H133.526V117.682C133.526 116.826 133.081 116.364 132.327 116.364C132.134 116.355 131.941 116.388 131.763 116.461C131.584 116.534 131.423 116.646 131.292 116.788C131.161 116.93 131.062 117.099 131.003 117.283C130.943 117.467 130.925 117.661 130.949 117.853V120.019H130.393V115.893H130.898V116.646C131.059 116.394 131.284 116.188 131.55 116.05C131.816 115.912 132.113 115.846 132.413 115.858C132.716 115.834 133.02 115.902 133.283 116.055C133.547 116.208 133.757 116.438 133.885 116.715C134.054 116.439 134.295 116.214 134.581 116.063C134.867 115.912 135.189 115.842 135.512 115.858C135.746 115.836 135.982 115.866 136.203 115.948C136.424 116.029 136.624 116.16 136.787 116.329C136.95 116.499 137.072 116.704 137.145 116.927C137.218 117.151 137.239 117.389 137.207 117.622Z' fill='#263238' />
        <path d='M142.335 118.127H138.911C138.923 118.328 138.975 118.525 139.063 118.707C139.151 118.888 139.275 119.05 139.426 119.184C139.577 119.317 139.753 119.42 139.944 119.485C140.134 119.551 140.336 119.578 140.538 119.565C140.775 119.573 141.012 119.528 141.231 119.435C141.449 119.342 141.645 119.202 141.805 119.026L142.113 119.385C141.816 119.695 141.435 119.911 141.016 120.005C140.598 120.099 140.161 120.068 139.76 119.916C139.359 119.763 139.012 119.496 138.762 119.147C138.512 118.799 138.37 118.384 138.355 117.956C138.341 117.683 138.383 117.41 138.478 117.155C138.574 116.899 138.721 116.666 138.91 116.469C139.1 116.272 139.327 116.117 139.579 116.012C139.831 115.907 140.102 115.854 140.375 115.858C140.648 115.853 140.918 115.904 141.17 116.009C141.422 116.114 141.649 116.27 141.837 116.467C142.026 116.664 142.171 116.898 142.264 117.154C142.357 117.411 142.396 117.684 142.378 117.956C142.344 118.007 142.335 118.067 142.335 118.127ZM138.911 117.716H141.839C141.839 117.328 141.685 116.955 141.41 116.681C141.136 116.406 140.763 116.252 140.375 116.252C139.987 116.252 139.614 116.406 139.34 116.681C139.065 116.955 138.911 117.328 138.911 117.716Z' fill='#263238' />
      </g>
      <path d='M207.027 81.8647H95.0078V101.04H207.027V81.8647Z' fill='white' />
      <g opacity='0.4'>
        <path d='M101.72 88.8327V90.99H104.562V91.495H101.72V93.8235H101.138V88.3276H104.904V88.8327H101.72Z' fill='#263238' />
        <path d='M105.787 88.3876C105.785 88.3057 105.808 88.2251 105.852 88.1562C105.897 88.0873 105.961 88.0333 106.036 88.0012C106.112 87.969 106.195 87.9602 106.276 87.9757C106.356 87.9913 106.43 88.0306 106.488 88.0886C106.546 88.1466 106.585 88.2206 106.601 88.3011C106.617 88.3816 106.608 88.4649 106.576 88.5403C106.543 88.6158 106.489 88.6798 106.421 88.7243C106.352 88.7688 106.271 88.7917 106.189 88.79C106.082 88.79 105.98 88.7476 105.905 88.6721C105.829 88.5967 105.787 88.4944 105.787 88.3876ZM105.907 89.6974H106.463V93.8236H105.907V89.6974Z' fill='#263238' />
        <path d='M110.101 89.6632V90.2025H109.964C109.768 90.1929 109.571 90.2269 109.389 90.3021C109.207 90.3773 109.044 90.4918 108.912 90.6375C108.779 90.7831 108.68 90.9562 108.623 91.1444C108.565 91.3327 108.549 91.5313 108.577 91.7262V93.8321H108.021V89.706H108.552V90.4764C108.7 90.2021 108.926 89.9781 109.202 89.8333C109.478 89.6884 109.791 89.6292 110.101 89.6632Z' fill='#263238' />
        <path d='M110.564 93.3528L110.812 92.9077C111.24 93.2179 111.756 93.3829 112.285 93.3785C113.072 93.3785 113.389 93.1046 113.389 92.7022C113.389 91.6236 110.718 92.4711 110.718 90.836C110.718 90.1683 111.292 89.6632 112.345 89.6632C112.859 89.6521 113.366 89.7855 113.808 90.0485L113.56 90.4936C113.2 90.2558 112.776 90.1334 112.345 90.1426C111.6 90.1426 111.274 90.4337 111.274 90.8275C111.274 91.9403 113.945 91.1014 113.945 92.6851C113.945 93.3956 113.32 93.8579 112.233 93.8579C111.637 93.8734 111.052 93.6963 110.564 93.3528Z' fill='#263238' />
        <path d='M117.199 93.5752C116.953 93.762 116.652 93.8615 116.343 93.8577C116.181 93.8767 116.016 93.8588 115.862 93.8054C115.707 93.7521 115.567 93.6645 115.451 93.5493C115.335 93.4342 115.246 93.2943 115.192 93.1402C115.137 92.9861 115.118 92.8217 115.136 92.6592V90.1681H114.391V89.6973H115.136V88.7642H115.692V89.6716H116.942V90.1424H115.692V92.5993C115.678 92.6982 115.686 92.7992 115.716 92.8946C115.746 92.9901 115.797 93.0776 115.865 93.1507C115.933 93.2238 116.017 93.2807 116.11 93.3171C116.203 93.3536 116.303 93.3686 116.403 93.3612C116.627 93.3641 116.845 93.2884 117.019 93.1472L117.199 93.5752Z' fill='#263238' />
        <path d='M124.904 88.3276V93.8235H124.424L120.898 89.3635V93.8235H120.315V88.3276H120.795L124.33 92.7877V88.3276H124.904Z' fill='#263238' />
        <path d='M129.724 91.2642V93.8323H129.185V93.1903C129.032 93.419 128.82 93.6023 128.571 93.7213C128.323 93.8402 128.047 93.8904 127.773 93.8666C126.831 93.8666 126.257 93.3872 126.257 92.6681C126.257 91.949 126.668 91.5039 127.858 91.5039H129.185V91.2471C129.185 90.5451 128.783 90.1599 128.012 90.1599C127.512 90.1532 127.026 90.3264 126.643 90.6478L126.386 90.2284C126.874 89.8484 127.48 89.6515 128.098 89.6719C128.315 89.6467 128.534 89.6702 128.741 89.7408C128.947 89.8114 129.135 89.9273 129.291 90.0799C129.447 90.2325 129.567 90.4181 129.642 90.623C129.717 90.8279 129.745 91.047 129.724 91.2642ZM129.168 92.5825V91.9062H127.867C127.071 91.9062 126.814 92.223 126.814 92.6424C126.814 93.0619 127.199 93.4214 127.858 93.4214C128.14 93.441 128.42 93.3701 128.659 93.2192C128.898 93.0684 129.082 92.8453 129.185 92.5825H129.168Z' fill='#263238' />
        <path d='M138.054 91.4267V93.8236H137.498V91.4866C137.498 90.6305 137.053 90.1683 136.308 90.1683C136.115 90.1593 135.922 90.1926 135.743 90.2659C135.565 90.3392 135.404 90.4507 135.273 90.5926C135.141 90.7344 135.043 90.9033 134.983 91.0873C134.924 91.2713 134.906 91.466 134.93 91.6578V93.8236H134.373V91.4866C134.373 90.6305 133.928 90.1683 133.175 90.1683C132.982 90.1593 132.789 90.1926 132.61 90.2659C132.431 90.3392 132.271 90.4507 132.139 90.5926C132.008 90.7344 131.909 90.9033 131.85 91.0873C131.791 91.2713 131.773 91.466 131.797 91.6578V93.8236H131.24V89.6974H131.754V90.4764C131.915 90.2241 132.14 90.0185 132.406 89.8803C132.672 89.7421 132.97 89.6761 133.269 89.6889C133.573 89.6639 133.876 89.7326 134.14 89.8857C134.403 90.0388 134.613 90.2688 134.742 90.5449C134.909 90.2678 135.149 90.0417 135.436 89.8908C135.722 89.74 136.045 89.6702 136.368 89.6889C136.599 89.6681 136.832 89.6992 137.05 89.78C137.267 89.8607 137.464 89.989 137.626 90.1556C137.787 90.3222 137.91 90.5227 137.984 90.7426C138.058 90.9625 138.082 91.1962 138.054 91.4267Z' fill='#263238' />
        <path d='M143.183 91.9315H139.759C139.771 92.1329 139.822 92.3299 139.911 92.5112C139.999 92.6926 140.122 92.8547 140.273 92.9884C140.425 93.122 140.601 93.2244 140.791 93.2899C140.982 93.3553 141.184 93.3824 141.385 93.3697C141.624 93.3775 141.861 93.3324 142.08 93.2376C142.299 93.1429 142.495 93.0009 142.652 92.8218L142.96 93.1899C142.664 93.4997 142.282 93.7152 141.864 93.8096C141.446 93.9039 141.009 93.8729 140.608 93.7204C140.207 93.5679 139.86 93.3007 139.61 92.9521C139.36 92.6035 139.218 92.189 139.202 91.7603C139.188 91.4877 139.23 91.2151 139.326 90.9593C139.421 90.7036 139.568 90.4702 139.758 90.2736C139.947 90.077 140.175 89.9214 140.427 89.8163C140.679 89.7113 140.95 89.6591 141.223 89.663C141.495 89.6576 141.766 89.709 142.018 89.8138C142.269 89.9185 142.497 90.0744 142.685 90.2716C142.873 90.4688 143.019 90.7029 143.112 90.9592C143.205 91.2154 143.244 91.4882 143.226 91.7603C143.192 91.8031 143.183 91.863 143.183 91.9315ZM139.759 91.5121H142.687C142.687 91.1238 142.532 90.7515 142.258 90.477C141.983 90.2024 141.611 90.0482 141.223 90.0482C140.834 90.0482 140.462 90.2024 140.188 90.477C139.913 90.7515 139.759 91.1238 139.759 91.5121Z' fill='#263238' />
      </g>
      <path d='M293.242 0.000174324H268.645C266.637 0.000174324 264.712 0.797467 263.293 2.21665C261.874 3.63584 261.076 5.56067 261.076 7.5677V28.969C261.075 29.9635 261.27 30.9485 261.65 31.8676C262.03 32.7868 262.587 33.622 263.29 34.3256C263.993 35.0292 264.828 35.5874 265.746 35.9683C266.665 36.3491 267.65 36.5451 268.645 36.5451H275.828L274.115 43.2994L280.965 36.5451H293.285C295.294 36.5451 297.222 35.7469 298.642 34.3261C300.063 32.9053 300.862 30.9783 300.862 28.969V7.5677C300.862 6.56958 300.664 5.58132 300.281 4.65982C299.897 3.73832 299.335 2.90179 298.627 2.19841C297.919 1.49503 297.078 0.93868 296.154 0.561411C295.23 0.184142 294.24 -0.00660023 293.242 0.000174324Z' fill='#561885' />
      <path opacity='0.7' d='M293.242 0.000174324H268.645C266.637 0.000174324 264.712 0.797467 263.293 2.21665C261.874 3.63584 261.076 5.56067 261.076 7.5677V28.969C261.075 29.9635 261.27 30.9485 261.65 31.8676C262.03 32.7868 262.587 33.622 263.29 34.3256C263.993 35.0292 264.828 35.5874 265.746 35.9683C266.665 36.3491 267.65 36.5451 268.645 36.5451H275.828L274.115 43.2994L280.965 36.5451H293.285C295.294 36.5451 297.222 35.7469 298.642 34.3261C300.063 32.9053 300.862 30.9783 300.862 28.969V7.5677C300.862 6.56958 300.664 5.58132 300.281 4.65982C299.897 3.73832 299.335 2.90179 298.627 2.19841C297.919 1.49503 297.078 0.93868 296.154 0.561411C295.23 0.184142 294.24 -0.00660023 293.242 0.000174324Z' fill='white' />
      <path d='M280.948 27.7277C286.22 27.7277 290.494 23.4543 290.494 18.1827C290.494 12.9111 286.22 8.6377 280.948 8.6377C275.675 8.6377 271.401 12.9111 271.401 18.1827C271.401 23.4543 275.675 27.7277 280.948 27.7277Z' fill='white' />
      <path d='M280.948 5.97559C278.533 5.97559 276.172 6.69154 274.165 8.0329C272.157 9.37425 270.592 11.2808 269.668 13.5114C268.744 15.742 268.502 18.1965 268.973 20.5644C269.444 22.9324 270.607 25.1076 272.315 26.8148C274.022 28.522 276.197 29.6847 278.566 30.1557C280.934 30.6267 283.389 30.385 285.62 29.461C287.851 28.5371 289.757 26.9724 291.099 24.9649C292.44 22.9575 293.156 20.5973 293.156 18.1829C293.157 16.5795 292.842 14.9916 292.229 13.51C291.616 12.0285 290.717 10.6823 289.583 9.54852C288.449 8.41474 287.103 7.5156 285.621 6.90253C284.139 6.28945 282.551 5.97446 280.948 5.97559ZM287.874 14.5361L280.519 23.1994C280.415 23.3246 280.284 23.4249 280.136 23.493C279.988 23.5611 279.826 23.5954 279.663 23.5932C279.501 23.5942 279.339 23.5594 279.192 23.4914C279.044 23.4233 278.912 23.3237 278.807 23.1994L274.021 17.558C273.92 17.4465 273.843 17.3157 273.793 17.1735C273.744 17.0313 273.723 16.8806 273.733 16.7304C273.743 16.5802 273.783 16.4335 273.851 16.2991C273.919 16.1648 274.013 16.0454 274.128 15.9483C274.243 15.8511 274.377 15.7781 274.521 15.7336C274.664 15.689 274.816 15.6739 274.966 15.6892C275.115 15.7044 275.261 15.7496 275.393 15.8221C275.524 15.8946 275.64 15.993 275.734 16.1113L279.663 20.7425L286.162 13.0894C286.357 12.874 286.628 12.7429 286.918 12.7236C287.208 12.7043 287.494 12.7984 287.716 12.986C287.938 13.1736 288.079 13.4401 288.108 13.7292C288.138 14.0184 288.054 14.3077 287.874 14.5361Z' fill='#561885' />
      <path d='M288.978 132.047C288.978 132.047 274.073 130.164 251.487 136.327C251.487 136.327 247.857 95.9729 248.285 90.4257C248.713 84.8785 283.156 86.505 283.156 86.505C283.156 86.505 284.012 102.659 286.256 112.401C288.499 122.142 290.143 130.172 288.978 132.047Z' fill='#561885' />
      <path opacity='0.2' d='M288.978 132.047C288.978 132.047 274.073 130.164 251.487 136.327C251.487 136.327 247.857 95.9729 248.285 90.4257C248.713 84.8785 283.156 86.505 283.156 86.505C283.156 86.505 284.012 102.659 286.256 112.401C288.499 122.142 290.143 130.172 288.978 132.047Z' fill='black' />
      <path d='M240.597 265.643L246.675 267.363L248.121 257.904L248.9 252.845L242.822 251.124L241.787 257.904L240.597 265.643Z' fill='#E4897B' />
      <path d='M275.545 266.139L281.811 266.969L281.871 257.904L281.914 252.271L275.639 251.449L275.596 257.904L275.545 266.139Z' fill='#E4897B' />
      <path opacity='0.2' d='M275.639 251.449L275.596 257.904H281.871L281.914 252.271L275.639 251.449Z' fill='black' />
      <path opacity='0.2' d='M241.787 257.904H248.121L248.9 252.845L242.822 251.124L241.787 257.904Z' fill='black' />
      <path d='M213.61 93.7386L207.575 91.958L208.679 98.8492C208.679 98.8492 214.158 100.108 214.671 97.257L213.61 93.7386Z' fill='#E4897B' />
      <path d='M201.547 93.5588L204.509 99.1574L208.678 98.8492L207.574 91.958L201.547 93.5588Z' fill='#E4897B' />
      <path d='M221.941 95.5018L212.027 93.2847L211.128 98.0957L220.126 100.287L221.941 95.5018Z' fill='#E4897B' />
      <path d='M251.932 91.1529L251.35 91.8464C250.1 93.3102 247.069 96.8457 243.91 99.8933C241.342 102.376 238.773 104.542 237.061 104.978C232.206 106.228 214.561 101.032 214.561 101.032L216.385 93.8752C216.385 93.8752 232.823 96.0581 234.099 96.0581C235.066 96.0581 245.066 81.5737 247.489 80.3838L251.932 91.1529Z' fill='#561885' />
      <path opacity='0.2' d='M251.349 91.8465C250.099 93.3103 247.069 96.8458 243.91 99.8934C244.544 94.8769 246.384 90.2713 248.807 87.3608C249.033 87.0798 249.291 86.8269 249.577 86.6074L251.349 91.8465Z' fill='black' />
      <path d='M240.674 264.692L247.574 264.512C247.699 264.509 247.821 264.547 247.92 264.622C248.02 264.696 248.091 264.803 248.122 264.923L249.578 270.479C249.612 270.612 249.617 270.751 249.59 270.886C249.564 271.021 249.508 271.148 249.425 271.259C249.343 271.369 249.237 271.459 249.115 271.523C248.993 271.586 248.859 271.622 248.721 271.626C246.23 271.626 242.626 271.626 239.501 271.686C235.836 271.789 233.508 271.609 229.227 271.686C226.658 271.755 225.802 269.169 226.898 268.904C231.804 267.688 235.014 267.928 239.192 265.189C239.633 264.895 240.145 264.724 240.674 264.692Z' fill='#263238' />
      <path d='M240.074 265.583C239.064 265.805 237.266 266.105 236.547 265.514C236.442 265.428 236.36 265.317 236.306 265.192C236.252 265.067 236.229 264.931 236.239 264.795C236.239 264.714 236.26 264.634 236.3 264.563C236.341 264.492 236.399 264.434 236.47 264.393C237.232 263.939 239.809 265.163 240.108 265.3C240.134 265.314 240.156 265.335 240.171 265.361C240.187 265.387 240.194 265.416 240.194 265.446C240.192 265.479 240.18 265.51 240.158 265.535C240.136 265.56 240.107 265.577 240.074 265.583ZM236.65 264.633H236.598C236.57 264.649 236.546 264.673 236.53 264.701C236.513 264.73 236.504 264.762 236.504 264.795C236.497 264.884 236.511 264.973 236.546 265.054C236.58 265.136 236.633 265.209 236.701 265.266C237.103 265.6 238.148 265.634 239.535 265.36C238.533 264.941 237.138 264.453 236.667 264.633H236.65Z' fill='#561885' />
      <path d='M240.074 265.583H239.971C239.192 265.257 237.634 263.939 237.72 263.229C237.72 263.057 237.857 262.843 238.268 262.792C238.418 262.772 238.572 262.783 238.719 262.822C238.866 262.862 239.003 262.93 239.124 263.023C239.98 263.665 240.177 265.352 240.194 265.42C240.196 265.446 240.192 265.472 240.181 265.496C240.171 265.52 240.155 265.541 240.134 265.557L240.074 265.583ZM238.156 263.126C238.028 263.169 238.019 263.237 238.019 263.263C237.968 263.691 239.029 264.735 239.826 265.18C239.734 264.461 239.416 263.79 238.918 263.263C238.834 263.192 238.735 263.14 238.629 263.11C238.522 263.081 238.411 263.074 238.302 263.092C238.252 263.096 238.203 263.107 238.156 263.126Z' fill='#561885' />
      <path d='M275.793 264.692L282.694 264.513C282.819 264.508 282.942 264.546 283.043 264.621C283.144 264.696 283.217 264.802 283.25 264.923L284.697 270.479C284.733 270.612 284.739 270.752 284.713 270.888C284.688 271.023 284.632 271.151 284.549 271.262C284.466 271.372 284.36 271.463 284.237 271.526C284.114 271.589 283.979 271.623 283.841 271.626C281.349 271.626 277.745 271.626 274.611 271.686C270.956 271.789 268.618 271.609 264.338 271.686C261.769 271.755 260.913 269.169 262.009 268.904C266.915 267.689 270.117 267.928 274.295 265.189C274.741 264.892 275.258 264.721 275.793 264.692Z' fill='#263238' />
      <path d='M275.202 265.583C274.192 265.805 272.386 266.105 271.675 265.514C271.57 265.428 271.488 265.317 271.434 265.192C271.38 265.067 271.357 264.931 271.367 264.795C271.366 264.714 271.388 264.634 271.428 264.563C271.469 264.492 271.527 264.434 271.598 264.393C272.351 263.939 274.937 265.163 275.228 265.3C275.256 265.311 275.28 265.332 275.296 265.358C275.311 265.385 275.317 265.415 275.313 265.446C275.311 265.477 275.299 265.507 275.279 265.532C275.259 265.556 275.232 265.574 275.202 265.583ZM271.778 264.633H271.726C271.698 264.649 271.675 264.673 271.66 264.701C271.645 264.73 271.638 264.763 271.641 264.795C271.632 264.884 271.646 264.973 271.68 265.055C271.714 265.137 271.769 265.21 271.838 265.266C272.24 265.6 273.276 265.634 274.671 265.36C273.652 264.941 272.257 264.453 271.786 264.633H271.778Z' fill='#561885' />
      <path d='M275.202 265.583H275.091C274.32 265.257 272.754 263.939 272.848 263.228C272.848 263.057 272.976 262.843 273.387 262.792C273.538 262.771 273.692 262.781 273.839 262.821C273.986 262.86 274.123 262.929 274.243 263.023C274.842 263.69 275.216 264.529 275.313 265.42C275.318 265.446 275.314 265.473 275.304 265.497C275.293 265.522 275.276 265.542 275.253 265.557L275.202 265.583ZM273.276 263.126C273.156 263.168 273.147 263.237 273.147 263.263C273.088 263.691 274.158 264.735 274.954 265.18C274.856 264.463 274.539 263.793 274.046 263.263C273.962 263.192 273.863 263.14 273.757 263.111C273.65 263.081 273.539 263.075 273.43 263.091L273.276 263.126Z' fill='#561885' />
      <path d='M288.473 128.366C291.795 142.756 286.093 255.969 286.093 255.969H275.083L270.374 161.623L253.037 255.969L241.265 255.113C248.97 182.023 255.759 138.39 255.759 138.39V132.877L288.473 128.366Z' fill='#263238' />
      <path opacity='0.4' d='M241.265 255.113L253.037 255.969L266.684 181.698L270.374 161.623L275.083 255.969H286.093C286.093 255.969 291.795 142.756 288.473 128.366L255.742 132.877V138.39C255.742 138.39 248.961 182.023 241.265 255.113Z' fill='white' />
      <path d='M282.539 75.0678C282.202 81.9482 282.377 88.8441 283.062 95.6987C283.524 100.493 284.158 105.021 284.834 109.104C286.418 118.684 288.216 125.866 288.473 128.374L255.742 132.886C243.807 99.4995 248.302 80.1014 248.302 80.1014C250.749 79.091 253.257 78.2333 255.81 77.5332C256.35 77.3877 256.898 77.2507 257.463 77.1223C262.06 76.2178 266.705 75.5776 271.375 75.2047C272.231 75.1277 273.165 75.0678 274.038 75.025C278.507 74.8024 282.539 75.0678 282.539 75.0678Z' fill='#263238' />
      <path d='M265.023 60.0439C265.263 64.6238 265.751 73.2186 269.561 75.4016C269.561 75.4016 267.189 79.913 262.061 80.3752C258.182 80.7176 258.439 76.8911 258.439 76.8911C260.682 75.2218 260.151 72.3883 258.439 69.1866L265.023 60.0439Z' fill='#E4897B' />
      <path opacity='0.2' d='M262.334 63.7764L258.464 69.1866C258.872 69.9266 259.215 70.6998 259.492 71.498C261.289 70.7789 263.352 68.0738 263.053 66.1134C262.959 65.2968 262.715 64.5045 262.334 63.7764Z' fill='black' />
      <path d='M248.328 50.8245C247.583 53.2043 247.994 58.0838 251.307 56.5001C254.62 54.9164 249.612 46.7411 248.328 50.8245Z' fill='#263238' />
      <path d='M265.451 54.0432C265.819 60.1469 266.307 63.6995 263.645 67.158C259.595 72.3542 251.753 70.4196 249.561 64.5299C247.592 59.2224 247.326 50.1311 252.985 47.0493C254.24 46.3669 255.647 46.0138 257.075 46.0231C258.503 46.0325 259.905 46.4039 261.151 47.1026C262.396 47.8014 263.444 48.8046 264.196 50.0185C264.948 51.2323 265.38 52.6171 265.451 54.0432Z' fill='#E4897B' />
      <path d='M261.17 50.7214V43.873C261.17 43.873 268.43 43.3337 268.105 47.049C272.129 49.0436 270.228 55.3013 264.826 58.366C261.863 55.9947 262.206 50.2934 262.206 50.2934C261.889 50.4561 261.53 50.593 261.17 50.7214Z' fill='#263238' />
      <path d='M268.404 58.803C268.264 59.5497 267.976 60.2608 267.557 60.8945C267.138 61.5281 266.597 62.0715 265.964 62.4926C264.252 63.614 262.771 62.2358 262.711 60.3097C262.66 58.5976 263.473 55.8924 265.442 55.55C265.873 55.4829 266.313 55.5236 266.724 55.6684C267.136 55.8133 267.504 56.0578 267.798 56.3801C268.091 56.7024 268.3 57.0924 268.406 57.5152C268.512 57.938 268.511 58.3805 268.404 58.803Z' fill='#E4897B' />
      <path d='M256.435 56.7655C256.495 57.262 256.281 57.6901 255.956 57.7243C255.631 57.7586 255.322 57.3905 255.263 56.8683C255.203 56.3461 255.417 55.9437 255.733 55.9095C256.05 55.8752 256.375 56.2348 256.435 56.7655Z' fill='#263238' />
      <path d='M250.836 57.3904C250.896 57.8869 250.682 58.3149 250.357 58.3491C250.031 58.3834 249.723 58.0153 249.663 57.4931C249.603 56.9709 249.817 56.5685 250.143 56.5343C250.468 56.5001 250.759 56.8938 250.836 57.3904Z' fill='#263238' />
      <path d='M252.642 57.4331C252.21 58.9639 251.567 60.4273 250.733 61.7819C251.103 61.9716 251.508 62.0812 251.922 62.1033C252.337 62.1254 252.752 62.0596 253.139 61.9103L252.642 57.4331Z' fill='#DE5753' />
      <path d='M256.024 63.5027C255.813 63.5566 255.599 63.5966 255.382 63.6226C255.344 63.6226 255.307 63.6072 255.279 63.5799C255.252 63.5527 255.237 63.5156 255.237 63.477C255.237 63.4384 255.252 63.4014 255.279 63.3741C255.307 63.3468 255.344 63.3315 255.382 63.3315C255.997 63.2757 256.59 63.0762 257.114 62.7491C257.637 62.4219 258.076 61.9763 258.396 61.4482C258.403 61.4309 258.414 61.4153 258.427 61.4023C258.441 61.3894 258.457 61.3794 258.475 61.373C258.493 61.3666 258.511 61.3638 258.53 61.365C258.549 61.3662 258.567 61.3712 258.584 61.3797C258.602 61.3879 258.617 61.3996 258.63 61.414C258.643 61.4285 258.653 61.4454 258.66 61.4637C258.666 61.4821 258.669 61.5015 258.668 61.5209C258.666 61.5403 258.661 61.5592 258.652 61.5766C258.373 62.0628 257.994 62.4853 257.542 62.817C257.089 63.1486 256.572 63.3822 256.024 63.5027Z' fill='#263238' />
      <path d='M257.942 54.7707C257.89 54.7827 257.836 54.7812 257.785 54.7662C257.734 54.7512 257.688 54.7232 257.651 54.6851C257.403 54.4134 257.095 54.2032 256.752 54.0709C256.409 53.9387 256.04 53.8881 255.674 53.9232C255.636 53.9319 255.597 53.9327 255.559 53.9257C255.521 53.9186 255.484 53.9037 255.452 53.882C255.42 53.8604 255.393 53.8323 255.372 53.7996C255.351 53.7669 255.337 53.7303 255.331 53.6921C255.324 53.6544 255.324 53.6156 255.331 53.578C255.339 53.5403 255.354 53.5046 255.375 53.4727C255.397 53.4409 255.425 53.4136 255.457 53.3925C255.489 53.3714 255.525 53.3568 255.562 53.3496C256.024 53.2946 256.493 53.3506 256.929 53.5128C257.366 53.6751 257.757 53.939 258.071 54.2827C258.099 54.3091 258.121 54.341 258.136 54.3763C258.151 54.4116 258.159 54.4497 258.159 54.4882C258.159 54.5267 258.151 54.5647 258.136 54.6001C258.121 54.6354 258.099 54.6672 258.071 54.6936C258.034 54.7285 257.99 54.7548 257.942 54.7707Z' fill='#263238' />
      <path d='M248.191 55.7381C248.135 55.7592 248.074 55.7592 248.019 55.7381C247.947 55.7129 247.887 55.66 247.854 55.5911C247.82 55.5221 247.815 55.4427 247.84 55.3701C247.981 54.9294 248.227 54.5296 248.556 54.2046C248.886 53.8796 249.289 53.6392 249.731 53.5038C249.806 53.4846 249.886 53.4958 249.953 53.5351C250.019 53.5745 250.067 53.6387 250.087 53.7136C250.106 53.7885 250.095 53.868 250.055 53.9347C250.016 54.0013 249.952 54.0495 249.877 54.0688C249.527 54.1767 249.209 54.369 248.951 54.6287C248.692 54.8885 248.502 55.2077 248.396 55.5584C248.38 55.6033 248.352 55.6433 248.316 55.6747C248.281 55.7062 248.237 55.728 248.191 55.7381Z' fill='#263238' />
      <path d='M264.389 53.2556C264.389 53.2556 260.109 51.689 258.157 49.2065C258.157 49.2065 254.27 50.2252 253.473 51.7746C253.11 51.4635 252.875 51.0289 252.813 50.5547C252.752 50.0804 252.868 49.6001 253.139 49.2065C253.139 49.2065 250.228 49.8228 249.655 51.7746C249.182 51.5494 248.793 51.1785 248.547 50.7162C248.3 50.2539 248.208 49.7247 248.285 49.2065C248.285 49.2065 246.992 49.6944 246.573 51.7746C246.573 51.7746 243.362 45.0803 253.97 43.1627C253.167 42.8904 252.326 42.7487 251.478 42.7433C251.478 42.7433 258.884 39.7556 264.749 42.4864C270.614 45.2173 269.595 50.5077 264.389 53.2556Z' fill='#263238' />
      <path d='M290.998 134.135L280.725 135.505C280.725 135.505 276.487 105.894 274.62 96.332V75.9237L279.988 74.5112L284.149 75.9237C285.29 80.0297 286.168 84.2043 286.778 88.4221C287.283 91.6237 287.736 95.048 288.139 98.515C288.695 103.24 289.158 108.051 289.551 112.554C290.168 119.822 290.579 126.251 290.801 130.18C290.93 132.646 290.998 134.135 290.998 134.135Z' fill='#561885' />
      <path d='M274.629 96.315C274.629 96.315 275.485 81.9076 279.997 74.4771C279.997 74.4771 279.886 72.3541 278.987 72.0116C278.088 71.6692 274.629 73.7237 274.629 73.7237C274.629 73.7237 272.48 72.2513 272.009 72.9362C271.538 73.621 270.391 78.1752 274.629 96.315Z' fill='#561885' />
      <path opacity='0.7' d='M274.629 96.315C274.629 96.315 275.485 81.9076 279.997 74.4771C279.997 74.4771 279.886 72.3541 278.987 72.0116C278.088 71.6692 274.629 73.7237 274.629 73.7237C274.629 73.7237 272.48 72.2513 272.009 72.9362C271.538 73.621 270.391 78.1752 274.629 96.315Z' fill='white' />
      <path d='M251.273 138.082L252.446 138.938C252.446 138.938 250.734 129.393 251.436 105.252C251.436 104.088 251.513 102.967 251.555 101.896C252.412 81.471 255.597 77.7558 255.836 77.5332L252.874 78.5433L247.506 80.3924C245.888 97.8131 251.273 138.082 251.273 138.082Z' fill='#561885' />
      <path d='M251.735 98.3782C251.735 98.3782 248.773 84.3731 249.655 79.545C250.536 74.7169 253.695 76.8656 253.695 76.8656C253.695 76.8656 256.597 74.52 257.462 77.1053C257.462 77.1053 253.473 79.9131 251.735 98.3782Z' fill='#561885' />
      <path opacity='0.7' d='M251.735 98.3782C251.735 98.3782 248.773 84.3731 249.655 79.545C250.536 74.7169 253.695 76.8656 253.695 76.8656C253.695 76.8656 256.597 74.52 257.462 77.1053C257.462 77.1053 253.473 79.9131 251.735 98.3782Z' fill='white' />
      <path opacity='0.2' d='M288.121 98.4811C283.738 96.0242 283.156 89.6123 283.156 89.6123C283.422 89.5096 285.048 88.9532 286.76 88.3882C287.265 91.5898 287.719 95.014 288.121 98.4811Z' fill='black' />
      <path opacity='0.2' d='M266.683 181.698L270.373 161.624L269.663 151C269.663 151 264.603 169.422 266.683 181.698Z' fill='black' />
      <path opacity='0.2' d='M290.784 130.146C288.027 129.958 285.648 129.778 285.648 129.778L285.125 118.572C285.985 118.035 287.016 117.845 288.01 118.042C288.688 116.251 289.201 114.403 289.543 112.52C290.142 119.788 290.561 126.225 290.784 130.146Z' fill='black' />
      <path d='M291.726 112.512L286.358 121.132L290.604 123.572L295.647 115.808L291.726 112.512Z' fill='#E4897B' />
      <path d='M283.918 75.7871C287.531 76.1809 302.89 97.2569 303.567 103.609C304.243 109.961 294.543 120.927 294.543 120.927L288.07 117.117C288.07 117.117 294.646 107.007 294.988 104.277C295.331 101.546 282.959 90.5112 282.959 90.5112C282.024 87.9693 281.509 85.2918 281.435 82.5842C281.264 77.9444 283.918 75.7871 283.918 75.7871Z' fill='#561885' />
      <path d='M285.117 118.581C285.117 118.581 290.639 119.762 292.865 123.238C292.865 123.238 290.553 130.472 288.901 131.259C287.249 132.047 283.156 132.047 283.156 132.047C283.156 132.047 282.891 124 285.117 118.581Z' fill='#561885' />
      <defs>
        <linearGradient id='paint0_linear_10832_18884' x1='76.6154' y1='111.216' x2='298.519' y2='227.399' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#6A00BA' stopOpacity='0.53' />
          <stop offset='1' stopColor='#561885' stopOpacity='0' />
        </linearGradient>
      </defs>
    </SvgIcon>
  )
}
