import { Box, Typography, useMediaQuery } from '@mui/material'
import { theme } from '~/utils'

export type HowItWorksBannerProps = {
  title: string
  description: string
  backgroundImageUrl: string
}

export const HowItWorksBanner: React.FC<HowItWorksBannerProps> = ({ backgroundImageUrl, description, title }) => {
  const isLowerLg = useMediaQuery(theme.breakpoints.down('lg'))

  return (
    <Box sx={{
      gap: 1,
      flex: 1,
      padding: 4,
      height: '100%',
      display: 'flex',
      borderRadius: '12px',
      flexDirection: 'column',
      background: `linear-gradient(180deg, rgba(50, 50, 50, 0) 20%, rgba(0, 0, 0, 1) 100%), url(${backgroundImageUrl}), #434343`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      justifyContent: 'end'
    }}>
      <Typography variant='body2' color='yellow.light' fontWeight={600} marginTop={isLowerLg ? '160px' : '0px'}>COMO FUNCIONA</Typography>
      <Typography variant='h3' fontWeight={600} color='common.white'>{title}</Typography>
      <Typography color='common.white'>{description}</Typography>
    </Box>
  )
}
