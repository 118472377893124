import { 
  FacebookShareButton, 
  FacebookIcon, 
  WhatsappShareButton, 
  WhatsappIcon, 
  TwitterShareButton, 
  TwitterIcon, 
  TelegramShareButton,
  TelegramIcon,
  LinkedinShareButton,
  LinkedinIcon
} from 'react-share'
import { Container } from './styles'
import { usePartner } from '~/contexts'

export const ShareSocialMediaLinks: React.FC = () => {
  const { indicationLink } = usePartner()

  return (
    <Container>
      <FacebookShareButton url={indicationLink}>
        <FacebookIcon size={32} round={true}  />
      </FacebookShareButton>
      <WhatsappShareButton url={indicationLink}>
        <WhatsappIcon size={32} round={true}  />
      </WhatsappShareButton>
      <TwitterShareButton url={indicationLink}>
        <TwitterIcon size={32} round={true}  />
      </TwitterShareButton>
      <TelegramShareButton url={indicationLink}>
        <TelegramIcon size={32} round={true}  />
      </TelegramShareButton>
      <LinkedinShareButton url={`https://www.linkedin.com/sharing/share-offsite/?url=${indicationLink}`}>
        <LinkedinIcon size={32} round={true}  />
      </LinkedinShareButton>
    </Container>
  )
}
