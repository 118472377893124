import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react'
import { useApiPagination } from '~/hooks'
import { IndicationsContextData, IndicationsListData } from './types'
import { useGetIndicationsQuery } from '~/graphql/types'
import { toast } from 'react-toastify'

const IndicationsContext = createContext({} as IndicationsContextData)

export const IndicationsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [indications, setIndications] = useState<IndicationsListData>([])
  const paginationData = useApiPagination({ pageSize: 20, currentPage: 0 })

  const { data, loading: indicationsListIsLoading } = useGetIndicationsQuery({
    variables: {
      params: {
        pageSize: paginationData.metadata.pageSize,
        pageNumber: paginationData.metadata.currentPage,
        filter: {
          ...paginationData.filtersTable?.nameCustomerOrSalesman.length > 0 ? { nameCustomerOrSalesman: paginationData.filtersTable?.nameCustomerOrSalesman } : {},
          ...paginationData.filtersTable?.status?.length > 0 ? { status: paginationData.filtersTable?.status } : {},
          ...paginationData.filtersTable?.origin?.length > 0 ? { origin: paginationData.filtersTable?.origin } : {},
          ...paginationData.filtersTable?.begin?.length > 0 ? { begin: new Date(paginationData.filtersTable?.begin) } : {},
          ...paginationData.filtersTable?.end?.length > 0 ? { end: new Date(`${paginationData.filtersTable?.end}Z23:59:59`) } : {}
        },
        sort: {
          field: 'createdAt',
          order: -1
        }
      }
    },
    onError(error) {
      toast.error(error.message)
    },
    onCompleted(dataResponse) {
      paginationData.setMetadata({ ...paginationData.metadata, documentCounts: dataResponse.getIndications.meta.documentCounts })
    },
  })

  useEffect(() => {
    if(data) {
      setIndications(data.getIndications.data as IndicationsListData)
    }
  }, [data])

  return (
    <IndicationsContext.Provider value={{
      indications,
      setIndications,
      paginationData,
      indicationsListIsLoading,
    }}>
      {children}
    </IndicationsContext.Provider>
  )
}

export const useIndications = () => useContext(IndicationsContext)
