import { Box, Step, StepButton, StepIconProps, StepLabel, Typography } from '@mui/material'
import { Card, ContentTitle, PageLoader, IndicationProposalInformations } from '~/components'
import { BasicData, Address, Documents, LegalRepresentativies } from './components'
import { useIndicationFlow, usePartner } from '~/contexts'
import { Navigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useCallback } from 'react'
import { CustomIconContainer, StyledStepper } from './styles'
import { Check, ErrorOutline } from '@mui/icons-material'

export const IndicationsCreateV2: React.FC = () => {
  const { myPartner } = usePartner()
  const { stepper, goToPage, getIndcationAndProposalIsLoading, isPf, selectedProposal, selectedIndication } = useIndicationFlow()

  const renderStepperIndication = useCallback(() => {
    if(stepper === 0) {
      return <BasicData />
    }

    if(stepper === 1) {
      return <Address />
    }

    if(isPf) {

      if(stepper === 2) {
        return <Documents />
      }

    } else {

      if(stepper === 2) {
        return <LegalRepresentativies />
      }
      if(stepper === 3) {
        return <Documents />
      }

    }
  }, [stepper, isPf])

  const steps = isPf ? [
    'Dados básicos',
    'Endereço',
    'Documentos',
  ] : [
    'Dados básicos',
    'Endereço',
    'Representantes',
    'Documentos',
  ]

  const customerPendencies = selectedProposal?.customerRef.pendencies

  const basicDataPendency = 
    Boolean(customerPendencies?.name.rejectedAt) ||
    Boolean(customerPendencies?.document.rejectedAt) ||
    Boolean(customerPendencies?.email.rejectedAt) ||
    Boolean(customerPendencies?.phone.rejectedAt)

  const addressPendency = 
    Boolean(customerPendencies?.zipcode.rejectedAt) ||
    Boolean(customerPendencies?.state.rejectedAt) ||
    Boolean(customerPendencies?.city.rejectedAt) ||
    Boolean(customerPendencies?.neighborhood.rejectedAt) ||
    Boolean(customerPendencies?.street.rejectedAt) ||
    Boolean(customerPendencies?.number.rejectedAt)

  const documentPendency = 
  Boolean(selectedProposal?.files?.some(item => item.rejectedAt))

  const legalRepresentativyPendency = selectedProposal?.customerRef?.legalRepresentative?.some(item => {
    const hasPendency = 
      Boolean(item.pendencies?.name.rejectedAt) ||
      Boolean(item.pendencies?.document.rejectedAt) ||
      Boolean(item.pendencies?.identificationNumber.rejectedAt) ||
      Boolean(item.pendencies?.email.rejectedAt) ||
      Boolean(item.pendencies?.phone.rejectedAt) ||
      Boolean(item.pendencies?.maritalStatus.rejectedAt) ||
      Boolean(item.pendencies?.nationality.rejectedAt) ||
      Boolean(item.pendencies?.zipcode.rejectedAt) ||
      Boolean(item.pendencies?.state.rejectedAt) ||
      Boolean(item.pendencies?.city.rejectedAt) ||
      Boolean(item.pendencies?.neighborhood.rejectedAt) ||
      Boolean(item.pendencies?.street.rejectedAt) ||
      Boolean(item.pendencies?.number.rejectedAt) ||
      Boolean(item.files?.some(file => file.rejectedAt))

    return hasPendency
  })

  const hasStepPendencies = isPf ? [
    basicDataPendency,
    addressPendency,
    documentPendency
  ] : [
    basicDataPendency,
    addressPendency,
    legalRepresentativyPendency,
    documentPendency
  ]

  const CustomStepIcon = (props: StepIconProps) => {
    return (
      <CustomIconContainer active={!!props.active} completed={!!props.completed} error={props.error}>
        {props.error && <ErrorOutline color='error' />}
        {!props.error && props.completed && <Check fontSize='small' sx={{ color: '#3BA1FF' }} />}
        {!props.error && !props.completed && <Typography color={props.active ? 'white.main' : '#CDCDCD'}>{props.icon}</Typography>}
      </CustomIconContainer>
    )
  }

  if(!myPartner?.configuration.allowManualProposal) {
    toast.error('Você não tem  permissão para acessar esta página, entre em contato com seu parceiro para habilitar esta função!')
    return <Navigate to='/app/' />
  }

  if(getIndcationAndProposalIsLoading) {
    return <PageLoader />
  }

  return (
    <>
      <Box sx={{ minHeight: '100vh', height: '100%' }}>
        <Card gap={5}>
          <ContentTitle
            title='Detalhes da indicação'
            style={{ padding: 0 }}
            description='Consulte e faça alterações nos dados da indicação'
            breadcrumbLinks={{ currentLink: 'Gerar indicação', links: [{ href: '/app/indications', label: 'Indicações' }] }}
            rightContent={selectedProposal && selectedIndication?.status ? <IndicationProposalInformations indicationStatus={selectedIndication?.status} proposal={selectedProposal} /> : <></>}
          />

          <StyledStepper
            nonLinear
            activeStep={stepper}
          > 
            {steps.map((label, index) => {
              return (
                <Step key={label}>
                  <StepButton onClick={() => goToPage(index)}>
                    <StepLabel
                      StepIconComponent={CustomStepIcon}
                      error={hasStepPendencies[index]}
                    >
                      <Typography sx={{ whiteSpace: 'nowrap' }}>
                        {label}
                      </Typography>
                    </StepLabel>
                  </StepButton>
                </Step>
              )
            })}
          </StyledStepper>
          {renderStepperIndication()}

        </Card>
      </Box>
    </>
  )
}
