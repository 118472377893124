import { CSSProperties, InputHTMLAttributes, useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Box, Checkbox, CheckboxProps, FormControlLabel, RadioProps } from '@mui/material'

export type InnerCheckboxMultipleProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'defaultValue'> & Omit<CheckboxProps, 'defaultValue'> & {
  name: string
  checkboxProps?: RadioProps,
  shouldUnregister?: boolean
  direction?: CSSProperties['flexFlow']
  options: {
    label?: string,
    value: string
    checkboxProps?: RadioProps,
  }[]
}

export const CheckboxMultiple: React.FC<InnerCheckboxMultipleProps> = ({ name, direction = 'column', shouldUnregister = false, options }) => {
  const formMethods = useFormContext()

  const onClick = useCallback((newValue: string, values: string[] = []) => {
    if(values.includes(newValue)) {
      return values.filter(item => item !== newValue)
    } else {
      return [...values, newValue]
    }
  }, [formMethods])

  return (
    <>
      <Controller
        name={name}
        control={formMethods.control}
        shouldUnregister={shouldUnregister}
        defaultValue={[]}
        render={({ field: { value, onChange } }) => (
          <Box sx={{ display: 'flex', flexFlow: direction, padding: '.8rem 0 0 .6rem' }}>
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                label={option.label}
                checked={value?.includes(option.value)}
                onChange={() => onChange(onClick(option.value, value))}
                control={
                  <Checkbox {...option.checkboxProps} />
                }
              />
            ))}
          </Box>
        )
        }
      />
    </>

  )
}
