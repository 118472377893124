import { translateComissionsStatus } from '~/utils'
import { Container } from './styles'
import { ECommissionStatus } from '~/graphql/types'

type ComissionStatusProps = {
  status: ECommissionStatus
}

export const ComissionStatus: React.FC<ComissionStatusProps> = ({ status }) => {
  return (
    <Container status={status || ECommissionStatus.notPaid}>
      <span>{translateComissionsStatus(status)}</span>
    </Container>
  )
}
