import { EMaritalStatus, GetProposalAndIndicationByIdQuery, ProposalFileModel, ProposalLegalRepresentativePendenciesFields } from '~/graphql/types'
import { Unarray } from '~/utils'

export type AddressForm = {
  city: string
  state: string
  street: string
  number: string
  zipcode: string
  complement: string
  neighborhood: string
}

export type LegalRepresentativeType = {
  //strings
  name: string
  email: string
  phone: string
  document: string
  nationality: string
  address: AddressForm
  maritalStatus: EMaritalStatus
  identificationNumber: string
  pendencies?: Omit<ProposalLegalRepresentativePendenciesFields, 'country'> | null | undefined
  files?: ProposalFileModel[]

  //files
  customerPjRepresentativeDocumentWithPhotoBack: null | File[]
  customerPjRepresentativeDocumentWithPhotoFront: null | File[]
}

export const LEGAL_REPRESENTATIVE_INITIAL_VALUES: LegalRepresentativeType = {
  name: '',
  email: '',
  phone: '',
  document: '',
  nationality: '',
  maritalStatus: '' as EMaritalStatus,
  identificationNumber: '',
  address: {
    city: '',
    state: '',
    number: '',
    street: '',
    zipcode: '',
    complement: '',
    neighborhood: ''
  },
  customerPjRepresentativeDocumentWithPhotoBack: null,
  customerPjRepresentativeDocumentWithPhotoFront: null
}

type PendencyObject = GetProposalAndIndicationByIdQuery['getIndicationProposalById']['customerRef']['pendencies']

export type BasicDataPendency = 'name' | 'document' | 'email' | 'phone' | 'nationality' | 'maritalStatus'

export const hasPendencyOrNullOnBasicData = (fieldName: BasicDataPendency, pendencies: PendencyObject) => {
  if(!pendencies) {
    return true
  }

  if(!pendencies[fieldName].sendedAt) {
    return true
  }

  if(!pendencies[fieldName].rejectedAt) {
    return true
  }

  return false
}

export type AddressPendency = 'zipcode' | 'state' | 'city' | 'neighborhood' | 'street' | 'number' | 'complement'

export const hasPendencyOrNullOnAddress = (fieldName: AddressPendency, pendencies: PendencyObject) => {
  if(!pendencies) {
    return true
  }

  if(!pendencies[fieldName].sendedAt) {
    return false
  }

  if(pendencies[fieldName].rejectedAt) {
    return false
  }

  return true
}

export type LegalRepresentativyPendency = keyof Omit<ProposalLegalRepresentativePendenciesFields, 'country'>

export const hasPendencyOrNullOnLegalRepresentativy = (fieldName: LegalRepresentativyPendency, pendencies?: Omit<ProposalLegalRepresentativePendenciesFields, 'country'> | null | undefined) => {
  if(!pendencies) {
    return false
  }

  if(!pendencies[fieldName].sendedAt) {
    return false
  }

  if(pendencies[fieldName].rejectedAt) {
    return false
  }

  return true
}

export const hasDuplicatedValuesInLegalRepresentativies = (array: any[]) => { 

  const identificationNumber = array.map(function(value) {
    return value.identificationNumber
  })

  const document = array.map(function(value) {
    return value.document
  })

  const phone = array.map(function(value) {
    return value.phone
  })

  const email = array.map(function(value) {
    return value.email
  })

  const myValues = [...identificationNumber, ...document, ...phone, ...email]

  return myValues.some(function(value, index, myArray) {
    if(value.length > 1) {
      return myArray.indexOf(value) !== myArray.lastIndexOf(value)
    }
  })
}

type FileProposal = Unarray<GetProposalAndIndicationByIdQuery['getIndicationProposalById']['files']>

export const documentFileIsDisabled = (file: FileProposal | undefined) => {

  if(!file?.sendAt) {
    return false
  }

  if(file?.rejectedAt) {
    return false
  }

  return true
}
