import { Typography } from '@mui/material'
import { Card } from '~/components'
import { LoginForm } from './components'
import { LoginBackground } from './style'
import DespertaLogo from '~/assets/images/logo/desperta-logo.svg'

export const Login: React.FC = () => {

  return (
    <>
      <LoginBackground>
        <Card sx={{ width: '100%', maxWidth: '470px' }}>
          <img style={{ alignSelf: 'start' }} src={DespertaLogo} height={40} />

          <Typography
            variant='h5'
            fontWeight={600}
            margin='24px 0px 8px 0px'
          >
            Faça seu login
          </Typography>

          <Typography
            variant='body1'
            color='grey.400'
            margin='9px 0px 32px 0px'
            sx={{ maxWidth: '300px' }}
          >
            Informe seus dados para continuar
          </Typography>

          <LoginForm />

        </Card>
      </LoginBackground>
    </>
  )
}
