import * as yup from 'yup'
import { toast } from 'react-toastify'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { IconArrowRight, IconLock } from '@tabler/icons-react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { CircularProgress, useMediaQuery, Button } from '@mui/material'
import { Input } from '~/components'
import { passwordRegex, theme } from '~/utils'
import { useAffiliatePartnerUserChangePasswordMutation } from '~/graphql/types'

export type FirstAccessFormData = {
  newPassword: string,
  retypeNewPassword: string,
}

const schema = yup
  .object({
    newPassword: yup.string()
      .required()
      .min(6)
      .matches(passwordRegex.hasUppercase, 'Pelo menos 1 carácter maiúsculo')
      .matches(passwordRegex.hasLowercase, 'Pelo menos 1 carácter minúsculo')
      .matches(passwordRegex.hasNumeric, 'Pelo menos 1 carácter numérico')
      .matches(passwordRegex.hasSpecialCharacter, 'Pelo menos 1 carácter especial')
    ,
    retypeNewPassword: yup.string().required().oneOf([yup.ref('newPassword')], 'A confirmação de senha tem que ser igual a senha.'),
  })
  .required()

export const FirstAccessForm: React.FC = () => {
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const navigate = useNavigate()

  const [affiliatePartnerChangePassword, { loading: changePasswordIsLoading }] = useAffiliatePartnerUserChangePasswordMutation({
    onCompleted() {
      toast.success('Sua senha foi cadastrada, agora é só acessar!')
      setTimeout(() => {
        navigate('/')
      }, 1000)
    },
    onError() {
      toast.error('Link expirado. Por favor solicite novamente a recuperação de senha.')
    },

  })

  const formMethods = useForm<FirstAccessFormData>({
    resolver: yupResolver(schema)
  })

  // const { newPassword } = useWatch({ control: formMethods.control })

  // const hasSixDigits = Boolean(newPassword && newPassword?.length >= 6)
  // const hasUppercase = newPassword?.match(passwordRegex.hasUppercase)
  // const hasLowercase = newPassword?.match(passwordRegex.hasLowercase)
  // const hasNumeric = newPassword?.match(passwordRegex.hasNumeric)
  // const hasSpecialCharacter = newPassword?.match(passwordRegex.hasSpecialCharacter)

  // const requestPasswordFilled = 
  // [hasSixDigits, hasUppercase, hasLowercase, hasNumeric, hasSpecialCharacter].filter(item => item).length

  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const onSubmit: SubmitHandler<FirstAccessFormData> = async (data) => {
    if(token) {
      await affiliatePartnerChangePassword({ variables: {
        params: {
          ...data,
          token
        }
      } })
    } else {
      toast.error('Solicite uma nova recuperação de senha.')
    }
  }

  return (
    <FormProvider {...formMethods}>
      <form style={{ width: '100%', display: 'flex', flexDirection: 'column' }} onSubmit={formMethods.handleSubmit(onSubmit)}>

        <Input
          icons={{
            start: !isLowerSm ? { element: <IconLock style={{ color: '#999999' }} />, background: '#FAFAFA' } : undefined
          }}
          type='password'
          label='Nova Senha'
          name='newPassword'
          style={{ margin: '24px 0px 8px 0px' }}
        />

        {/* <GridColumn padding='1rem 0 1rem 0' columns={6}>
          <Divider sx={{ borderRadius: '2px', borderWidth: '2px', background: requestPasswordFilled >= 0 ? theme.palette.error.main : theme.palette.grey[100] }} />
          <Divider sx={{ borderRadius: '2px', borderWidth: '2px', background: requestPasswordFilled >= 1 ? theme.palette.error.main : theme.palette.grey[100] }} />
          <Divider sx={{ borderRadius: '2px', borderWidth: '2px', background: requestPasswordFilled >= 2 ? theme.palette.yellow.main : theme.palette.grey[100] }} />
          <Divider sx={{ borderRadius: '2px', borderWidth: '2px', background: requestPasswordFilled >= 3 ? theme.palette.yellow.main : theme.palette.grey[100] }} />
          <Divider sx={{ borderRadius: '2px', borderWidth: '2px', background: requestPasswordFilled >= 4 ? theme.palette.success.main : theme.palette.grey[100] }} />
          <Divider sx={{ borderRadius: '2px', borderWidth: '2px', background: requestPasswordFilled >= 5 ? theme.palette.success.main : theme.palette.grey[100] }} />
        </GridColumn> */}

        {/* <Ul>
          <Li>
            {hasUppercase ? 
              <IconCheck color={theme.palette.success.main} />
              : 
              <IconX color={theme.palette.error.main} />
            }
            Maiúsculas
          </Li>
          <Li>
            {hasLowercase ? 
              <IconCheck color={theme.palette.success.main} />
              : 
              <IconX color={theme.palette.error.main} />
            }
            Minúsculas
          </Li>
          <Li>
            {hasNumeric ? 
              <IconCheck color={theme.palette.success.main} />
              : 
              <IconX color={theme.palette.error.main} />
            }
            Números
          </Li>
          <Li>
            {hasSpecialCharacter ? 
              <IconCheck color={theme.palette.success.main} />
              : 
              <IconX color={theme.palette.error.main} />
            }
            Símbolos
          </Li>
          <Li style={{ gridColumn: '1/-1' }}>
            {hasSixDigits ? 
              <IconCheck color={theme.palette.success.main} />
              :
              <IconX color={theme.palette.error.main} />
            }
            Mínimo de 6 carácteres
          </Li>
        </Ul> */}

        <Input
          icons={{
            start: !isLowerSm ? { element: <IconLock style={{ color: '#999999' }} />, background: '#FAFAFA' } : undefined
          }}
          type='password'
          label='Confirmação de nova senha'
          name='retypeNewPassword'
          onPaste={(e) => e.preventDefault()}
          style={{ margin: '24px 0px 36px 0px' }}
        />

        <Button
          disabled={changePasswordIsLoading}
          style={{ fontSize: '16px', fontWeight: 600 }}
          variant='contained'
          color='primary'
          type='submit'
          endIcon={changePasswordIsLoading ? <CircularProgress size={24} color='inherit' /> : (
            <>
              {!isLowerSm && 
              <IconArrowRight />
              }
            </>
          )}
        >
          Concluir definição de senha
        </Button>
      </form>
    </FormProvider>
  )
}
