import { Box } from '@mui/material'
import { Card, ContentTitle } from '~/components'
import { IndicationsCreateBanner, IndicationsCreateForm } from './components'
import { usePartner } from '~/contexts'
import { Navigate } from 'react-router-dom'
import { toast } from 'react-toastify'

export const IndicationsCreate: React.FC = () => {
  const { myPartner } = usePartner()

  if(!myPartner?.configuration.allowManualProposal) {
    toast.error('Você não tem  permissão para acessar esta página, entre em contato com seu parceiro para habilitar esta função!')
    return <Navigate to='/app/' />
  }

  return (
    <>
      <Box sx={{ minHeight: '100vh', height: '100%' }}>
        <Card gap={5}>
          <ContentTitle
            title='Gerar indicação'
            style={{ padding: 0 }}
            description='Faça uma indicação solicitando uma proposta para seu cliente'
            breadcrumbLinks={{ currentLink: 'Gerar indicação', links: [{ href: '/app/indications', label: 'Indicações' }] }}
          />

          <IndicationsCreateBanner />

          <IndicationsCreateForm />
        </Card>
      </Box>
    </>
  )
}
