import { InputHTMLAttributes, useState } from 'react'
import { FormControl, TextField, InputAdornment, IconButton, TextFieldProps } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { PatternFormat, NumericFormat } from 'react-number-format'
import { useMask, usePlaceholder, usePrefix, useSuffix } from './utils'
import { IconProps, IconsProps, TinputMaskKeys } from './types'
import { Visibility, VisibilityOff } from '@mui/icons-material'

type InputProps = InputHTMLAttributes<HTMLInputElement> & TextFieldProps & {
  name: string
  label?: string,
  mask?: TinputMaskKeys,
  type?: 'text' | 'password' | 'date' | 'number' | 'hidden',
  icons?: IconsProps
  customMask?: string
  decimalScale?: number
}

const renderStartIcon = (props?: IconProps) => {
  if (props?.element) {
    return (
      <InputAdornment
        position='start'
        sx={{
          padding: props.padding ?? '27.5px 14px',
          backgroundColor: props.background ?? 'transparent',
          borderTopLeftRadius: props.background ? '8px' : '0',
          borderBottomLeftRadius: props.background ? '8px' : '0',
        }}
      >
        {props.element}
      </InputAdornment>
    )
  }
  return null
}

const renderEndIcon = (props?: IconProps) => {
  if (props?.element) {
    return (
      <InputAdornment
        position='end'
        sx={{
          padding: props.padding ?? '27.5px 14px',
          backgroundColor: props.background ?? 'transparent',
          borderTopRightRadius: props.background ? '8px' : '0',
          borderBottomRightRadius: props.background ? '8px' : '0',
        }}
      >
        {props.element}
      </InputAdornment>
    )
  }
  return null
}

export const Input = ({ name, label, mask, customMask, type = 'text', multiline = false, icons, minRows, decimalScale, ...rest }: InputProps) => {

  const { control } = useFormContext()
  const [showPassowrd, setShowPassword] = useState(false)

  return (
    <FormControl fullWidth sx={{
      '& .MuiInputBase-root': {
        paddingRight: icons?.end?.element ? '0' : '14px'
      }
    }}>

      <Controller
        name={name}
        control={control}
        defaultValue=''
        render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
          <>
            {mask || customMask ? (
              <>
                {mask === 'BRL' || mask === 'percentage' || mask === 'kwh' || mask === 'BRLToMWh' ? (
                  <NumericFormat
                    {...rest as any}
                    value={value}
                    onBlur={onBlur}
                    onValueChange={(valueChange) => onChange(valueChange.floatValue)}
                    name={name}
                    allowNegative={false}
                    //fixedDecimalScale={mask === 'BRL'}
                    thousandSeparator={mask !== 'kwh' ? '.' : undefined}
                    decimalSeparator={mask !== 'kwh' ? ',' : undefined}
                    decimalScale={decimalScale || (mask !== 'kwh' ? 2 : undefined)}
                    suffix={useSuffix(mask)}
                    prefix={usePrefix(mask)}
                    placeholder={usePlaceholder(mask)}
                    helperText={error?.message}
                    error={Boolean(error)}
                    customInput={TextField}
                    label={label}
                    type={showPassowrd ? 'text' : type}
                    InputProps={{
                      startAdornment: renderStartIcon(icons?.start),
                      endAdornment: (
                        <>
                          {type === 'password' && (
                            <InputAdornment position='end'>
                              <IconButton onClick={() => setShowPassword(!showPassowrd)}>
                                {showPassowrd ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          )}
                          {type !== 'password' && renderEndIcon(icons?.end)}
                        </>
                      )
                    }}
                  />
                ) : (
                  <PatternFormat
                    {...rest as any}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    onValueChange={(valueChange) => onChange(valueChange.formattedValue)}
                    name={name}
                    format={useMask(value, mask, customMask)}
                    allowEmptyFormatting={false}
                    helperText={error?.message}
                    error={Boolean(error)}
                    customInput={TextField}
                    label={label}
                    type={showPassowrd ? 'text' : type}
                    InputProps={{
                      startAdornment: renderStartIcon(icons?.start),
                      endAdornment: (
                        <>
                          {type === 'password' && (
                            <InputAdornment position='end'>
                              <IconButton onClick={() => setShowPassword(!showPassowrd)}>
                                {showPassowrd ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          )}
                          {type !== 'password' && renderEndIcon(icons?.end)}
                        </>
                      )
                    }}
                  />
                )}
              </>
            ) : (
              <TextField
                {...rest}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                name={name}
                multiline={multiline}
                helperText={error?.message}
                error={Boolean(error)}
                label={label}
                minRows={minRows || 1}
                type={showPassowrd ? 'text' : type}
                InputProps={{
                  inputProps: { style: { padding: multiline ? '0px 20px' : '' } },
                  startAdornment: renderStartIcon(icons?.start),
                  endAdornment: (
                    <>
                      {type === 'password' && (
                        <InputAdornment position='end'>
                          <IconButton onClick={() => setShowPassword(!showPassowrd)}>
                            {showPassowrd ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )}
                      {type !== 'password' && renderEndIcon(icons?.end)}
                    </>
                  )
                }}
              />
            )}
          </>
        )}
      />
    </FormControl>
  )
}
