import { createContext, useCallback, useContext, useState } from 'react'
import { MyPartnerData, PartnerContextData, PartnerProviderProps } from './types'
import { useAuth } from '../auth'
import { useAffiliatePartnerGetMyPartnerQuery } from '~/graphql/types'
import { useSidebar } from '../sidebar'
import { toast } from 'react-toastify'

const PartnerContext = createContext({} as PartnerContextData)

export const PartnerProvider: React.FC<PartnerProviderProps> = ({ children }) => {
  const { authUser } = useAuth()
  const { setCloseLogo, setOpenLogo } = useSidebar()
  const [myPartnerisLoading, setMyPartnerIsLoading] = useState(true)
  const [myPartner, setMyPartner] = useState<null | MyPartnerData>(null)
  
  const indicationLink = authUser?.owner ? 
    `${process.env.VITE_APP_WEBSITE_BASE_URL}/nova-proposta/${myPartner?.code}` 
    : `${process.env.VITE_APP_WEBSITE_BASE_URL}/nova-proposta/${myPartner?.code}?u=${authUser?._id}`
  
  useAffiliatePartnerGetMyPartnerQuery({
    onCompleted(data) {
      setMyPartner(data.affiliatePartnerGetMyPartner)
      if(data.affiliatePartnerGetMyPartner.configuration.icone?.fileUrl) {
        const urlIcone = `${process.env.VITE_APP_REST_API_URL}partners/icone/${data.affiliatePartnerGetMyPartner.code}`
        setCloseLogo(urlIcone)
      } 
      if(data.affiliatePartnerGetMyPartner.configuration.logo?.fileUrl) {
        const urlLogo = `${process.env.VITE_APP_REST_API_URL}partners/logo/${data.affiliatePartnerGetMyPartner.code}`
        setOpenLogo(urlLogo)
      }
      setMyPartnerIsLoading(false)
    },
    onError(error) {
      toast.error(error.message)
      setMyPartnerIsLoading(false)
    },
    skip: Boolean(!authUser),
    fetchPolicy: 'no-cache',
  })

  const resetPartner = useCallback(() => {
    setMyPartnerIsLoading(true)
    setMyPartner(null)
  }, [])

  return (
    <PartnerContext.Provider value={{
      myPartner,
      setMyPartner,
      getPartnerIsLoading: myPartnerisLoading,
      indicationLink,
      resetPartner
    }}>
      {children}
    </PartnerContext.Provider>
  )
}

export const usePartner = () => useContext(PartnerContext)
