import { Container } from './styles'

type ErrorTextProps = {
  error?: string | undefined
}

export const ErrorText: React.FC<ErrorTextProps> = ({ error }) => {
  return (
    <>
      {error && <Container>{error}</Container>}
    </>
  )
}
