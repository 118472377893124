import { translateUserStatus } from '~/utils'
import { Container } from './styles'
import { EAffiliatePartnerUserStatus } from '~/graphql/types'

type UserStatusProps = {
  status: EAffiliatePartnerUserStatus
}

export const UserStatus: React.FC<UserStatusProps> = ({ status }) => {
  return (
    <Container status={status || EAffiliatePartnerUserStatus.pending}>
      <span>{translateUserStatus(status)}</span>
    </Container>
  )
}
