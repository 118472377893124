import { Box } from '@mui/material'
import { IconHome, IconPercentage, IconSettings, IconUser, IconUsers } from '@tabler/icons-react'
import { MouseEventHandler, PropsWithChildren, useMemo } from 'react'
import { useAuth } from '~/contexts'
import { useSidebar } from '~/contexts/sidebar'
import { Collapsibles, Navbar, Sidebar } from './components'
import { DrawerHeader } from './components/Sidebar/style'
import { usePartner } from '~/contexts/partner'

export const drawerWidth = 270

export type TSidebarItem = {
  text: string
  icon: JSX.Element
  path?: string
  collapsibleName?: keyof Collapsibles
  nested?: {
    text: string
    path: string
    onClick?: MouseEventHandler<HTMLLIElement> | undefined
  }[]
}

export const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  const { authUser, isOwner } = useAuth()
  const { myPartner } = usePartner()
  const { isVisible, toggleSidebar } = useSidebar()

  const subAffiliateSidebar: TSidebarItem[] = [
    { text: 'Home', icon: <IconHome />, path: `/app` },
    {
      text: 'Indicações', icon: <IconUsers />, path: '/app/indications/*', collapsibleName: 'indications',
      nested: [
        { text: 'Suas indicações', path: '/app/indications' },
        myPartner?.configuration.allowManualProposal && { text:  'Gerar indicação', path: '/app/indications/create' },
      ].filter(item => !!item)
    }
  ].filter(item => Boolean(item)) as TSidebarItem[]

  const ownerAffiliateSidebar: TSidebarItem[] = [
    { text: 'Home', icon: <IconHome />, path: `/app` },
    { text: 'Indicações', icon: <IconUsers />, path: '/app/indications', collapsibleName: 'indications',
      nested: [
        { text: isOwner ? 'Todas indicações' : 'Suas indicações', path: '/app/indications' },
        myPartner?.configuration.allowManualProposal && { text:  'Gerar indicação', path: '/app/indications/create' },
      ].filter(item => !!item)
    },
    { text: 'Comissão', icon: <IconPercentage />, path: '/app/commissions' },
    { text: 'Usuários', icon: <IconUser />, path: `/app/users`, collapsibleName: 'users', 
      nested: [
        {
          text: 'Lista de usuários',
          path: '/app/users'
        },
        {
          text: 'Cadastrar usuários',
          path: '/app/users/create'
        },
      ]
    },
    {
      text: 'Configuração', icon: <IconSettings />, path: `/app/settings/*`, collapsibleName: 'settings',
      nested: [
        { text: 'Desenvolvedor', path: '/app/settings/developer' },
        { text: 'Geral', path: '/app/settings' },
        { text: 'Benefícios', path: '/app/settings/benefits' }
      ]
    },
  ].filter(item => Boolean(item)) as TSidebarItem[]

  const sidebarItems: TSidebarItem[] = useMemo(() =>
    isOwner
      ? ownerAffiliateSidebar
      : subAffiliateSidebar, [authUser, myPartner]
  )

  return (
    <Box sx={{ display: 'flex' }}>
      <Navbar isDrawerOpen={isVisible} toggleDrawer={toggleSidebar} />
      <Sidebar toggleSidebar={toggleSidebar} sidebarItems={sidebarItems} isDrawerOpen={isVisible} />
      <Box component='main' sx={{ flexGrow: 1, padding: 3, backgroundColor: '#FAFAFA', minHeight: '100vh', height: '100%', width: 'calc(100vw - 294px)' }}>
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  )
}
