import { Box, Divider } from '@mui/material'
import { Card, ContentTitle } from '~/components'
import { BenefitsPercentageTable } from './components'

export const BenefitsSettings: React.FC = () => {
  return (
    <Box sx={{ minHeight: '100vh', height: '100%' }}>
      <Card>
        <ContentTitle
          title='Benefício por concessionária'
          style={{ padding: '0px 0px 1.6rem 0px' }}
          description='Verifique e edite os benefício oferecidos por cada concessionária'
          breadcrumbLinks={{ currentLink: 'Benefícios', links: [{ href: '/app/settings', label: 'Configurações' }] }}
        />
        <Divider />

        <BenefitsPercentageTable />
      </Card>
    </Box>
  )
}
