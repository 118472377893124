import { useCallback, useEffect, useMemo } from 'react'
import { IconArrowLeft, IconCheck, IconCircle, IconCirclePlus, IconEqual, IconInfoCircle } from '@tabler/icons-react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { Box, Button, CircularProgress, Divider, Grid, Typography, useMediaQuery } from '@mui/material'
import { brazilStates } from '~/constants'
import { Form, Input, PageLoader, Radio, Select } from '~/components'
import { maritalStatusOptions, Option, personTypeOptions } from '~/utils/options'
import { usePartner } from '~/contexts/partner'
import { documentSchema, phoneSchema } from '~/utils/yupSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { clearString, transformMoney } from '~/utils'
import { useTheme } from '@emotion/react'
import { IndicationsCreateBanner } from './components'
import { useDealershipsPartner, useIndicationFlow } from '~/contexts'
import { BasicDataPendency, hasPendencyOrNullOnBasicData } from '../utils'
import { EMaritalStatus, EPersonType, useGetProposalAndIndicationByIdLazyQuery, useUpdateIndicationMutation } from '~/graphql/types'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'

type FormData = {
  name: string,
  document: string,
  email: string,
  phone: string,
  uf: string,
  dealershipId: string,
  averageEnergyBillValueCents: number
  commissionPercentage: number
  maritalStatus: EMaritalStatus
  nationality: string;
  personType: EPersonType
}

export const BasicData: React.FC = () => {
  const theme = useTheme()
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const { myPartner } = usePartner()
  const { dealerships, dealershipsIsLoading } = useDealershipsPartner()
  const { selectedIndication, selectedProposal, setSelectedProposal, setSelectedIndication } = useIndicationFlow()

  const schema = yup.object({
    name: yup.string().required(),
    document: documentSchema,
    email: yup.string().email().required(),
    phone: phoneSchema,
    nationality: yup.string(),
    maritalStatus: yup.string(),
  }).required()

  const formMethods = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      uf: '',
      commissionPercentage: 0
    }
  })

  const watchFields = useWatch({ control: formMethods.control })
  const isPf = watchFields.personType === EPersonType.pf
  const selectedDealership = dealerships.find(item => item.dealershipId === watchFields.dealershipId)

  const [getProposalAndIndicationById, { loading: getProposalAndIndicationByIdIsLoading }] = useGetProposalAndIndicationByIdLazyQuery({
    onCompleted(data) {
      if (data.getIndicationProposalById._id && data.getIndicationById._id) {
        setSelectedIndication(data.getIndicationById)
        setSelectedProposal(data.getIndicationProposalById)
        toast.success('Os dados básicos foram alterados!')
      }
    },
  })

  const [updateIndication, { loading: updateIndicationIsLoading }] = useUpdateIndicationMutation()

  useEffect(() => {
    if (selectedIndication) {
      formMethods.setValue('averageEnergyBillValueCents', transformMoney(selectedIndication.averageEnergyBillValueCents, 'toReal'))
      formMethods.setValue('uf', selectedIndication?.condition?.uf || '')
      formMethods.setValue('dealershipId', selectedIndication?.condition?.dealershipId || '')
      formMethods.setValue('document', selectedIndication.customerRef.document)
      formMethods.setValue('email', selectedIndication.customerRef.email)
      formMethods.setValue('name', selectedIndication.customerRef.name)
      formMethods.setValue('phone', selectedIndication.customerRef.phone)
      if(selectedProposal?.customerRef.personType) {
        formMethods.setValue('personType', selectedProposal?.customerRef.personType as EPersonType)
      }
      if (selectedIndication?.condition?.customerDiscountOffered) {
        formMethods.setValue('commissionPercentage', selectedIndication?.condition?.customerDiscountOffered * 100
        )
      }
      // Verifica se há um valor para o estado civil. Se não, define como vazio
      formMethods.setValue('maritalStatus', selectedIndication.customerRef.maritalStatus || '' as EMaritalStatus)

      // Verifica se há um valor para a nacionalidade. Se não, define como vazio
      formMethods.setValue('nationality', selectedIndication.customerRef.nationality || '')

      const fieldsToSetError: BasicDataPendency[] = ['name', 'document', 'email', 'phone']

      fieldsToSetError.map(item => {
        if (selectedProposal?.customerRef?.pendencies?.[item]?.rejectedAt) {
          formMethods.setError(item, { message: 'Este campo foi reprovado por um analista!' })
        }
      })

    }
  }, [selectedProposal, selectedIndication])

  const dealershipOptions: Option[] = useMemo(() => dealerships?.filter(dealership => dealership.uf === watchFields.uf)
    .map(dealership => {
      return { value: dealership.dealershipId, label: dealership.dealershipName || '' }
    }), [dealerships, watchFields]) || []

  const onSubmit = useCallback(async (data: FormData) => {

    await updateIndication({
      variables: {
        params: {
          id: selectedIndication?._id || '',
          email: data.email,
          name: data.name,
          phone: clearString(data.phone),
          ...isPf && {
            maritalStatus: data.maritalStatus,
            nationality: data.nationality,
          }
        }
      }
    })

    await getProposalAndIndicationById({ variables: { id: selectedIndication?._id || '' } })

  }, [dealershipOptions, selectedIndication])

  const filtersStates = useMemo(() => {
    return brazilStates.filter(state => state.value === dealerships.find(dealership => dealership.uf === state.value)?.uf)
  }, [myPartner, dealerships])

  const discountMax = isPf ? selectedDealership?.customerPFDiscountMax : selectedDealership?.customerPJDiscountMax
  const discountMin = isPf ? selectedDealership?.customerPFDiscountMin : selectedDealership?.customerPJDiscountMin

  const hasCommissionPercentage = Boolean(watchFields.commissionPercentage)

  const roofPercentage = (discountMax || 0) + (myPartner?.partnershipConditions?.baseComissionPercentage || 0)

  const baseComission = selectedIndication?.condition?.baseCommissionPercentage ? selectedIndication?.condition?.baseCommissionPercentage * 100 : 0
  const aditionalComission = selectedIndication?.condition?.additionalComissionPercentage ? selectedIndication?.condition?.additionalComissionPercentage * 100 : 0

  const finalComission = selectedIndication?.condition?.commissionAmountPercentage ? selectedIndication?.condition?.commissionAmountPercentage * 100 : 0

  const enableSubmit =
    watchFields.name !== selectedIndication?.customerRef.name ||
    watchFields.email !== selectedIndication?.customerRef.email ||
    clearString(watchFields.document || '') !== clearString(selectedIndication?.customerRef.document || '') ||
    clearString(watchFields.phone || '') !== clearString(selectedIndication?.customerRef.phone || '')

  if (dealershipsIsLoading) return <PageLoader />

  return (
    <>
      <IndicationsCreateBanner />
      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit((data) => onSubmit(data))}>
          <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', gap: 3 }}>
            <Typography variant='h3' fontWeight={500} color={theme.palette.grey[800]}>Tipo de pessoa:</Typography>

            <Radio disabled={true} row name='personType' options={personTypeOptions} />

            <Typography variant='h3' fontWeight={500} color={theme.palette.grey[800]}>Dados</Typography>
            <Grid spacing={3} container>

              <Grid item md={8} xs={12}>
                <Input disabled={hasPendencyOrNullOnBasicData('name', selectedProposal?.customerRef.pendencies)} name='name' label='Nome completo' />
              </Grid>

              <Grid item md={4} xs={12}>
                <Input disabled={true} name='document' label={isPf ? 'CPF' : 'CNPJ'} mask={isPf ? 'cpf' : 'cnpj'} />
              </Grid>

              <Grid item md={4} xs={12}>
                <Input disabled={hasPendencyOrNullOnBasicData('email', selectedProposal?.customerRef.pendencies)} name='email' label='E-mail' />
              </Grid>
              <Grid item md={4} xs={12}>
                <Input disabled={hasPendencyOrNullOnBasicData('phone', selectedProposal?.customerRef.pendencies)} name='phone' label='Telefone' mask='phone' />
              </Grid>
              <Grid item md={4} xs={12}>
                <Select disabled={true} variant='outlined' name='uf' label='UF' options={filtersStates} />
              </Grid>
              <Grid item md={4} xs={12}>
                <Select
                  name='dealershipId'
                  variant='outlined'
                  options={dealershipOptions}
                  disabled={true}
                  label='Concessionária de energia'
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Input disabled={true} name='averageEnergyBillValueCents' label='Valor médio de consumo' mask='BRL' />
              </Grid>
              <Grid item md={4} xs={12}>
              </Grid>       
            </Grid>

            {isPf && (
              <>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant='h3' fontWeight={500} color={theme.palette.grey[800]}>Dados complementares</Typography>
                  </Grid>
                  <Grid xs={12} md={4} item>
                    <Input 
                      disabled={hasPendencyOrNullOnBasicData('nationality', selectedProposal?.customerRef.pendencies)} 
                      label='Nacionalidade' 
                      name='nationality' 
                    />
                  </Grid>
                  <Grid xs={12} md={4} item>
                    <Select 
                      disabled={hasPendencyOrNullOnBasicData('maritalStatus', selectedProposal?.customerRef.pendencies)} 
                      label='Estado civil' 
                      name='maritalStatus' options={maritalStatusOptions} 
                    />
                  </Grid>
                </Grid>
              </>
            )}

            <Grid container>

              <Grid item xs={12}>
                <Typography sx={{ padding: '0 0 1rem 0' }} variant='h3' fontWeight={500} color={theme.palette.grey[800]}>Percentual de economia</Typography>
              </Grid>

              <Grid item xs={12}>
                {Boolean(selectedDealership) && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '.4rem', padding: '0 0 1rem 0' }}>
                    <IconInfoCircle color={theme.palette.info.dark} />
                    <Box>
                      <Typography sx={{ fontWeight: 500 }} variant='body1' color={theme.palette.grey[900]}>
                        O valor deve estar entre {discountMin}% e {roofPercentage}%* para a concessionária informada
                      </Typography>
                      <Typography variant='body1' color={theme.palette.grey[600]}>
                        *em que {discountMax}% são do valor máximo de desconto para o cliente e {myPartner?.partnershipConditions?.baseComissionPercentage}% são substraídos da sua comissão base.
                      </Typography>
                    </Box>
                  </Box>
                )}

              </Grid>
              <Grid item md={4} xs={12}>
                <Input disabled={true} name='commissionPercentage' label='Percentual de economia' mask='percentage' />
              </Grid>
            </Grid>

            <Box sx={{ background: '#fafafa', border: `1px solid ${theme.palette.grey[300]}`, padding: '1.4rem', borderRadius: '.8rem' }}>
              <Typography sx={{ paddingBottom: '1rem' }} variant='h3' fontWeight={500} color={theme.palette.grey[800]}>Sua comissão</Typography>
              <Box sx={{ display: 'flex', gap: isLowerSm ? '1rem' : '2rem', alignItems: isLowerSm ? 'flex-start' : 'center', flexFlow: isLowerSm ? 'column' : 'row' }}>
                <Box sx={{ display: 'flex', flexFlow: 'column', gap: '.2rem' }}>
                  <Typography variant='body2' color={theme.palette.grey[600]}>Comissão base</Typography>
                  <Typography variant='h3' fontWeight={500} color={theme.palette.grey[800]}>{Number(baseComission.toFixed(2))}%</Typography>
                </Box>
                <IconCirclePlus color={theme.palette.secondary.main} size={18} />
                <Box sx={{ display: 'flex', flexFlow: 'column', gap: '.2rem' }}>
                  <Typography variant='body2' color={theme.palette.grey[600]}>Comissão adicional</Typography>
                  <Typography variant='h3' fontWeight={500} color={hasCommissionPercentage ? theme.palette.grey[800] : theme.palette.grey[300]}>{aditionalComission}%</Typography>
                </Box>
                <Box sx={{ position: 'relative' }}>
                  <IconCircle color={theme.palette.secondary.main} size={18} />
                  <IconEqual color={theme.palette.secondary.main} size={14} style={{ position: 'absolute', top: '2px', left: '2px' }} />
                </Box>
                <Box sx={{ display: 'flex', flexFlow: 'column', gap: '.2rem' }}>
                  <Typography variant='body2' color={theme.palette.grey[600]}>Comissão final</Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '.6rem' }}>
                    <IconCheck color={hasCommissionPercentage ? theme.palette.success.main : theme.palette.grey[300]} />
                    <Typography variant='h3' fontWeight={500} color={hasCommissionPercentage ? theme.palette.grey[800] : theme.palette.grey[300]}>{Number(finalComission.toFixed(2))}%</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Divider />

            <Box sx={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
              <Link to='/app/indications'>
                <Button startIcon={<IconArrowLeft />} color='secondary'>
                  Voltar
                </Button>
              </Link>

              <Button
                disabled={!enableSubmit || updateIndicationIsLoading || getProposalAndIndicationByIdIsLoading}
                type='submit'
                startIcon={updateIndicationIsLoading || getProposalAndIndicationByIdIsLoading ? <CircularProgress size={24} color='inherit' /> : <IconCheck size={24} />
                }>
                Confirmar
              </Button>
            </Box>
          </Box>
        </Form>
      </FormProvider>
    </>
  )
}
