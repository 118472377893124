import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react'
import { DealershipListData, DealershipsPartnerContextData } from './types'
import { usePaginateDealershipConditionsQuery } from '~/graphql/types'
import { toast } from 'react-toastify'

const DealershipsPartner = createContext({} as DealershipsPartnerContextData)

export const DealershipsPartnerProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [dealerships, setDealerships] = useState<DealershipListData>([])

  const { data, loading: dealershipsIsLoading } = usePaginateDealershipConditionsQuery({
    variables: {
      params: {
        pageSize: 99999,
        pageNumber: 0
      }
    },
    fetchPolicy: 'no-cache',
    onError(error) {
      toast.error(error.message)
    },
  })

  useEffect(() => {
    if(data) {
      setDealerships(data.paginateDealershipConditions.data as DealershipListData)
    }
  }, [data])
 
  return (
    <DealershipsPartner.Provider value={{
      dealerships,
      setDealerships,
      dealershipsIsLoading
    }}>
      {children}
    </DealershipsPartner.Provider>
  )
}

export const useDealershipsPartner = () => useContext(DealershipsPartner)
