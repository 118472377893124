import { useTheme } from '@emotion/react'
import { Box, Button, InputAdornment, TextField, useMediaQuery, IconButton, Drawer, Typography, Divider } from '@mui/material'
import { IconArrowRight, IconCopy, IconFilter, IconLink, IconPlus, IconSearch, IconX } from '@tabler/icons-react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { Column, useFilters, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import { Card, CheckboxMultiple, ContentTitle, TableApi, TableHeaderApi } from '~/components'
import { UserStatus } from '~/components/UserStatus'
import { usePartner, useUsers } from '~/contexts'
import { useDebounce, useToggle } from '~/hooks'
import { displayPhone } from '~/utils'
import { userStatusOptions } from '~/utils/options'
import { EAffiliatePartnerUserStatus } from '~/graphql/types'
import { copyToClipboard } from '~/utils/clipboard'

type FilterTableData = {
  nameOrUsernameOrEmail: string | null,
  status: EAffiliatePartnerUserStatus[],
}

export const UsersList: React.FC = () => {
  const { myPartner, indicationLink } = usePartner()
  const { users, paginationData, usersIsLoading } = useUsers()
  const theme = useTheme()
  const { isTrue: filterDrawerIsOpen, toggle } = useToggle(false)
  const isLowerlg = useMediaQuery(theme.breakpoints.down('xl'))
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))

  const [filterTable, setFilterTable] = useState<FilterTableData>({
    nameOrUsernameOrEmail: '',
    status: [],
  })

  const debouncedFilterTable = useDebounce(filterTable, 1000)

  const formMethods = useForm()

  useEffect(() => {
    paginationData.setFiltersTable(filterTable)
    paginationData.setMetadata((prev) => ({ ...prev, currentPage: 0 }))
  }, [debouncedFilterTable])

  const columns = useMemo((): Column[] => {
    return [
      {
        Header: 'Nome',
        accessor: 'name',
      },
      {
        Header: 'E-mail',
        accessor: 'email',
        Cell: ({ value }) => {
          return <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{value}</div>
        }
      },
      {
        Header: 'Telefone',
        accessor: 'phone',
        Cell: ({ value }) => value && <>{displayPhone(value)}</>
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => {
          return (
            <Box sx={{ padding: '.8rem 0' }}>
              <UserStatus status={value} />
            </Box>
          )
        }
      },
      {
        Header: 'Acessar',
        accessor: '_id',
        Cell: ({ value }) => {
          return (
            <Link to={`/app/users/update/${value}`}>
              <IconButton><IconArrowRight /></IconButton>
            </Link>
          )
        }
      },
    ]
  }, [])

  const dataWithMemo = useMemo(() => users || [], [users])

  const defaultColumnHiddens = isLowerlg ? [].concat(isLowerMd ? [] : []) : []

  const tableMethods = useTable({
    columns,
    data: dataWithMemo,
    manualPagination: true,
    initialState: {
      hiddenColumns: defaultColumnHiddens
    },
    defaultColumn: {
      Filter: <></>
    },
  }, 
  useFilters,
  useSortBy,
  usePagination,
  useRowSelect,
  //disable checkboxColumn
  // (hooks) => renderTableCheckbox(hooks)
  )

  const onApplyFilters = useCallback((formData: any) => {
    setFilterTable({
      ...filterTable,
      ...formData.status?.length > 0 ? { status: formData?.status } : { status: [] },
    })
    toggle()
  }, [toggle, filterTable])

  const onRemoveFilters = useCallback(() => {
    setFilterTable({ nameOrUsernameOrEmail: '', status: [] })
    toggle()
  }, [toggle])

  return (
    <Box sx={{ minHeight: '100vh', height: '100%' }}>
      <Card>

        <ContentTitle
          title='Lista de usuários'
          style={{ padding: '0px 0px 1.6rem 0px' }}
          description='Consulte a lista de usuários cadastrados'
          rightContent={
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              {myPartner?.configuration.allowAffiliateLink && (
                <Button sx={{ 
                  color: theme.palette.grey[400],
                  border: `1px solid ${theme.palette.grey[400]}` 
                }}
                color='secondary' 
                startIcon={<IconLink />} 
                endIcon={<IconCopy color={theme.palette.primary.main} />}
                onClick={() => copyToClipboard(indicationLink)}
                >
                Link de indicação
                </Button>
              )}
             
              <Link to='/app/users/create'>
                <Button endIcon={<IconPlus />} >Novo usuário</Button>
              </Link>
            </Box>
          }
          breadcrumbLinks={{ currentLink: 'Lista de usuários', links: [{ href: '/app/users', label: 'usuários' }] }}
        />

        <TableHeaderApi
          startElement={(
            <>
              <TextField
                fullWidth
                sx={{ maxWidth: '400px' }}
                value={filterTable.nameOrUsernameOrEmail}
                onChange={(e) => setFilterTable({ ...filterTable, nameOrUsernameOrEmail: e.target.value })}
                placeholder='Procurar um usuário por nome ou E-mail'
                InputProps={{
                  endAdornment: (
                    <>
                      <InputAdornment position='end'>
                        <IconSearch />
                      </InputAdornment>
                    </>
                  )
                }}
              />
              <Drawer PaperProps={{ sx: { maxWidth: '560px', width: '100%', padding: '2rem' } }} anchor='right' open={filterDrawerIsOpen} onClose={toggle}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography fontWeight={500} variant='h5'>Filtrar por</Typography>
                  <IconButton type='button' onClick={() => toggle()}>
                    <IconX  />
                  </IconButton>
                </Box>

                <FormProvider {...formMethods}>
                  <form onSubmit={formMethods.handleSubmit((formData) => onApplyFilters(formData))}>
                    <Typography sx={{ paddingTop: '1rem' }} fontWeight={500}>Status</Typography>
                    <CheckboxMultiple name='status' options={userStatusOptions} />
                    <Divider sx={{ paddingTop: '1.4rem' }} />
                    <Box sx={{ display: 'flex', padding: '2rem 0', gap: '1rem', justifyContent: 'flex-end' }}>
                      <Button onClick={() => onRemoveFilters()} type='button' fullWidth color='secondary'>Limpar</Button>
                      <Button type='submit' fullWidth color='primary'>Aplicar</Button>
                    </Box>
                  </form>
                </FormProvider>
              </Drawer>
            </>
            
          )}
          endElement={
            (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                <Button onClick={() => toggle()} sx={{ fontWeight: 600 }} startIcon={<IconFilter />} color='secondary'>Filtros</Button>
              </Box>
            )
          }
        />

        <TableApi
          tableLayout={isLowerMd ? 'auto' : 'fixed'}
          isLoading={usersIsLoading}
          paginationData={paginationData}
          tableMethods={tableMethods}
          columns={columns}
        />

      </Card>
    </Box>
  )
}
