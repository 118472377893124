import { Box, Typography, useMediaQuery } from '@mui/material'
import { IconApi, IconExternalLink } from '@tabler/icons-react'
import { theme } from '~/utils'
import { Banner } from '~/components'
import { SoftwareIntegration } from '~/assets/illustrations'

export const DeveloperSettingsBanner: React.FC = () => {
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Banner
      padding='24px'
      minHeight={0}
      backgroundColor='#F5F1F8'
      title={(
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <IconApi color='#9E78BC' size={32} />
          <Typography variant={isLowerSm ? 'h4' : 'h3'} fontWeight={500}>Documentação</Typography>
        </Box>
      )}
      description={(
        <Typography variant={isLowerSm ? 'body2' : 'h5'} sx={{ lineHeight: isLowerSm ? '20px' : '30px' }}>
          A versão da API Desperta Energia é estruturada em REST, HTTP e JSON. <br />
          URLs de endpoint da API são organizados em torno de recurso como contatos ou negócios.
        </Typography>
      )}
      iconTextList={[
        {
          invert: true,
          icon: <IconExternalLink size={24} color={theme.palette.purple.main} />,
          text: <Typography fontWeight={500} variant={isLowerSm ? 'body2' : 'body1'} color={theme.palette.purple.main}>Documentação API</Typography>,
          link: 'https://documenter.getpostman.com/view/17271564/2sA2xb7bFH'
        },
      ]}
      rightElement={isLowerSm ? null : <SoftwareIntegration style={{ width: 230, height: 154 }} />}
    />
  )
}
