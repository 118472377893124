import { FormControl, FormHelperText, InputLabel, MenuItem, Select as MuiSelect, SelectProps as MuiSelectProps } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'

type SelectProps = MuiSelectProps & {
  name: string,
  label?: string,
  options: {
    value: string | number,
    label?: string
  }[]
  shouldUnregister?: boolean,
  defaultValue?: string
}

export const Select: React.FC<SelectProps> = ({ name, label, shouldUnregister = false, options, disabled, defaultValue, ...rest }) => {
  const { control } = useFormContext()

  return (
    <FormControl fullWidth>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || (options[0]?.value ?? '')}
        shouldUnregister={shouldUnregister}
        render={({ field, fieldState: { error } }) => (
          <>
            <InputLabel id={field.name}>{label}</InputLabel>
            <MuiSelect error={Boolean(error?.message)} label={label} {...rest} {...field} disabled={disabled}>
              <MenuItem value=''>Selecione</MenuItem>
              {options.map((option, index) => {
                return (
                  <MenuItem key={`select-option-${option.value}-${index}`} value={option.value}>{option.label}</MenuItem>
                )
              })}
            </MuiSelect>
            {error && <FormHelperText sx={{ color: 'error.main' }}>{error.message}</FormHelperText>}
          </>
        )}
      />
    </FormControl>
  )
}
