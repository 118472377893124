import { useCallback } from 'react'
import { usePaginationValues } from './usePaginationValues'
import { useSearchParams } from 'react-router-dom'

export type MetadataPropsV2 = {
  nextPage: Function
  prevPage: Function
  firstPage: Function
  lastPage: Function
  canNextPage: boolean
  canPrevPage: boolean
  page: number
  size: number
  items: number
}

export const useApiPaginationV2 = () => {
  const { items, page, size } = usePaginationValues()
  const [searchParams, setSearchParams] = useSearchParams()

  const lastPageNumber = Math.ceil(items / size)

  const nextPage = useCallback(() => {
    setSearchParams(state => {
      state.set('page', String(page + 1))
      state.set('size', String(size))
      return state
    })
  }, [searchParams, page])

  const prevPage = useCallback(() => {
    setSearchParams(state => {
      state.set('page', String(page - 1))
      return state
    })
  }, [searchParams, page])

  const firstPage = useCallback(() => {
    setSearchParams(state => {
      state.set('page', '0')
      return state
    })
  }, [searchParams])

  const lastPage = useCallback(() => {
    setSearchParams(state => {
      state.set('page', String(lastPageNumber - 1))
      return state
    })
  }, [searchParams, lastPageNumber])

  const canNextPage = page >= lastPageNumber - 1
  const canPrevPage = page < 1

  return { nextPage, prevPage, firstPage, lastPage, canNextPage, canPrevPage, items, page, size }
}
