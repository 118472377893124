import { Typography, useMediaQuery } from '@mui/material'
import { IconCheck } from '@tabler/icons-react'
import { theme } from '~/utils'
import { Banner } from '~/components'
import { MenHighFive } from '~/assets/illustrations'

export const IndicationsCreateBanner: React.FC = () => {
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Banner
      padding='24px'
      minHeight={0}
      backgroundColor='#F5F1F8'
      title={<Typography variant='h3' fontWeight={500}>Solicite proposta para seus clientes</Typography>}
      description={<Typography variant='h5'>Ao solicitar uma proposta, você estará fazendo uma indicação</Typography>}
      category={{
        icon: null,
        color: theme.palette.purple.main,
        text: 'Dificuldades com o link de indicação?'
      }}
      iconTextList={[
        {
          icon: <IconCheck size={30} color='#9E78BC' />,
          text: <span>Informe os dados abaixo para indicar seu cliente</span>
        },
      ]}
      rightElement={isLowerSm ? null : <MenHighFive style={{ width: 140, height: 160 }} />}
    />
  )
}
