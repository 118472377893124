import { PropsWithChildren, createContext, useCallback, useContext, useState } from 'react'
import { IndicationByIndicationFlow, IndicationFlowContextData, ProposalByIndicationFlow } from './types'
import { useParams } from 'react-router-dom'
import { useGetProposalAndIndicationByIdQuery } from '~/graphql/types'
import { toast } from 'react-toastify'

const IndicationFlowContext = createContext({} as IndicationFlowContextData)

export const IndicationFlowProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { id } = useParams()

  const [stepper, setStepper] = useState(0)
  const [selectedIndication, setSelectedIndication] = useState<null | IndicationByIndicationFlow>(null)
  const [selectedProposal, setSelectedProposal] = useState<null | ProposalByIndicationFlow>(null)
  const isPf = selectedProposal?.customerRef.personType === 'pf'

  const { loading: getIndcationAndProposalIsLoading } = useGetProposalAndIndicationByIdQuery({
    variables: {
      id: id || ''
    },
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      if(data.getIndicationById._id && data.getIndicationProposalById?._id) {
        setSelectedIndication(data.getIndicationById)
        setSelectedProposal(data.getIndicationProposalById)
      }   
    },
    onError(error) {
      toast.error(error.message)
    },
  })

  const goToPage = useCallback((page: number) => {
    setStepper(page)
  }, [])
  
  return (
    <IndicationFlowContext.Provider value={{
      setStepper,
      stepper,
      goToPage,
      getIndcationAndProposalIsLoading,
      selectedIndication,
      selectedProposal,
      setSelectedIndication,
      setSelectedProposal,
      isPf
    }}>
      {children}
    </IndicationFlowContext.Provider>
  )
}

export const useIndicationFlow = () => useContext(IndicationFlowContext)
