import { SvgIcon, SvgIconProps } from '@mui/material'

export const MenHighFive: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width={161} height={149} viewBox='0 0 161 149' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M34.0341 94.1164C53.1347 103.304 78.9196 98.6107 100.083 91.4282C183.38 70.5558 145.962 1.60957 91.5512 0.0272614C37.1403 -1.55504 -23.8018 66.161 34.0341 94.1164Z' fill='url(#paint0_linear_10832_19586)' fillOpacity='0.5' />
      <path d='M80.7008 149C125.049 149 161 146.901 161 144.311C161 141.721 125.049 139.622 80.7008 139.622C36.3526 139.622 0.401367 141.721 0.401367 144.311C0.401367 146.901 36.3526 149 80.7008 149Z' fill='#DFDFDF' />
      <path d='M55.624 140.807H58.8627L59.5253 133.305H56.2867L55.624 140.807Z' fill='#FFB573' />
      <path d='M59.3888 140.43H55.4378C55.3724 140.431 55.3091 140.454 55.2576 140.494C55.2061 140.534 55.169 140.59 55.152 140.653L54.4107 143.532C54.3919 143.601 54.3895 143.673 54.4035 143.743C54.4176 143.812 54.4477 143.878 54.4916 143.934C54.5355 143.99 54.5918 144.034 54.6562 144.065C54.7205 144.095 54.791 144.11 54.8621 144.108C56.1336 144.087 57.0654 144.013 58.664 144.013C59.6497 144.013 62.6108 144.112 63.9734 144.112C65.3359 144.112 65.5554 142.77 65.0046 142.646C62.5197 142.103 60.6768 141.349 59.9023 140.629C59.7623 140.5 59.5789 140.429 59.3888 140.43Z' fill='#263238' />
      <path opacity='0.2' d='M56.2861 133.31H59.5248L59.181 137.174H55.9424L56.2861 133.31Z' fill='black' />
      <path d='M62.3459 66.2188C62.3459 66.2188 64.4166 91.3575 63.8368 100.847C63.1824 111.236 60.6975 136.424 60.6975 136.424H54.7669C54.7669 136.424 55.2846 112.967 54.9823 102.873C54.6427 91.3534 51.168 66.3761 51.168 66.3761L62.3459 66.2188Z' fill='#263238' />
      <path d='M61.2103 136.553H54.2236L54.2982 134.465L61.7073 134.329L61.2103 136.553Z' fill='#561885' />
      <path d='M60.4405 140.997C60.6629 141.013 60.8844 140.955 61.07 140.831C61.1313 140.783 61.179 140.719 61.2087 140.646C61.2385 140.574 61.2492 140.495 61.2398 140.417C61.2431 140.373 61.2335 140.33 61.2122 140.291C61.1908 140.253 61.1587 140.222 61.1197 140.202C60.7636 140.015 59.6992 140.657 59.5791 140.732C59.5658 140.74 59.5552 140.752 59.5486 140.766C59.542 140.78 59.5396 140.795 59.5418 140.811C59.5437 140.825 59.5498 140.839 59.5594 140.85C59.569 140.861 59.5816 140.869 59.5957 140.873C59.8717 140.946 60.1551 140.988 60.4405 140.997ZM60.9458 140.326C60.98 140.326 61.0138 140.333 61.0452 140.347C61.0452 140.347 61.0866 140.376 61.0866 140.438C61.0914 140.49 61.0837 140.542 61.0643 140.59C61.0448 140.638 61.0141 140.681 60.9748 140.715C60.6125 140.881 60.2006 140.902 59.8234 140.773C60.166 140.555 60.5468 140.403 60.9458 140.326Z' fill='#561885' />
      <path d='M59.6116 140.898C59.6237 140.902 59.6367 140.902 59.6489 140.898C59.9926 140.716 60.6635 140.007 60.6097 139.655C60.6008 139.599 60.5722 139.547 60.529 139.51C60.4857 139.473 60.4307 139.452 60.3736 139.452C60.3146 139.446 60.2549 139.452 60.198 139.469C60.1411 139.486 60.0882 139.514 60.0423 139.552C59.6571 139.858 59.5453 140.794 59.5412 140.832C59.5388 140.845 59.5402 140.859 59.5453 140.871C59.5505 140.883 59.5591 140.894 59.5702 140.902C59.5841 140.904 59.5984 140.903 59.6116 140.898ZM60.3156 139.585H60.3488C60.4482 139.585 60.4565 139.643 60.4565 139.663C60.4896 139.875 60.0423 140.413 59.7151 140.666C59.7515 140.294 59.8955 139.942 60.1293 139.651C60.1793 139.602 60.2455 139.572 60.3156 139.568V139.585Z' fill='#561885' />
      <path d='M31.2969 135.12L34.3243 136.159L37.7452 129.317L34.7177 128.277L31.2969 135.12Z' fill='#FFB573' />
      <path d='M34.8334 136.288L31.6818 134.022C31.629 133.985 31.5654 133.968 31.5014 133.972C31.4374 133.977 31.3768 134.003 31.3297 134.047L29.0809 136.035C29.0265 136.082 28.9834 136.14 28.9547 136.206C28.926 136.272 28.9126 136.343 28.9154 136.415C28.9182 136.487 28.9371 136.557 28.9708 136.62C29.0045 136.683 29.052 136.738 29.1099 136.781C30.137 137.489 30.9239 137.961 32.1995 138.881C32.9863 139.444 35.2931 141.233 36.3782 142.012C37.4633 142.791 38.42 141.797 38.0348 141.374C36.3782 139.498 35.3263 137.8 35.1358 136.756C35.0997 136.566 34.9912 136.398 34.8334 136.288Z' fill='#263238' />
      <path d='M57.8887 38.9553L58.6756 38.7813L59.5039 38.5867C60.0547 38.45 60.6055 38.3008 61.1605 38.1434C62.2538 37.8245 63.3306 37.4724 64.3701 37.0706C65.3907 36.6835 66.3841 36.2282 67.3437 35.7079C68.2319 35.231 69.0582 34.6468 69.8037 33.9682L70.0522 33.7279C70.1185 33.6492 70.1848 33.5829 70.2552 33.5001C70.396 33.3261 70.5451 33.1356 70.69 32.9202C70.9882 32.506 71.2864 32.0338 71.568 31.545C72.1271 30.5633 72.6821 29.5278 73.2246 28.4674C74.2766 26.3549 75.2954 24.1595 76.3059 21.9932L78.621 22.7388C78.105 25.1607 77.4397 27.5483 76.6289 29.8881C76.2148 31.0645 75.8006 32.245 75.2457 33.4255C74.9765 34.0137 74.6866 34.606 74.3387 35.2149C74.1647 35.5173 73.9701 35.8238 73.7506 36.1386C73.6429 36.296 73.5062 36.4617 73.3861 36.6191L73.0175 37.0333C72.5288 37.5684 72.0014 38.0668 71.4396 38.5245C70.8951 38.9544 70.3283 39.3554 69.7416 39.7258C68.6101 40.4324 67.4231 41.046 66.1924 41.5607C65.0006 42.0685 63.7794 42.5042 62.5354 42.8655C61.9197 43.0478 61.2985 43.2134 60.6718 43.3626C60.3611 43.4413 60.0464 43.5158 59.7275 43.5821C59.4086 43.6484 59.1104 43.7022 58.7335 43.7685L57.8887 38.9553Z' fill='#FFB573' />
      <path d='M56.2656 44.5427C58.7505 46.2948 67.7914 43.4243 67.7914 43.4243L66.5489 35.4341C66.5489 35.4341 62.8506 36.3081 58.97 37.4472C56.3319 38.2093 53.5364 42.6373 56.2656 44.5427Z' fill='#674085' />
      <path d='M75.9043 23.2894L76.5172 19.8721L79.6316 21.8644C79.5093 22.3453 79.2901 22.7961 78.9874 23.1893C78.6848 23.5825 78.305 23.9097 77.8715 24.1509L75.9043 23.2894Z' fill='#FFB573' />
      <path d='M77.3956 17.9294L79.8059 17.0679L79.6361 21.8645L76.5176 19.8721L77.3956 17.9294Z' fill='#FFB573' />
      <path opacity='0.2' d='M34.7176 128.277L37.7451 129.321L35.9808 132.846L32.9492 131.806L34.7176 128.277Z' fill='black' />
      <path d='M44.6699 38.2884C45.2937 41.8465 45.6482 45.4466 45.7301 49.058C45.8005 51.5848 45.7633 53.9831 45.6722 56.1618C45.4692 61.2567 44.9971 65.1255 45.0261 66.451L62.35 66.2356C66.4086 47.9769 62.8179 38.2429 62.8179 38.2429C61.4766 37.9077 60.1155 37.6572 58.7427 37.4931C58.4528 37.46 58.1588 37.431 57.8564 37.4062C55.4008 37.2952 52.9408 37.3256 50.4887 37.4973C50.0249 37.5263 49.5527 37.5677 49.093 37.6133C46.7407 37.8411 44.6699 38.2884 44.6699 38.2884Z' fill='#674085' />
      <path d='M50.4679 37.4807C52.9201 37.309 55.38 37.2786 57.8357 37.3895C58.1338 37.4144 58.432 37.4434 58.7219 37.4765H58.8255C59.2396 38.5866 59.2935 40.9808 56.8624 41.1755C53.5492 41.4406 50.4679 39.2742 49.0723 37.5884C49.5444 37.5511 50.0207 37.5097 50.4679 37.4807Z' fill='white' />
      <path opacity='0.2' d='M48.579 43.1926C48.3546 45.8626 47.3733 48.4132 45.7504 50.545C45.7504 50.052 45.7504 49.5508 45.7255 49.0455C45.6717 47.1484 45.5557 45.1809 45.3486 43.1885L48.579 43.1926Z' fill='black' />
      <path d='M51.4121 29.1797C51.8262 31.5449 52.1824 35.8817 50.4844 37.464C51.2046 38.2193 52.0822 38.8067 53.0549 39.1847C54.0276 39.5627 55.0715 39.7219 56.1127 39.6511C58.1544 39.5848 58.2869 38.1558 57.8521 37.3729C55.2222 36.7764 55.3382 34.813 55.8435 32.9656L51.4121 29.1797Z' fill='#FFB573' />
      <path opacity='0.2' d='M53.2172 30.7163L55.8388 32.9655C55.7158 33.3936 55.6299 33.8316 55.582 34.2744C54.5756 34.1419 53.213 33.0525 53.1302 31.9962C53.0815 31.5678 53.111 31.1342 53.2172 30.7163Z' fill='black' />
      <path d='M60.3823 22.548C62.362 21.5581 61.455 18.5219 61.455 18.5219C61.455 18.5219 60.4072 18.497 59.2641 19.7645C59.7818 18.1988 58.9121 15.6472 58.6222 14.6572C54.6505 16.3804 54.3855 18.0455 54.3855 18.0455C54.3855 18.0455 53.5572 16.8029 52.7786 16.7324C52.1629 17.1111 51.6859 17.6786 51.4187 18.3504C51.1516 19.0221 51.1085 19.7622 51.2959 20.4604C50.6411 20.0799 49.9096 19.8507 49.1548 19.7894C48.8541 20.7316 48.9026 21.7505 49.2914 22.6599C48.8346 22.8965 48.4648 23.2722 48.2354 23.7327C48.5976 23.9628 48.9836 24.1531 49.3867 24.3002C49.3246 25.5428 49.5896 27.2577 50.4842 28.2269L59.5955 23.9688C61.1361 24.6357 61.8236 24.1552 61.8236 24.1552C61.8236 24.1552 61.4674 22.8463 60.3823 22.548Z' fill='#263238' />
      <path d='M51.2135 25.6175C51.3336 28.8235 51.2425 30.7123 52.8245 32.3402C55.2059 34.7841 59.1983 33.268 60.039 30.0537C60.7969 27.1542 60.4532 22.4073 57.3595 21.1688C54.2658 19.9303 51.0892 22.4321 51.2135 25.6175Z' fill='#FFB573' />
      <path d='M54.1699 22.0179C54.377 23.6996 52.0991 24.9422 52.6292 27.4275C52.2348 28.0124 51.7561 28.5358 51.2087 28.9808L50.2852 26.45L50.7449 21.8108L53.8261 21.5498L54.1699 22.0179Z' fill='#263238' />
      <path d='M52.0332 21.8106C54.4477 23.3763 58.4981 23.0988 59.6246 22.7095C59.0613 20.6839 56.7959 20.1082 56.7959 20.1082C56.7959 20.1082 52.9982 19.7644 52.0332 21.8106Z' fill='#263238' />
      <path d='M57.0111 30.0411C56.6376 30.0567 56.2659 29.9798 55.9292 29.8173C55.5925 29.6547 55.3012 29.4114 55.0812 29.1091C55.0712 29.0895 55.0687 29.067 55.074 29.0457C55.0793 29.0243 55.0922 29.0057 55.1102 28.9931C55.1191 28.9871 55.1291 28.9829 55.1396 28.9809C55.1501 28.9789 55.161 28.979 55.1715 28.9814C55.1819 28.9837 55.1918 28.9881 55.2005 28.9944C55.2092 29.0007 55.2165 29.0087 55.222 29.0179C55.4303 29.2942 55.7019 29.5162 56.014 29.6655C56.3261 29.8147 56.6695 29.8867 57.0153 29.8754C57.026 29.8754 57.0365 29.8775 57.0464 29.8817C57.0562 29.8859 57.0651 29.8921 57.0724 29.8999C57.0798 29.9076 57.0855 29.9168 57.0892 29.9268C57.0929 29.9369 57.0945 29.9475 57.094 29.9582C57.094 29.9802 57.0852 30.0013 57.0697 30.0168C57.0542 30.0323 57.0331 30.0411 57.0111 30.0411Z' fill='#263238' />
      <path d='M49.6803 29.1712C49.8858 29.5078 50.1578 29.799 50.4797 30.0269C50.8015 30.2549 51.1665 30.4147 51.5523 30.4967C52.5918 30.7162 53.0805 29.7594 52.7616 28.8067C52.4717 27.9492 51.5854 26.806 50.5542 27.0172C50.33 27.0651 50.1209 27.1672 49.9452 27.3145C49.7695 27.4619 49.6326 27.65 49.5464 27.8625C49.4602 28.075 49.4273 28.3054 49.4507 28.5335C49.474 28.7616 49.5529 28.9806 49.6803 29.1712Z' fill='#FFB573' />
      <path opacity='0.2' d='M53.9545 74.0684L52.5381 76.856C53.1883 82.1249 53.9338 88.5866 54.4308 94.2987C55.5614 88.5535 56.2944 77.5229 53.9545 74.0684Z' fill='black' />
      <path d='M56.8326 66.2891C56.8326 66.2891 52.807 89.2615 51.0096 98.6393C49.0383 108.908 37.6285 132.613 37.6285 132.613L32.083 130.708C32.083 130.708 41.2481 107.098 42.2421 96.9659C43.3727 85.4466 45.0045 66.4299 45.0045 66.4299L56.8326 66.2891Z' fill='#263238' />
      <path d='M38.0841 132.908L31.5488 130.663L32.2902 128.683L39.2644 130.936L38.0841 132.908Z' fill='#561885' />
      <path d='M56.0586 26.454C56.0586 26.7149 56.187 26.9262 56.3568 26.922C56.5266 26.9179 56.6715 26.7066 56.6757 26.4457C56.6798 26.1847 56.5514 25.9735 56.3775 25.9776C56.2035 25.9817 56.0627 26.193 56.0586 26.454Z' fill='#263238' />
      <path d='M59.0117 26.4168C59.0117 26.6777 59.1401 26.889 59.3099 26.8848C59.4797 26.8807 59.6247 26.6695 59.6329 26.4085C59.6412 26.1475 59.5046 25.9404 59.3306 25.9404C59.1567 25.9404 59.02 26.16 59.0117 26.4168Z' fill='#263238' />
      <path d='M58.0716 26.5742C58.3636 27.3663 58.7651 28.1136 59.2643 28.7944C58.7922 29.2335 57.9805 28.9808 57.9805 28.9808L58.0716 26.5742Z' fill='#ED893E' />
      <path d='M55.1637 25.5097C55.1915 25.5147 55.2201 25.5119 55.2464 25.5017C55.2728 25.4915 55.2957 25.4742 55.3128 25.4517C55.4277 25.2933 55.5775 25.1635 55.7506 25.0723C55.9236 24.9811 56.1154 24.9309 56.3109 24.9257C56.352 24.928 56.3924 24.9141 56.4233 24.887C56.4543 24.8599 56.4734 24.8217 56.4766 24.7807C56.4789 24.761 56.4771 24.741 56.4714 24.722C56.4656 24.703 56.4561 24.6854 56.4433 24.6703C56.4305 24.6551 56.4147 24.6428 56.3969 24.634C56.3791 24.6252 56.3597 24.6201 56.3399 24.6191C56.0927 24.6215 55.8494 24.6816 55.6294 24.7945C55.4094 24.9074 55.2188 25.07 55.0726 25.2695C55.0484 25.3017 55.0374 25.342 55.0421 25.3821C55.0467 25.4222 55.0665 25.459 55.0975 25.4849C55.1177 25.4974 55.1402 25.5058 55.1637 25.5097Z' fill='#263238' />
      <path d='M60.3037 25.3855C60.3333 25.3953 60.3653 25.3953 60.3948 25.3855C60.4316 25.3683 60.4603 25.3376 60.4749 25.2998C60.4896 25.262 60.4891 25.22 60.4735 25.1825C60.3749 24.9657 60.2242 24.7767 60.0349 24.6321C59.8456 24.4876 59.6236 24.3921 59.3885 24.3541C59.3479 24.3493 59.3072 24.3605 59.2747 24.3852C59.2423 24.41 59.2207 24.4463 59.2145 24.4867C59.2116 24.5063 59.2126 24.5263 59.2175 24.5455C59.2224 24.5647 59.2311 24.5827 59.2431 24.5985C59.255 24.6143 59.27 24.6276 59.2871 24.6376C59.3043 24.6475 59.3232 24.6539 59.3429 24.6565C59.5257 24.6955 59.6964 24.778 59.8406 24.897C59.9848 25.0159 60.0982 25.1679 60.1712 25.3399C60.1876 25.3581 60.2084 25.3718 60.2316 25.3798C60.2548 25.3878 60.2796 25.3897 60.3037 25.3855Z' fill='#263238' />
      <path d='M35.3472 137.369C35.515 137.508 35.7255 137.586 35.9436 137.588C36.0205 137.581 36.0947 137.556 36.1596 137.514C36.2245 137.472 36.2783 137.415 36.3163 137.348C36.3436 137.312 36.3604 137.27 36.3648 137.225C36.3692 137.181 36.361 137.136 36.3412 137.095C36.1672 136.739 34.9455 136.681 34.8088 136.656C34.7933 136.656 34.7779 136.66 34.7646 136.668C34.7514 136.676 34.7408 136.688 34.7343 136.702C34.7249 136.714 34.7197 136.728 34.7197 136.743C34.7197 136.758 34.7249 136.773 34.7343 136.785C34.9146 137.003 35.1204 137.199 35.3472 137.369ZM36.1341 137.095C36.1634 137.112 36.1877 137.136 36.2045 137.166C36.2136 137.182 36.2184 137.199 36.2184 137.218C36.2184 137.236 36.2136 137.254 36.2045 137.269C36.1791 137.315 36.1428 137.355 36.0987 137.384C36.0547 137.412 36.0043 137.43 35.9519 137.435C35.7241 137.456 35.3721 137.232 34.9993 136.826C35.3917 136.84 35.7775 136.931 36.1341 137.095Z' fill='#561885' />
      <path d='M34.7544 136.797C34.7651 136.801 34.7769 136.801 34.7875 136.797C35.1644 136.843 36.1128 136.632 36.2743 136.296C36.299 136.245 36.3051 136.187 36.2915 136.132C36.2779 136.076 36.2454 136.028 36.1998 135.994C36.1571 135.954 36.1067 135.925 36.0518 135.906C35.9968 135.887 35.9386 135.881 35.8809 135.886C35.3963 135.923 34.771 136.644 34.742 136.673C34.7351 136.685 34.7314 136.699 34.7314 136.712C34.7314 136.726 34.7351 136.74 34.742 136.752C34.7404 136.768 34.7448 136.784 34.7544 136.797ZM36.0673 136.097H36.0921C36.1667 136.168 36.146 136.209 36.1377 136.226C36.0424 136.425 35.3881 136.64 34.9698 136.64C35.2093 136.352 35.5272 136.139 35.885 136.027C35.9526 136.026 36.0179 136.051 36.0673 136.097Z' fill='#561885' />
      <path d='M47.7256 43.263L46.7068 44.5057C46.3631 44.9199 46.0235 45.3548 45.688 45.7897C45.0171 46.6513 44.3627 47.5239 43.725 48.4076C43.0996 49.294 42.4825 50.1887 41.9276 51.0917C41.3726 51.9947 40.8383 52.9143 40.4035 53.8338L40.3165 54.0078L40.2834 54.0865C40.2523 54.1488 40.2273 54.214 40.2088 54.2812C40.1524 54.4687 40.1082 54.6596 40.0763 54.8528C39.9962 55.3283 39.9464 55.8083 39.9272 56.2901C39.8858 57.3132 39.8982 58.4026 39.9562 59.4962C40.0639 61.6832 40.2627 63.9448 40.4822 66.165L38.1132 66.7159C37.3314 64.506 36.7153 62.2409 36.2703 59.9394C36.0405 58.7501 35.8828 57.5481 35.7981 56.3398C35.7587 55.6832 35.7587 55.0248 35.7981 54.3682C35.8279 53.9955 35.879 53.6248 35.9514 53.2581C36.0011 53.0551 36.0383 52.8646 36.117 52.6326L36.2247 52.3095L36.3117 52.0941C37.2105 49.8335 38.3461 47.6745 39.6994 45.6531C40.3538 44.6589 41.0413 43.698 41.7702 42.7535C42.1305 42.2855 42.4991 41.8257 42.876 41.3659C43.2528 40.9061 43.6256 40.4712 44.0563 39.9907L47.7256 43.263Z' fill='#FFB573' />
      <path d='M44.6695 38.2882C41.5883 38.7273 38.2461 45.9843 38.2461 45.9843L44.0773 51.5017C44.0773 51.5017 46.4959 47.8525 48.488 44.3109C50.654 40.4546 48.3969 37.758 44.6695 38.2882Z' fill='#674085' />
      <path d='M40.2004 65.4648L42.1511 68.0454L38.0966 69.0271C38.0966 69.0271 36.9369 66.4465 38.2622 65.6305L40.2004 65.4648Z' fill='#FFB573' />
      <path d='M42.0849 70.7502L38.7013 71.4047L38.0967 69.0271L42.1512 68.0454L42.0849 70.7502Z' fill='#FFB573' />
      <path d='M134.328 133.301L131.272 134.61L127.035 128.053L130.087 126.74L134.328 133.301Z' fill='#FFC3BD' />
      <path opacity='0.2' d='M130.092 126.74L127.035 128.053L129.222 131.433L132.278 130.124L130.092 126.74Z' fill='black' />
      <path d='M106.274 67.2542C106.274 67.2542 111.658 94.3605 114.354 103.705C117.307 113.974 127.5 131.338 127.5 131.338L133.095 128.935C133.095 128.935 126.882 114.935 124.398 104.828C121.586 93.3208 117.697 66.3057 117.697 66.3057L106.274 67.2542Z' fill='#674085' />
      <path opacity='0.2' d='M110.73 75.0376C111.422 82.5722 111.587 87.8783 111.873 93.7105C110.457 87.5221 108.945 80.3478 107.848 75.0376H110.73Z' fill='black' />
      <path d='M112.018 140.902L108.693 140.852L107.931 133.14L111.252 133.189L112.018 140.902Z' fill='#FFC3BD' />
      <path opacity='0.2' d='M107.931 133.14L108.324 137.116L111.65 137.17L111.256 133.193L107.931 133.14Z' fill='black' />
      <path d='M107.09 136.342L112.718 136.429C112.718 136.429 110.498 114.554 109.769 104.219C109.546 101.075 109.649 96.6307 109.914 91.8465C110.246 85.8155 110.465 83.4007 110.921 75.7791C111.244 70.3446 111.637 66.8237 111.637 66.8237L99.987 67.7806C99.987 67.7806 99.5315 94.1703 100.853 104.232C102.236 114.678 107.09 136.342 107.09 136.342Z' fill='#674085' />
      <path d='M108.722 140.483L112.354 140.541C112.419 140.541 112.483 140.564 112.534 140.605C112.584 140.647 112.619 140.705 112.631 140.769L113.244 143.735C113.26 143.807 113.259 143.881 113.242 143.953C113.225 144.024 113.191 144.091 113.144 144.147C113.096 144.204 113.037 144.249 112.97 144.278C112.902 144.308 112.829 144.322 112.756 144.319C111.447 144.278 109.554 144.17 107.91 144.145C105.984 144.116 105.765 144.195 103.508 144.145C102.145 144.124 101.789 142.741 102.36 142.625C104.97 142.095 105.649 142.066 107.918 140.715C108.16 140.566 108.438 140.486 108.722 140.483Z' fill='#263238' />
      <path d='M106.382 136.449L113.087 136.553L113.124 134.386L105.947 134.154L106.382 136.449Z' fill='#561885' />
      <path opacity='0.3' d='M106.382 136.449L113.087 136.553L113.124 134.386L105.947 134.154L106.382 136.449Z' fill='black' />
      <path d='M108.391 140.923C108.404 140.916 108.416 140.907 108.425 140.896C108.434 140.884 108.441 140.871 108.444 140.857C108.446 140.841 108.443 140.825 108.436 140.811C108.429 140.797 108.417 140.785 108.403 140.778C108.254 140.695 106.929 139.999 106.519 140.215C106.48 140.236 106.448 140.266 106.425 140.303C106.402 140.34 106.388 140.382 106.386 140.426C106.378 140.497 106.386 140.568 106.41 140.635C106.435 140.702 106.475 140.762 106.527 140.811C106.887 141.138 107.844 141.022 108.378 140.923H108.391ZM106.622 140.343C106.875 140.26 107.595 140.546 108.117 140.807C107.384 140.919 106.838 140.877 106.635 140.695C106.6 140.663 106.574 140.622 106.558 140.578C106.542 140.533 106.537 140.485 106.543 140.438C106.543 140.421 106.548 140.404 106.556 140.39C106.565 140.375 106.578 140.363 106.593 140.355L106.622 140.343Z' fill='#561885' />
      <path d='M108.391 140.923H108.412C108.422 140.914 108.431 140.904 108.437 140.891C108.442 140.879 108.445 140.866 108.445 140.852C108.445 140.815 108.366 139.916 107.948 139.564C107.889 139.512 107.82 139.472 107.745 139.448C107.67 139.424 107.591 139.415 107.513 139.423C107.298 139.423 107.231 139.548 107.215 139.639C107.149 140.012 107.935 140.745 108.333 140.931C108.353 140.935 108.373 140.932 108.391 140.923ZM107.447 139.581H107.525C107.583 139.575 107.641 139.582 107.696 139.601C107.751 139.619 107.802 139.649 107.844 139.688C108.087 139.98 108.232 140.341 108.259 140.72C107.844 140.467 107.323 139.891 107.364 139.668C107.372 139.655 107.381 139.601 107.447 139.581Z' fill='#561885' />
      <path d='M130.577 134.457L134.06 132.386C134.116 132.352 134.183 132.338 134.248 132.347C134.314 132.356 134.374 132.387 134.42 132.435L136.491 134.639C136.541 134.693 136.578 134.758 136.6 134.828C136.622 134.899 136.627 134.973 136.616 135.046C136.605 135.119 136.577 135.188 136.536 135.249C136.494 135.31 136.439 135.361 136.375 135.397C135.244 136.043 134.387 136.466 132.979 137.307C132.109 137.824 130.104 139.117 128.908 139.829C127.711 140.542 126.87 139.477 127.251 139.055C129.057 137.149 129.868 135.964 130.196 134.912C130.26 134.717 130.396 134.554 130.577 134.457Z' fill='#263238' />
      <path d='M81.5288 23.4964L82.1749 20.5969L78.7416 19.5986C78.7416 19.5986 77.698 22.4029 79.7149 23.7408L81.5288 23.4964Z' fill='#FFC3BD' />
      <path d='M81.7277 17.7887L79.0606 16.3555L78.7334 19.6153L82.1667 20.6136L81.7277 17.7887Z' fill='#FFC3BD' />
      <path d='M98.2275 44.6632C96.952 44.307 95.7965 43.9135 94.5996 43.4827C93.4027 43.0519 92.2431 42.5839 91.0793 42.0702C89.9156 41.5566 88.7642 41.0181 87.6212 40.4134C87.0496 40.1234 86.4823 39.792 85.9149 39.469L85.0866 38.9512L84.8754 38.8228L84.5606 38.6157C84.3535 38.4693 84.1589 38.3202 83.9767 38.1683C83.327 37.6065 82.762 36.9535 82.2994 36.2298C81.8954 35.6068 81.5409 34.9531 81.2391 34.2747C80.699 33.0316 80.2721 31.7423 79.9636 30.4225C79.369 27.919 79.0537 25.3573 79.0234 22.7843L82.0757 22.3701C82.7179 24.6183 83.4796 26.8306 84.3577 28.9976C84.7692 30.0077 85.2565 30.9853 85.8155 31.9219C86.0608 32.3336 86.3377 32.7255 86.6438 33.0942C86.8765 33.373 87.1453 33.6195 87.4431 33.8273C87.4925 33.8604 87.5436 33.8909 87.5963 33.9185C87.623 33.9249 87.6482 33.9362 87.6709 33.9516L87.8614 34.0386L88.6151 34.4031C89.137 34.6351 89.6505 34.8712 90.1806 35.0948C91.2367 35.5463 92.3218 35.9647 93.4151 36.3789C94.5085 36.7931 95.6143 37.2073 96.7283 37.5718L100.042 38.7027L98.2275 44.6632Z' fill='#674085' />
      <path d='M109.211 28.645C108.97 31.1096 108.929 35.5914 110.78 37.0412C109.592 38.4785 108.022 40.1561 105.156 40.5372C102.29 40.9182 102.601 38.8803 103.259 37.7743C105.897 36.8672 105.632 34.8748 104.978 33.0357L109.211 28.645Z' fill='#FFC3BD' />
      <path d='M101.697 26.2509C101.668 26.5202 101.507 26.7231 101.333 26.7066C101.159 26.69 101.039 26.458 101.064 26.1888C101.089 25.9196 101.254 25.7207 101.428 25.7373C101.602 25.7539 101.722 25.9858 101.697 26.2509Z' fill='#263238' />
      <path d='M101.603 26.5122C101.205 27.2974 100.701 28.0236 100.104 28.6703C100.272 28.8178 100.47 28.9269 100.684 28.9898C100.899 29.0528 101.125 29.0681 101.346 29.0348L101.603 26.5122Z' fill='#ED847E' />
      <path d='M102.41 25.2529C102.386 25.2438 102.365 25.2287 102.348 25.2092C102.332 25.1896 102.32 25.1662 102.315 25.1411C102.276 24.947 102.192 24.7649 102.069 24.61C101.945 24.4551 101.787 24.3318 101.607 24.2505C101.587 24.2435 101.569 24.2326 101.554 24.2186C101.538 24.2045 101.526 24.1876 101.517 24.1688C101.508 24.1499 101.503 24.1295 101.502 24.1087C101.501 24.0879 101.504 24.0671 101.511 24.0476C101.518 24.028 101.529 24.01 101.543 23.9946C101.557 23.9792 101.574 23.9667 101.593 23.9579C101.612 23.949 101.632 23.944 101.653 23.943C101.674 23.9421 101.695 23.9452 101.714 23.9523C101.943 24.0534 102.145 24.2075 102.303 24.402C102.46 24.5965 102.57 24.8257 102.621 25.0707C102.63 25.1113 102.622 25.1537 102.6 25.1886C102.577 25.2235 102.542 25.2481 102.501 25.2571C102.471 25.2662 102.439 25.2647 102.41 25.2529Z' fill='#263238' />
      <path d='M110.809 26.4667C110.167 29.7059 109.956 31.632 108.084 33.0941C105.263 35.2812 101.437 33.2433 101.097 29.8798C100.79 26.8519 101.888 22.0677 105.259 21.1813C106.002 20.9859 106.782 20.9838 107.526 21.1753C108.27 21.3667 108.952 21.7453 109.509 22.275C110.065 22.8048 110.476 23.468 110.704 24.2017C110.931 24.9354 110.968 25.7151 110.809 26.4667Z' fill='#FFC3BD' />
      <path d='M104.236 27.3697C103.304 26.2968 103.652 23.9855 104.236 22.8796C103.549 23.3186 101.209 23.1612 101.196 21.3262C101.176 18.638 105.694 18.0125 108.171 18.4847C111.024 19.0481 113.584 21.7239 114.569 20.8002C115.584 22.5896 113.807 23.2151 113.807 23.2151C113.807 23.2151 115.571 27.6679 109.115 32.0296C108.229 31.905 107.328 31.9429 106.456 32.1414C106.256 32.1408 106.064 32.062 105.922 31.9219C105.267 31.2674 103.478 29.2461 104.236 27.3697Z' fill='#263238' />
      <path d='M113.563 23.1154C113.563 23.1154 114.781 23.3722 115.195 22.5894C115.075 23.7077 114.392 23.716 113.563 23.1154Z' fill='#263238' />
      <path d='M106.378 28.3965C105.969 29.119 105.29 29.6494 104.49 29.8711C103.429 30.1528 102.907 29.2001 103.214 28.2142C103.487 27.3154 104.374 26.0893 105.434 26.2426C106.494 26.3958 106.863 27.5101 106.378 28.3965Z' fill='#FFC3BD' />
      <path d='M119.022 39.8706C119.498 40.3262 119.888 40.7363 120.298 41.1754C120.708 41.6145 121.097 42.0494 121.474 42.5009C122.24 43.3914 122.969 44.3151 123.681 45.2513C124.394 46.1874 125.073 47.1484 125.715 48.1508L126.257 48.9419C126.46 49.2402 126.626 49.5467 126.8 49.8491C127.126 50.4498 127.41 51.0727 127.649 51.713C128.109 52.9187 128.446 54.1677 128.655 55.441C128.864 56.6622 128.981 57.8972 129.007 59.1358C129.044 60.3436 129.008 61.5526 128.9 62.756C128.8 63.9619 128.623 65.1601 128.369 66.3431L125.309 66.0076C125.255 64.9762 125.176 63.8496 125.056 62.7809C124.936 61.7122 124.779 60.6353 124.584 59.5914C124.246 57.5914 123.631 55.648 122.758 53.8173C122.55 53.4122 122.319 53.0194 122.066 52.6409C121.938 52.4628 121.818 52.2764 121.685 52.1148C121.61 52.0278 121.565 51.9616 121.474 51.858L121.205 51.5432C120.476 50.7148 119.755 49.8863 119.014 49.0579C118.272 48.2295 117.539 47.401 116.802 46.6099L114.628 44.2282L119.022 39.8706Z' fill='#674085' />
      <path d='M110.826 42.5006C110.296 44.8989 117.655 49.2648 117.655 49.2648L123.478 44.2942C122.001 42.1774 120.338 40.1965 118.509 38.375C115.489 35.3678 111.688 38.6153 110.826 42.5006Z' fill='#263238' />
      <path opacity='0.2' d='M115.941 45.0151C114.889 44.4393 114.727 45.7524 114.889 47.4217C116.355 48.4945 117.651 49.2649 117.651 49.2649L118.392 48.6312C117.808 47.1027 116.997 45.5908 115.941 45.0151Z' fill='black' />
      <path d='M125.242 65.4857L123.71 67.5319L127.392 69.0521C127.392 69.0521 128.353 67.3952 128.15 66.1526C128.103 65.9158 127.989 65.6976 127.82 65.5247C127.652 65.3519 127.437 65.2318 127.201 65.1792L126.53 65.0218C126.294 64.9644 126.046 64.9778 125.817 65.0603C125.588 65.1428 125.388 65.2907 125.242 65.4857Z' fill='#FFC3BD' />
      <path d='M122.969 70.8165L126.439 71.5869L127.392 69.0519L123.71 67.5317L122.969 70.8165Z' fill='#FFC3BD' />
      <path d='M117.27 38.901C115.762 51.634 119.821 67.3494 119.92 69.9672C117.319 70.1826 104.464 71.3673 99.1258 71.8022C96.0279 49.1322 98.8814 38.3791 98.8814 38.3791C98.8814 38.3791 99.9872 38.0229 101.507 37.6791C101.983 37.5714 102.501 37.4678 103.039 37.3726C103.334 37.3187 103.64 37.2732 103.946 37.2276C106.455 36.9716 108.975 36.8582 111.496 36.8879C111.973 36.8879 112.461 36.8879 112.934 36.9252C113.406 36.9625 113.799 36.9749 114.205 37.0039C114.83 37.0536 115.41 37.1116 115.903 37.1655C116.107 37.1885 116.305 37.2519 116.484 37.3519C116.664 37.4518 116.821 37.5864 116.949 37.7479C117.076 37.9093 117.17 38.0943 117.225 38.2923C117.28 38.4902 117.295 38.6971 117.27 38.901Z' fill='#263238' />
      <path opacity='0.2' d='M97.995 44.4971C97.9329 45.1474 97.8832 45.8557 97.8418 46.6179C99.018 46.5019 100.447 46.2036 101.051 45.5243C101.789 44.6628 99.5357 44.5095 97.995 44.4971Z' fill='black' />
      <path d='M102.431 45.657C99.5853 47.0653 94.1973 44.6628 94.1973 44.6628L94.2842 36.3081C94.2842 36.3081 100.103 37.2442 104.224 37.2028C108.448 37.1614 105.549 44.1078 102.431 45.657Z' fill='#263238' />
      <path d='M126.883 131.743L133.472 128.918L132.627 126.947L125.151 129.407L126.883 131.743Z' fill='#561885' />
      <path opacity='0.3' d='M126.883 131.743L133.472 128.918L132.627 126.947L125.151 129.407L126.883 131.743Z' fill='black' />
      <path d='M129.931 135.513C130.18 135.357 130.41 135.174 130.618 134.966C130.627 134.954 130.631 134.94 130.631 134.925C130.631 134.91 130.627 134.896 130.618 134.883C130.614 134.869 130.604 134.856 130.592 134.847C130.579 134.838 130.564 134.833 130.548 134.834C130.403 134.834 129.148 134.813 128.929 135.161C128.904 135.2 128.892 135.245 128.892 135.291C128.892 135.338 128.904 135.383 128.929 135.422C128.961 135.493 129.009 135.555 129.071 135.602C129.132 135.65 129.204 135.682 129.281 135.695C129.512 135.714 129.743 135.649 129.931 135.513ZM130.366 134.987C129.952 135.376 129.537 135.579 129.322 135.542C129.269 135.533 129.22 135.51 129.178 135.477C129.136 135.444 129.103 135.401 129.082 135.352C129.071 135.336 129.066 135.318 129.066 135.3C129.066 135.281 129.071 135.263 129.082 135.248C129.19 135.07 129.86 134.999 130.366 134.991V134.987Z' fill='#561885' />
      <path d='M130.601 134.983C130.614 134.974 130.625 134.963 130.634 134.95C130.641 134.938 130.645 134.924 130.645 134.911C130.645 134.897 130.641 134.883 130.634 134.871C130.609 134.838 130.05 134.043 129.557 133.981C129.498 133.969 129.437 133.97 129.378 133.984C129.32 133.999 129.265 134.026 129.218 134.063C129.167 134.094 129.129 134.142 129.109 134.198C129.09 134.253 129.09 134.314 129.11 134.37C129.238 134.726 130.183 135.008 130.572 134.995L130.601 134.983ZM129.334 134.155C129.393 134.122 129.462 134.111 129.528 134.121C129.843 134.171 130.22 134.594 130.398 134.817C129.984 134.768 129.325 134.511 129.247 134.304C129.247 134.283 129.226 134.237 129.305 134.175L129.334 134.155Z' fill='#561885' />
      <path opacity='0.9' d='M79.5617 3.74707H79.0771V6.09567H79.5617V3.74707Z' fill='#561885' />
      <path opacity='0.9' d='M79.5617 7.24316H79.0771V13.1789H79.5617V7.24316Z' fill='#561885' />
      <path opacity='0.9' d='M84.6165 12.9839L82.0762 15.522L82.4186 15.8648L84.9589 13.3268L84.6165 12.9839Z' fill='#561885' />
      <path opacity='0.9' d='M88.9375 18.3936H85.1895V18.8782H88.9375V18.3936Z' fill='#561885' />
      <path opacity='0.9' d='M72.9618 11.9376L72.6191 12.2803L76.2124 15.8741L76.555 15.5314L72.9618 11.9376Z' fill='#561885' />
      <path opacity='0.9' d='M73.4488 18.3936H71.7715V18.8782H73.4488V18.3936Z' fill='#561885' />
      <defs>
        <linearGradient id='paint0_linear_10832_19586' x1='34' y1='22.5' x2='157.232' y2='92.4228' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#6A00BA' stopOpacity='0.53' />
          <stop offset='1' stopColor='#561885' stopOpacity='0' />
        </linearGradient>
      </defs>
    </SvgIcon>
  )
}
