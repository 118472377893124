import { Typography, Box, useMediaQuery } from '@mui/material'
import { IconCircleCheckFilled, IconInfoCircle, IconInfoCircleFilled } from '@tabler/icons-react'
import { theme } from '~/utils'

type InfoBannerProps = {
  text: string,
  type?: 'info' | 'warning' | 'error' | 'success',
  icon?: JSX.Element,
}

export const InfoBanner: React.FC<InfoBannerProps> = ({ text, icon, type = 'info' }) => {
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const stylesType = {
    info: {
      color: theme.palette.info.dark,
      icon: <IconInfoCircleFilled style={{ color: theme.palette.info.dark }} size={34} />
    },
    warning: {
      color: theme.palette.yellow.main,
      icon: <IconInfoCircle size={34} color={theme.palette.yellow.main} />
    },
    error: {
      color: theme.palette.error.main,
      icon: <IconInfoCircle size={34} color={theme.palette.error.main} />
    },
    success: {
      color: theme.palette.success.main,
      icon: <IconCircleCheckFilled size={34} style={{ color: theme.palette.success.main }} />
    },
  }

  const selectedStyle = stylesType[type]

  return (
    <Box sx={{
      display: 'flex',
      alignItems: isLowerSm ? 'flex-start' : 'center',
      flexFlow: isLowerSm ? 'column' : 'row',
      gap: '.8rem',
      color: theme.palette.black.main,
      border: `1px solid ${selectedStyle.color}`,
      borderRadius: '.6rem',
      padding: isLowerSm ? '.8rem' : '1.4rem'
    }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {icon || selectedStyle.icon}
      </Box>
      <Typography variant={isLowerSm ? 'body2' : 'body1'}>{text}</Typography>
    </Box>
  )
}
