import styled from '@emotion/styled'
import { EIndicationStatus } from '~/graphql/types'
import { colorIndicationsStatus } from '~/utils'

type ContainerProps = {
  status: EIndicationStatus;
}
export const Container = styled.div<ContainerProps>`
  span {
    ${(props) => colorIndicationsStatus(props.status)}
    padding: .2rem .6rem;
    border-radius: .4rem;
    font-weight: 600;
    white-space: nowrap;
    font-size: .8rem;
  }
`
