/* eslint-disable no-unused-vars */
import React from 'react'
import { Box, Link, List, Typography } from '@mui/material'
import { theme } from '~/utils'
import { Collapsibles } from '../..'
import { TSidebarItem } from '~/components/Layout'
import { SidebarListItem } from '../SidebarListItem'
import { CollapsibleList } from '../CollapsibleList'
import { DrawerHeader, StyleDrawer } from '../../style'
import { IconLogout, IconVocabulary } from '@tabler/icons-react'
import { useAuth, usePartner, useSidebar } from '~/contexts'

export type SidebarContentProps = {
  isDrawerOpen: boolean
  collapsibles: Collapsibles
  sidebarItems: TSidebarItem[]
  toggleCollapsible: (collapsibleName: keyof Collapsibles) => void
}
export const SidebarContent: React.FC<SidebarContentProps> = (props) => {
  const { isDrawerOpen, sidebarItems, collapsibles, toggleCollapsible } = props
  const { logout } = useAuth()
  const { resetPartner } = usePartner()
  const { closeLogo, openLogo } = useSidebar()

  return (
    <StyleDrawer variant='permanent' open={isDrawerOpen}>
      <DrawerHeader sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
        <img src={isDrawerOpen ? openLogo : closeLogo} height={40} />
      </DrawerHeader >

      <List sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        {sidebarItems.map((sidebarItem) => {
          const { collapsibleName, text, nested, ...rest } = sidebarItem

          return (
            <React.Fragment key={`sidebar-list-item-${text}`}>
              <SidebarListItem
                text={text}
                isDrawerOpen={isDrawerOpen}
                isCollapsibleOpen={collapsibleName ? collapsibles[collapsibleName] : false}
                toggleCollapsible={collapsibleName ? () => toggleCollapsible(collapsibleName) : undefined}
                {...rest}
              />
              {nested && collapsibleName && (
                <CollapsibleList
                  isDrawerOpen={isDrawerOpen}
                  sidebarItem={sidebarItem}
                  isOpen={collapsibles[collapsibleName]}
                />
              )}
            </React.Fragment>
          )
        })}
        <Link href='https://despertaenergia.ac-page.com/material-de-apoio-parceiros' target='_blank' sx={{ textDecoration: 'none' }}>
          <Box sx={{
            background: '#5618850F',
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            padding: '16px 0',
            gap: '12px',
            margin: isDrawerOpen ? '15px 17px' : '0px',
            borderRadius: isDrawerOpen ? '8px' : 0,
            transition: 'all 0.3s ease-in-out ',
            '&:hover': {
              background: '#56188526'
            }
          }}>
            <IconVocabulary size={24} color='#9E78BC' />
            {isDrawerOpen && (
              <Box sx={{ maxWidth: 120, wordWrap: 'break-word', whiteSpace: 'normal', lineHeight: 0 }}>
                <Typography variant='body2' component={'span'} fontWeight={800} color={'#561885'}>NOVO! </Typography>
                <Typography variant='body2' component={'span'} fontWeight={400} color={'#561885'}>Material de
apoio para vendas</Typography>
              </Box>
            )}
          </Box>
        </Link>
        <SidebarListItem
          text='Sair'
          icon={<IconLogout />}
          hideChevronIcon
          onClick={() => logout(resetPartner)}
          isDrawerOpen={isDrawerOpen}
          sx={{ marginTop: 'auto', background: theme.palette.error.main }}
        />
      </List>
    </StyleDrawer>
  )
}
