import { SvgIcon, SvgIconProps } from '@mui/material'

export const PaperPlaneWithMail: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width={156} height={97} viewBox='0 0 156 97' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g opacity='0.95'>
        <path fillRule='evenodd' clipRule='evenodd' d='M77.8561 96.9934C103.227 96.7555 128.769 88.1798 137.522 68.2609C147.307 45.9931 141.193 19.4794 117.829 6.02732C95.8418 -6.63233 67.9026 2.60234 46.3369 15.7598C24.9369 28.8162 5.7498 48.3465 13.9264 69.4197C22.1089 90.5084 51.3501 97.2419 77.8561 96.9934Z' fill='url(#paint0_linear_10871_1617)' fillOpacity='0.5' />
        <path d='M156 34.0474L18.923 53.7809L0.529404 72.3419L156 34.0474Z' fill='#F9DC45' />
        <path opacity='0.3' d='M156 34.0474L18.923 53.7809L0.529404 72.3419L156 34.0474Z' fill='white' />
        <path d='M156 34.0474L18.923 53.7809L25.2847 80.6213L156 34.0474Z' fill='#F9DC45' />
        <path opacity='0.2' d='M156 34.0474L18.923 53.7809L25.2847 80.6213L156 34.0474Z' fill='black' />
        <path d='M28.0989 2.97466L20.1438 40.2531C19.9369 41.2265 20.1245 42.2423 20.6656 43.0775C21.2067 43.9128 22.057 44.4993 23.03 44.7084L94.0308 59.8603C95.0042 60.0656 96.0192 59.8773 96.8541 59.3365C97.689 58.7957 98.2758 57.9464 98.4864 56.9743L106.448 19.6958C106.653 18.7217 106.464 17.706 105.921 16.871C105.379 16.036 104.529 15.4497 103.555 15.2405L32.5545 0.0885888C31.5803 -0.116526 30.5645 0.0726246 29.7295 0.614645C28.8945 1.15667 28.3082 2.00741 28.0989 2.98067V2.97466Z' fill='#F9DC45' />
        <path opacity='0.48' d='M28.0989 2.97466L20.1438 40.2531C19.9369 41.2265 20.1245 42.2423 20.6656 43.0775C21.2067 43.9128 22.057 44.4993 23.03 44.7084L94.0308 59.8603C95.0042 60.0656 96.0192 59.8773 96.8541 59.3365C97.689 58.7957 98.2758 57.9464 98.4864 56.9743L106.448 19.6958C106.653 18.7217 106.464 17.706 105.921 16.871C105.379 16.036 104.529 15.4497 103.555 15.2405L32.5545 0.0885888C31.5803 -0.116526 30.5645 0.0726246 29.7295 0.614645C28.8945 1.15667 28.3082 2.00741 28.0989 2.98067V2.97466Z' fill='white' />
        <path d='M29.8482 0.533023L53.5692 29.6523C55.7691 32.352 58.8248 34.22 62.2308 34.947C65.6367 35.6741 69.1888 35.2168 72.2995 33.6507L105.846 16.7551C105.303 15.9756 104.484 15.4317 103.555 15.2339L32.554 0.0820745C31.6275 -0.115112 30.6606 0.0460178 29.8482 0.533023Z' fill='#F9DC45' />
        <path d='M156 34.0474L42.9387 60.9179L25.2847 80.6213L156 34.0474Z' fill='#F9DC45' />
        <path opacity='0.3' d='M156 34.0474L42.9387 60.9179L25.2847 80.6213L156 34.0474Z' fill='white' />
        <path d='M114.758 59.9465L115.062 60.4653L102.48 67.835L102.176 67.3161L114.758 59.9465Z' fill='#F9DC45' />
        <path d='M96.6209 70.5656L96.9248 71.0845L92.4005 73.7345L92.0966 73.2157L96.6209 70.5656Z' fill='#F9DC45' />
        <path d='M156 34.0474L42.9387 60.9179L64.916 87.4036L156 34.0474Z' fill='#F9DC45' />
        <path d='M4.77495 97C4.92727 97.0004 5.07825 96.9718 5.2199 96.9158L23.6315 89.6281C23.9261 89.5095 24.1618 89.2791 24.2871 88.9873C24.4125 88.6955 24.4173 88.366 24.3006 88.0707C24.1839 87.7753 23.9551 87.5381 23.6642 87.4109C23.3732 87.2836 23.0437 87.2766 22.7476 87.3913L4.32998 94.679C4.06943 94.782 3.85301 94.9727 3.71808 95.2182C3.58315 95.4638 3.53817 95.7487 3.59092 96.0238C3.64367 96.299 3.79083 96.5471 4.007 96.7253C4.22317 96.9035 4.49479 97.0007 4.77495 97Z' fill='#F9DC45' />
        <path opacity='0.3' d='M4.77495 97C4.92727 97.0004 5.07825 96.9718 5.2199 96.9158L23.6315 89.6281C23.9261 89.5095 24.1618 89.2791 24.2871 88.9873C24.4125 88.6955 24.4173 88.366 24.3006 88.0707C24.1839 87.7753 23.9551 87.5381 23.6642 87.4109C23.3732 87.2836 23.0437 87.2766 22.7476 87.3913L4.32998 94.679C4.06943 94.782 3.85301 94.9727 3.71808 95.2182C3.58315 95.4638 3.53817 95.7487 3.59092 96.0238C3.64367 96.299 3.79083 96.5471 4.007 96.7253C4.22317 96.9035 4.49479 97.0007 4.77495 97Z' fill='#FCEEA2' />
        <path d='M5.86268 86.4424C6.01349 86.4415 6.16263 86.4108 6.30161 86.3522L16.1929 82.4138C16.3408 82.3565 16.4759 82.2705 16.5904 82.1607C16.7049 82.051 16.7966 81.9197 16.8601 81.7744C16.9237 81.6291 16.9579 81.4727 16.9607 81.3141C16.9636 81.1555 16.9351 80.998 16.8768 80.8505C16.8185 80.703 16.7316 80.5685 16.6211 80.4547C16.5106 80.3409 16.3788 80.2501 16.233 80.1875C16.0873 80.1249 15.9306 80.0917 15.7721 80.0899C15.6135 80.0881 15.4561 80.1177 15.309 80.1769L5.41771 84.0974C5.12128 84.214 4.88326 84.4436 4.75587 84.7355C4.62849 85.0275 4.62214 85.3581 4.73824 85.6547C4.8229 85.8851 4.97601 86.084 5.17701 86.2248C5.378 86.3656 5.61727 86.4415 5.86268 86.4424Z' fill='#F9DC45' />
        <path opacity='0.5' d='M5.86268 86.4424C6.01349 86.4415 6.16263 86.4108 6.30161 86.3522L16.1929 82.4138C16.3408 82.3565 16.4759 82.2705 16.5904 82.1607C16.7049 82.051 16.7966 81.9197 16.8601 81.7744C16.9237 81.6291 16.9579 81.4727 16.9607 81.3141C16.9636 81.1555 16.9351 80.998 16.8768 80.8505C16.8185 80.703 16.7316 80.5685 16.6211 80.4547C16.5106 80.3409 16.3788 80.2501 16.233 80.1875C16.0873 80.1249 15.9306 80.0917 15.7721 80.0899C15.6135 80.0881 15.4561 80.1177 15.309 80.1769L5.41771 84.0974C5.12128 84.214 4.88326 84.4436 4.75587 84.7355C4.62849 85.0275 4.62214 85.3581 4.73824 85.6547C4.8229 85.8851 4.97601 86.084 5.17701 86.2248C5.378 86.3656 5.61727 86.4415 5.86268 86.4424Z' fill='#FAE161' />
        <path d='M26.5413 96.8446C26.6915 96.8429 26.8402 96.8144 26.9803 96.7604L44.9409 89.6291C45.0888 89.5718 45.2239 89.4858 45.3384 89.3761C45.4529 89.2663 45.5446 89.1351 45.6082 88.9898C45.6717 88.8445 45.7059 88.688 45.7088 88.5294C45.7116 88.3709 45.6831 88.2133 45.6248 88.0658C45.5665 87.9183 45.4797 87.7838 45.3692 87.67C45.2587 87.5562 45.1268 87.4654 44.9811 87.4028C44.8354 87.3402 44.6787 87.3071 44.5201 87.3053C44.3615 87.3034 44.2042 87.333 44.057 87.3923L26.0964 94.4996C25.8358 94.6026 25.6194 94.7933 25.4845 95.0388C25.3495 95.2843 25.3046 95.5693 25.3573 95.8444C25.4101 96.1196 25.5572 96.3677 25.7734 96.5459C25.9896 96.7241 26.2612 96.8213 26.5413 96.8206V96.8446Z' fill='#F9DC45' />
        <path opacity='0.3' d='M26.5413 96.8446C26.6915 96.8429 26.8402 96.8144 26.9803 96.7604L44.9409 89.6291C45.0888 89.5718 45.2239 89.4858 45.3384 89.3761C45.4529 89.2663 45.5446 89.1351 45.6082 88.9898C45.6717 88.8445 45.7059 88.688 45.7088 88.5294C45.7116 88.3709 45.6831 88.2133 45.6248 88.0658C45.5665 87.9183 45.4797 87.7838 45.3692 87.67C45.2587 87.5562 45.1268 87.4654 44.9811 87.4028C44.8354 87.3402 44.6787 87.3071 44.5201 87.3053C44.3615 87.3034 44.2042 87.333 44.057 87.3923L26.0964 94.4996C25.8358 94.6026 25.6194 94.7933 25.4845 95.0388C25.3495 95.2843 25.3046 95.5693 25.3573 95.8444C25.4101 96.1196 25.5572 96.3677 25.7734 96.5459C25.9896 96.7241 26.2612 96.8213 26.5413 96.8206V96.8446Z' fill='#FCEEA2' />
      </g>
      <defs>
        <linearGradient id='paint0_linear_10871_1617' x1='42' y1='37' x2='128.999' y2='82.4172' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#FFD100' />
          <stop offset='1' stopColor='#FFE986' stopOpacity='0.12' />
        </linearGradient>
      </defs>
    </SvgIcon>
  )
}
