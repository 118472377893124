import { Box } from '@mui/material'
import { Card, ContentTitle } from '~/components'
import { DeveloperSettingsBanner, IntegrationArea } from './components'

export const DeveloperSettings: React.FC = () => {
  return (
    <Box sx={{ minHeight: '100vh', height: '100%' }}>
      <Card gap={5}>
        <ContentTitle
          title='Desenvolvedor'
          style={{ padding: 0 }}
          description='Configure e gerencie integrações com outras ferramentas.'
          breadcrumbLinks={{ currentLink: 'Desenvolvedor', links: [{ href: '/app/settings', label: 'Configurações' }] }}
        />
        
        <DeveloperSettingsBanner />

        <IntegrationArea />
      </Card>
    </Box>
  )
}
