import { SvgIcon, SvgIconProps } from '@mui/material'

export const SoftwareIntegration: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width={230} height={154} viewBox='0 0 250 154' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path opacity='0.7' d='M210.792 85.9779C210.792 85.9779 213.821 31.7123 168.081 16.774C122.341 1.83576 99.3194 12.8108 84.7795 26.8345C70.2396 40.8582 65.393 50.0041 48.7327 51.8332C32.0724 53.6624 16.6237 56.7111 8.74795 77.1369C0.872172 97.5627 10.8684 113.72 26.0141 123.781C41.1598 133.841 58.7289 123.171 72.6629 129.573C86.597 135.975 95.3816 146.646 123.856 147.865C152.33 149.084 153.238 140.853 167.778 128.049C182.318 115.245 208.066 128.659 210.792 85.9779Z' fill='url(#paint0_linear_11270_5936)' fillOpacity='0.5' />
      <path d='M129.186 104.058H119.919V141.612H129.186V104.058Z' fill='#434343' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M185.392 42.1099H68.4233V113.366H185.392V42.1099Z' fill='#434343' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M183.037 42.1099H66.0684V113.366H183.037V42.1099Z' fill='#561885' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M178.82 44.6755H70.2817V110.797H178.82V44.6755Z' fill='white' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M178.82 106.818H70.2817V110.8H178.82V106.818Z' fill='white' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M125.059 137.399L90.6968 139.629V141.877H158.409V139.629L125.059 137.399Z' fill='#434343' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M178.82 44.6755H70.2817V110.797H178.82V44.6755Z' fill='white' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M176.56 48.2668H124.225V63.1586H176.56V48.2668Z' fill='white' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M176.352 66.7253H143.352V86.4435H176.352V66.7253Z' fill='white' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M143.352 86.4433L154.839 77.2125L159.642 80.7792L169.667 73.0176L176.14 79.31V86.4433H143.352Z' fill='#434343' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M151.288 71.7641C151.288 72.2202 151.152 72.666 150.9 73.0449C150.647 73.4239 150.288 73.7191 149.868 73.8932C149.448 74.0674 148.987 74.1126 148.541 74.0232C148.096 73.9339 147.687 73.7139 147.366 73.3912C147.045 73.0684 146.827 72.6574 146.738 72.2099C146.65 71.7625 146.696 71.2988 146.869 70.8775C147.043 70.4561 147.338 70.096 147.715 69.8427C148.093 69.5893 148.537 69.4541 148.991 69.4541C149.601 69.4549 150.185 69.6987 150.616 70.1318C151.046 70.5649 151.288 71.152 151.288 71.7641Z' fill='#434343' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M176.352 89.1692H143.352V108.887H176.352V89.1692Z' fill='white' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M143.352 108.887L154.839 99.6566L159.642 103.223L169.667 95.4617L176.14 101.757V108.887H143.352Z' fill='#434343' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M151.288 94.2049C151.288 94.6611 151.153 95.1072 150.901 95.4865C150.649 95.8659 150.29 96.1616 149.87 96.3362C149.451 96.5108 148.989 96.5565 148.543 96.4675C148.098 96.3785 147.688 96.1587 147.367 95.8361C147.046 95.5135 146.827 95.1024 146.738 94.6549C146.65 94.2074 146.695 93.7436 146.869 93.3221C147.043 92.9005 147.337 92.5402 147.715 92.2867C148.093 92.0332 148.537 91.8979 148.991 91.8979C149.6 91.8988 150.184 92.1421 150.615 92.5745C151.045 93.007 151.287 93.5933 151.288 94.2049Z' fill='#434343' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M126.932 98.7357L125.647 90.814H126.567L127.414 96.5058L128.472 90.8602H129.251L130.333 96.5366L131.161 90.814H132.06L130.793 98.7357H129.993L128.892 92.8683L127.782 98.7357H126.932Z' fill='#434343' />
      <path d='M133.244 91.2944H140.959' stroke='#434343' strokeMiterlimit='10' />
      <path d='M133.244 93.4319H140.959' stroke='#434343' strokeMiterlimit='10' />
      <path d='M133.244 95.5693H140.959' stroke='#434343' strokeMiterlimit='10' />
      <path d='M133.244 97.707H140.959' stroke='#434343' strokeMiterlimit='10' />
      <path d='M133.244 99.8445H140.959' stroke='#434343' strokeMiterlimit='10' />
      <path d='M133.244 101.982H140.959' stroke='#434343' strokeMiterlimit='10' />
      <path d='M133.244 104.119H140.959' stroke='#434343' strokeMiterlimit='10' />
      <path d='M133.244 106.257H140.959' stroke='#434343' strokeMiterlimit='10' />
      <path d='M133.244 108.395H140.959' stroke='#434343' strokeMiterlimit='10' />
      <path d='M128.637 67.0394C126.53 67.0364 124.469 67.6648 122.719 68.8443V44.6755H70.2788V110.797H122.719V86.6313C124.102 87.5589 125.681 88.1485 127.332 88.3528C128.982 88.557 130.657 88.3703 132.223 87.8076C133.789 87.2449 135.201 86.3218 136.348 85.1122C137.495 83.9026 138.343 82.4402 138.826 80.842C139.309 79.2438 139.413 77.5543 139.128 75.9087C138.844 74.2631 138.181 72.7072 137.19 71.3656C136.2 70.024 134.91 68.9339 133.425 68.1826C131.94 67.4314 130.3 67.0399 128.637 67.0394Z' fill='#434343' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M75.7378 54.7686H99.3389' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M75.7378 61.8618H88.0597' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M75.7378 57.9595H87.2255' stroke='#561885' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M89.731 57.9595H95.7876' stroke='#561885' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M99.3389 57.9595H105.396' stroke='#561885' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M75.7378 64.8772H87.2255' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M89.731 64.8772H95.7876' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M99.3389 64.8772H105.396' stroke='#561885' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M75.7378 71.7917H87.2255' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M89.731 71.7917H95.7876' stroke='#561885' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M99.3389 71.7917H105.396' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M82.8369 74.8071H91.5738' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M93.4814 74.8071H98.0876' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M100.786 74.8071H105.395' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M82.8369 78.5308H91.5738' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M93.4814 78.5308H98.0876' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M100.786 78.5308H105.395' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M75.7378 82.6089H99.3389' stroke='#561885' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M75.7378 89.7019H88.0597' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M75.7378 85.7996H87.2255' stroke='#561885' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M89.731 85.7996H95.7876' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M99.3389 85.7996H105.396' stroke='#561885' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M75.7378 92.7173H87.2255' stroke='#561885' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M89.731 92.7173H95.7876' stroke='#561885' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M99.3389 92.7173H105.396' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M75.7378 99.6318H87.2255' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M89.731 99.6318H95.7876' stroke='#561885' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M99.3389 99.6318H105.396' stroke='#561885' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M82.8369 102.647H91.5738' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M93.4814 102.647H98.0876' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M100.786 102.647H105.395' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M82.8369 106.371H91.5738' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M93.4814 106.371H98.0876' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M100.786 106.371H105.395' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M76.3813 50.5121L78.5985 49.3633V49.6959L76.7309 50.6384L78.5985 51.5778V51.9104L76.3813 50.7616V50.5121Z' fill='white' stroke='white' strokeMiterlimit='10' />
      <path d='M79.9268 52.1137L81.2669 48.6333H81.5736L80.2365 52.1137H79.9268Z' fill='white' stroke='white' strokeMiterlimit='10' />
      <path d='M85.1215 50.78L82.9043 51.9226V51.59L84.7872 50.6506L82.9043 49.7081V49.3755L85.1215 50.5182V50.78Z' fill='white' stroke='white' strokeMiterlimit='10' />
      <path d='M108.168 29.5093L98.8604 43.6834' stroke='#434343' strokeMiterlimit='10' strokeDasharray='5 5' />
      <path d='M122.973 21.951V18.7324L119.784 18.3751C119.604 17.7037 119.336 17.0595 118.987 16.4593L121.008 13.9183L118.741 11.6422L116.227 13.6658C115.632 13.3141 114.992 13.0447 114.325 12.865L113.967 9.63403H110.771L110.415 12.8496C109.747 13.0249 109.106 13.2901 108.508 13.638L105.981 11.6083L103.715 13.8844L105.727 16.4131C105.376 17.0107 105.108 17.653 104.929 18.3227L101.715 18.6831V21.9017L104.914 22.259C105.09 22.9297 105.354 23.5739 105.699 24.1747L103.678 26.7126L105.944 28.9888L108.462 26.9683C109.057 27.32 109.697 27.5893 110.363 27.7691L110.722 30.9969H113.927L114.283 27.7845C114.95 27.6078 115.592 27.3426 116.19 26.996L118.717 29.0257L120.983 26.7496L118.987 24.2209C119.337 23.6233 119.605 22.981 119.784 22.3113L122.973 21.951ZM112.338 24.1593C111.581 24.1575 110.842 23.9302 110.213 23.5062C109.585 23.0821 109.096 22.4804 108.808 21.7772C108.52 21.0739 108.446 20.3007 108.596 19.5554C108.746 18.81 109.112 18.126 109.649 17.5899C110.186 17.0537 110.869 16.6895 111.612 16.5433C112.355 16.3972 113.124 16.4756 113.823 16.7687C114.521 17.0618 115.118 17.5564 115.536 18.1899C115.955 18.8234 116.177 19.5674 116.175 20.3278C116.171 21.3451 115.767 22.3196 115.049 23.0378C114.332 23.756 113.36 24.1593 112.347 24.1593H112.338Z' fill='#561885' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M135.581 15.4892L135.24 13.3578L133.094 13.478C132.905 13.0531 132.66 12.6562 132.364 12.2983L133.4 10.3918L131.649 9.13516L130.205 10.7552C129.773 10.5907 129.319 10.487 128.859 10.4472L128.264 8.34668L126.142 8.70396L126.261 10.86C125.838 11.0499 125.442 11.2965 125.084 11.593L123.188 10.5304L121.937 12.2891L123.55 13.7398C123.386 14.1741 123.283 14.6291 123.244 15.0919L121.134 15.7079L121.49 17.8392L123.636 17.7191C123.825 18.1446 124.071 18.5424 124.366 18.9018L123.308 20.8053L125.059 22.0619L126.504 20.4418C126.936 20.6064 127.389 20.7101 127.85 20.7498L128.445 22.8504L130.582 22.4839L130.463 20.3279C130.886 20.1386 131.281 19.892 131.637 19.5948L133.535 20.6359L134.787 18.8772L133.174 17.4265C133.337 16.9922 133.441 16.5372 133.48 16.0744L135.581 15.4892ZM128.782 18.1349C128.281 18.2187 127.767 18.1514 127.305 17.9418C126.842 17.7321 126.452 17.3894 126.183 16.9569C125.914 16.5245 125.779 16.0217 125.795 15.5122C125.811 15.0027 125.976 14.5093 126.271 14.0944C126.566 13.6795 126.977 13.3617 127.451 13.1811C127.926 13.0006 128.443 12.9654 128.938 13.0801C129.432 13.1948 129.882 13.4541 130.23 13.8253C130.578 14.1966 130.808 14.6631 130.892 15.1658C131.004 15.8405 130.844 16.5321 130.449 17.0888C130.053 17.6455 129.454 18.0218 128.782 18.1349Z' fill='#434343' stroke='#434343' strokeMiterlimit='10' />
      <path d='M80.8247 29.6575L80.9756 30.4152' stroke='#434343' strokeMiterlimit='10' />
      <path d='M81.2573 31.8352L83.3641 42.4858' stroke='#434343' strokeMiterlimit='10' strokeDasharray='4.7 4.7' />
      <path d='M83.5049 43.1941L83.6558 43.9518' stroke='#434343' strokeMiterlimit='10' />
      <path d='M82.9838 28.3362H87.314V12.1446H82.9838V28.3362Z' fill='#561885' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M77.2094 28.3362H81.5396V15.7698H77.2094V28.3362Z' fill='#434343' stroke='#434343' strokeMiterlimit='10' />
      <path d='M71.4379 28.3362H75.7681V18.6711H71.4379V28.3362Z' fill='#561885' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M65.6601 28.3362H69.9902V21.0859H65.6601V28.3362Z' fill='#434343' stroke='#434343' strokeMiterlimit='10' />
      <path d='M67.6874 20.017L67.2642 19.4595L71.6924 16.0746L73.8882 16.8108L77.513 12.8899L79.6443 13.5429L83.5114 9.09227L85.149 10.1549L86.8479 8.94751L87.2527 9.52039L85.1674 11.0019L83.6402 10.0101L79.8743 14.3437L77.7276 13.6876L74.0936 17.6177L71.8212 16.8569L67.6874 20.017Z' fill='#434343' />
      <path d='M87.6054 10.8511L88.9731 7.86963L85.7163 8.17147L87.6054 10.8511Z' fill='#434343' />
      <path d='M46.8804 29.0845L47.5182 29.5095' stroke='#434343' strokeMiterlimit='10' />
      <path d='M48.7998 30.3596L68.6749 43.5574' stroke='#434343' strokeMiterlimit='10' strokeDasharray='5.01 5.01' />
      <path d='M69.3159 43.9824L69.9538 44.4075' stroke='#434343' strokeMiterlimit='10' />
      <path d='M26.2201 15.8774H48.4841C49.003 15.8774 49.5006 16.0845 49.8675 16.453C50.2345 16.8215 50.4406 17.3213 50.4406 17.8425V33.1347H24.2666V17.8394C24.2674 17.3193 24.4735 16.8207 24.8397 16.453C25.2058 16.0852 25.7022 15.8783 26.2201 15.8774Z' fill='white' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M30.8691 22.8105H43.2677' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M30.8691 24.8218H43.2677' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M30.8691 26.8298H43.2677' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M30.8691 28.8381H43.2677' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M50.4406 18.6002V17.4852C50.4406 17.0588 50.2719 16.6499 49.9717 16.3483C49.6715 16.0468 49.2644 15.8774 48.8398 15.8774H25.8643C25.6543 15.8774 25.4463 15.919 25.2522 15.9999C25.0582 16.0807 24.8819 16.1992 24.7335 16.3485C24.5851 16.4979 24.4675 16.6751 24.3873 16.8702C24.3072 17.0652 24.2662 17.2742 24.2666 17.4852V18.6002H50.4406Z' fill='#561885' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M45.9634 17.3096C45.9634 17.2291 45.9396 17.1504 45.895 17.0834C45.8504 17.0165 45.7869 16.9644 45.7128 16.9337C45.6387 16.903 45.5571 16.8952 45.4785 16.9111C45.3999 16.9271 45.3278 16.9661 45.2713 17.0233C45.2148 17.0804 45.1765 17.1531 45.1612 17.2322C45.1459 17.3113 45.1544 17.3931 45.1855 17.4674C45.2166 17.5416 45.269 17.6049 45.3359 17.6492C45.4029 17.6935 45.4815 17.7168 45.5617 17.7162C45.6685 17.7154 45.7707 17.6722 45.8459 17.596C45.9212 17.5199 45.9634 17.4169 45.9634 17.3096Z' fill='#434343' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M47.5796 17.3096C47.5796 17.2292 47.5559 17.1506 47.5114 17.0838C47.4669 17.0169 47.4037 16.9648 47.3297 16.934C47.2558 16.9033 47.1744 16.8952 47.0958 16.9109C47.0173 16.9266 46.9452 16.9653 46.8886 17.0222C46.832 17.079 46.7934 17.1515 46.7778 17.2303C46.7622 17.3092 46.7702 17.3909 46.8008 17.4652C46.8315 17.5395 46.8834 17.603 46.9499 17.6477C47.0165 17.6924 47.0948 17.7162 47.1748 17.7162C47.2822 17.7162 47.3851 17.6734 47.4611 17.5971C47.537 17.5209 47.5796 17.4175 47.5796 17.3096Z' fill='#434343' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M49.1953 17.3096C49.1953 17.2292 49.1716 17.1506 49.1271 17.0838C49.0826 17.0169 49.0194 16.9648 48.9455 16.934C48.8715 16.9033 48.7901 16.8952 48.7116 16.9109C48.633 16.9266 48.5609 16.9653 48.5043 17.0222C48.4477 17.079 48.4091 17.1515 48.3935 17.2303C48.3779 17.3092 48.3859 17.3909 48.4166 17.4652C48.4472 17.5395 48.4991 17.603 48.5657 17.6477C48.6322 17.6924 48.7105 17.7162 48.7905 17.7162C48.8979 17.7162 49.0009 17.6734 49.0768 17.5971C49.1527 17.5209 49.1953 17.4175 49.1953 17.3096Z' fill='#434343' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M20.9454 36.8031C20.8413 36.7162 20.7556 36.6093 20.6933 36.4888C20.6309 36.3682 20.5932 36.2363 20.5824 36.1008C20.5715 35.9654 20.5877 35.8291 20.6301 35.7001C20.6724 35.571 20.74 35.4517 20.8288 35.3493L28.1888 26.8762L29.3112 27.8002L22.3867 36.6521C22.303 36.7585 22.1989 36.847 22.0805 36.9124C21.9622 36.9778 21.832 37.0187 21.6977 37.0328C21.5634 37.0468 21.4276 37.0338 21.2984 36.9943C21.1692 36.9549 21.0492 36.8898 20.9454 36.8031Z' fill='#434343' stroke='#434343' strokeLinejoin='round' />
      <path d='M26.2263 29.1306L20.8289 35.3491C20.7437 35.4519 20.6796 35.5704 20.64 35.6981C20.6005 35.8258 20.5863 35.96 20.5984 36.0931C20.6105 36.2263 20.6486 36.3557 20.7105 36.474C20.7725 36.5924 20.857 36.6973 20.9593 36.7829C21.0615 36.8684 21.1796 36.9329 21.3067 36.9726C21.4338 37.0123 21.5675 37.0265 21.7 37.0144C21.8326 37.0022 21.9614 36.9639 22.0793 36.9018C22.1971 36.8396 22.3016 36.7547 22.3868 36.652L27.4928 30.1285L26.2263 29.1306Z' fill='white' stroke='#434343' strokeLinejoin='round' />
      <path d='M34.3131 20.5899C33.6068 20.0024 32.7429 19.6384 31.8307 19.5441C30.9185 19.4497 29.9989 19.6291 29.1882 20.0596C28.3775 20.4901 27.7121 21.1524 27.2761 21.9628C26.8402 22.7731 26.6533 23.695 26.7391 24.6121C26.8248 25.5291 27.1794 26.3999 27.758 27.1145C28.3366 27.8292 29.1131 28.3554 29.9895 28.6268C30.8658 28.8981 31.8026 28.9024 32.6814 28.639C33.5602 28.3757 34.3414 27.8565 34.9264 27.1472C35.3149 26.6762 35.6072 26.133 35.7866 25.5485C35.966 24.9641 36.029 24.3498 35.972 23.7409C35.9151 23.132 35.7393 22.5403 35.4546 21.9997C35.17 21.459 34.7821 20.98 34.3131 20.5899ZM28.8268 27.2457C28.2224 26.7429 27.7801 26.0713 27.5557 25.3159C27.3313 24.5606 27.335 23.7554 27.5663 23.0021C27.7976 22.2488 28.2461 21.5814 28.8551 21.0841C29.464 20.5869 30.2062 20.2821 30.9876 20.2085C31.769 20.1348 32.5546 20.2955 33.2451 20.6703C33.9355 21.0451 34.4998 21.6171 34.8666 22.314C35.2334 23.0108 35.3862 23.8013 35.3057 24.5854C35.2252 25.3695 34.915 26.112 34.4143 26.7191C34.0819 27.1221 33.6736 27.4555 33.2129 27.7001C32.7522 27.9447 32.248 28.0957 31.7292 28.1446C31.2103 28.1936 30.687 28.1393 30.189 27.9851C29.691 27.8309 29.2281 27.5796 28.8268 27.2457Z' fill='#434343' stroke='#434343' strokeLinejoin='round' />
      <path d='M37.7231 65.6687H38.4898' stroke='#434343' strokeMiterlimit='10' />
      <path d='M40.0293 65.6687H66.9148' stroke='#434343' strokeMiterlimit='10' strokeDasharray='5.01 5.01' />
      <path d='M67.6846 65.6687H68.4512' stroke='#434343' strokeMiterlimit='10' />
      <path d='M25.1099 54.208H42.4457' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M33.7798 62.7858V54.208' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M20.041 56.3423V67.1931' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M36.7236 68.3328H52.4219V77.8684' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M42.4458 68.4961V77.6961' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M28.2165 50.592H16.9312V58.3136H28.2165V50.592Z' fill='#434343' stroke='#434343' strokeMiterlimit='10' />
      <path d='M49.5607 50.592H38.2754V58.3136H49.5607V50.592Z' fill='#561885' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M46.1599 75.3953H38.9287V80.3417H46.1599V75.3953Z' fill='#561885' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M24.0828 64.72H16.8516V69.6665H24.0828V64.72Z' fill='white' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M55.6482 75.3953H48.417V80.3417H55.6482V75.3953Z' fill='#434343' stroke='#434343' strokeMiterlimit='10' />
      <path d='M33.7767 62.7842L28.0889 68.4968L33.7767 74.2094L39.4646 68.4968L33.7767 62.7842Z' fill='#561885' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M54.6968 102.253L55.4236 102.004' stroke='#434343' strokeMiterlimit='10' />
      <path d='M56.8804 101.505L67.7977 97.7625' stroke='#434343' strokeMiterlimit='10' strokeDasharray='5.02 5.02' />
      <path d='M68.5244 97.5129L69.2512 97.2634' stroke='#434343' strokeMiterlimit='10' />
      <path d='M52.9765 97.4636H18.4121V105.616H52.9765V97.4636Z' fill='white' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M52.8602 107.569H34.7944V118.364H52.8602V107.569Z' fill='white' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M34.7944 118.365L41.0842 113.313L43.7123 115.266L49.2016 111.016L52.7467 114.462V118.365H34.7944Z' fill='#434343' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M37.8823 111.589C38.5767 111.589 39.1397 111.023 39.1397 110.326C39.1397 109.628 38.5767 109.063 37.8823 109.063C37.1879 109.063 36.625 109.628 36.625 110.326C36.625 111.023 37.1879 111.589 37.8823 111.589Z' fill='#434343' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M52.8602 119.858H34.7944V130.654H52.8602V119.858Z' fill='white' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M34.7944 130.654L41.0842 125.599L43.7123 127.552L49.2016 123.302L52.7467 126.748V130.654H34.7944Z' fill='#434343' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M37.8823 123.878C38.5767 123.878 39.1397 123.312 39.1397 122.615C39.1397 121.917 38.5767 121.352 37.8823 121.352C37.1879 121.352 36.625 121.917 36.625 122.615C36.625 123.312 37.1879 123.878 37.8823 123.878Z' fill='#434343' stroke='#434343' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M18.9365 108.382H32.6691' stroke='#434343' strokeMiterlimit='10' />
      <path d='M18.9365 111.447H32.6691' stroke='#434343' strokeMiterlimit='10' />
      <path d='M18.9365 114.511H32.6691' stroke='#434343' strokeMiterlimit='10' />
      <path d='M18.9365 117.576H32.6691' stroke='#434343' strokeMiterlimit='10' />
      <path d='M18.9365 120.641H32.6691' stroke='#434343' strokeMiterlimit='10' />
      <path d='M18.9365 123.705H32.6691' stroke='#434343' strokeMiterlimit='10' />
      <path d='M18.9365 126.77H32.6691' stroke='#434343' strokeMiterlimit='10' />
      <path d='M18.9365 129.834H32.6691' stroke='#434343' strokeMiterlimit='10' />
      <path d='M26.3613 141.877H221.104' stroke='#434343' strokeMiterlimit='10' />
      <path d='M16.2505 141.877H21.4914' stroke='#434343' strokeMiterlimit='10' />
      <defs>
        <linearGradient id='paint0_linear_11270_5936' x1='40.6318' y1='41.0043' x2='217.694' y2='147.77' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#6A00BA' stopOpacity='0.53' />
          <stop offset='1' stopColor='#561885' stopOpacity='0' />
        </linearGradient>
      </defs>
    </SvgIcon>
  )
}