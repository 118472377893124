import { IconApi, IconKeyboard, IconLink } from '@tabler/icons-react'
import { Tooltip } from '@mui/material'
import { EIndicationOrigin } from '~/graphql/types'

type OriginStatusProps = {
  status: EIndicationOrigin
}

export const OriginStatus: React.FC<OriginStatusProps> = ({ status }) => {

  // eslint-disable-next-line no-unused-vars
  const origins: { [x in EIndicationOrigin]: { text: string , icon: JSX.Element } } = {
    link: {
      text: 'Link de indicação',
      icon: <IconLink color='#999' size={28} />
    },
    partnerArea: {
      text: 'Proposta manual',
      icon: <IconKeyboard color='#999' size={28} />
    },
    api: {
      text: 'Integração api',
      icon: <IconApi color='#999' size={28} />
    }
  }

  const selectedOrigin = origins[status]

  return (
    <Tooltip title={selectedOrigin.text} arrow>
      {selectedOrigin.icon} 
    </Tooltip>
  )
}
