import { Box, BoxProps } from '@mui/material'

export const Card: React.FC<BoxProps> = ({ children, sx, ...props }) => {
  return (
    <Box
      sx={{
        p: 4,
        display: 'flex',
        borderRadius: '12px',
        flexDirection: 'column',
        backgroundColor: 'common.white',
        border: '1px solid #E6E6E6',
        ...sx
      }}
      {...props}
    >
      {children}
    </Box>
  )
}
