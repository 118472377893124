import { Box } from '@mui/material'
import { HeaderSection, PerformanceSection, RequestProposalBanner } from './components'
import { usePartner } from '~/contexts/partner'

export const SubAffiliateHome: React.FC = () => {
  const { myPartner } = usePartner()

  return (
    <Box sx={{ display: 'flex', padding: 0, flexDirection: 'column', gap: 4 }}>
      <HeaderSection />
      <PerformanceSection />
      {myPartner?.configuration.allowManualProposal && 
        <RequestProposalBanner />
      }
    </Box>
  )
}
