import { createContext, useContext, useEffect, useState } from 'react'
import { useApiPagination } from '~/hooks'
import { UserData, UserListData, UsersContextData, UsersProviderProps } from './types'
import { useAffiliatePartnerUserListQuery } from '~/graphql/types'
import { toast } from 'react-toastify'

const UsersContext = createContext({} as UsersContextData)

export const UsersProvider: React.FC<UsersProviderProps> = ({ children }) => {
  const [users, setUsers] = useState<UserListData>([])
  const [selectedUser, setSelectedUser] = useState<null | UserData>(null)
  const paginationData = useApiPagination({ pageSize: 20, currentPage: 0 })
  const { data, loading: usersIsLoading } = useAffiliatePartnerUserListQuery({
    variables: {
      params: {
        pageSize: paginationData.metadata.pageSize,
        pageNumber: paginationData.metadata.currentPage,
        filter: {
          ...paginationData.filtersTable?.nameOrUsernameOrEmail.length > 0 ? { nameOrUsernameOrEmail: paginationData.filtersTable?.nameOrUsernameOrEmail } : {},
          ...paginationData.filtersTable?.status?.length > 0 ? { status: paginationData.filtersTable?.status } : {},
        },
        sort: {
          field: 'createdAt',
          order: -1
        }
      }
    },
    onError(error) {
      toast.error(error.message)
    },
    onCompleted(dataResponse) {
      paginationData.setMetadata({ ...paginationData.metadata, documentCounts: dataResponse.affiliatePartnerUserList.meta.documentCounts })
    },
  })

  useEffect(() => {
    if(data) {
      setUsers(data.affiliatePartnerUserList.data as UserListData)
    }
  }, [data])

  return (
    <UsersContext.Provider value={{
      selectedUser,
      setSelectedUser,
      setUsers,
      users,
      paginationData,
      usersIsLoading
    }}>
      {children}
    </UsersContext.Provider>
  )
}

export const useUsers = () => useContext(UsersContext)
