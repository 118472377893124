import { useTheme } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material'
import { IconArrowLeft, IconCheck } from '@tabler/icons-react'
import { useCallback, useEffect } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { DragAndDrop } from '~/components'
import * as yup from  'yup'
import { useIndicationFlow } from '~/contexts'
import { EProposalFileStatus, EProposalFileType, useUpdateIndicationProposalFilesMutation } from '~/graphql/types'
import { fileSchema } from '~/utils/yupSchema'
import { useUploadMultiFilesWithNoProposal } from '~/hooks'
import { MultiFilesWithNoProposalReturn } from '~/services/uploadFile'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { documentFileIsDisabled } from '../utils'

type FormData = {
  customerPfDocumentWithPhotoFront: null | File[]
  customerPfDocumentWithPhotoBack: null | File[]
  customerPjSocialContract: null | File[]
  customerPjDocumentCard: null | File[]
  energyBill: null | File[]
}

const schema = yup
  .object({
    energyBill: fileSchema,
    customerPjDocumentCard: fileSchema,
    customerPjSocialContract: fileSchema,
    customerPfDocumentWithPhotoBack: fileSchema,
    customerPfDocumentWithPhotoFront: fileSchema,
  })
  .required()

export const Documents: React.FC = () => {
  const theme = useTheme()
  const { selectedIndication, isPf, selectedProposal, setSelectedProposal } = useIndicationFlow()
  const [uploadMultiFiles, { loading: loadingFilesUpload }] = useUploadMultiFilesWithNoProposal()
  const formMethods = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange'
  })

  const { 
    customerPfDocumentWithPhotoBack: customerPfDocumentWithPhotoBackWatch,
    customerPfDocumentWithPhotoFront: customerPfDocumentWithPhotoFrontWatch,
    customerPjDocumentCard: customerPjDocumentCardWatch,
    customerPjSocialContract: customerPjSocialContractWatch,
    energyBill: energyBillWatch
  } = useWatch({ control: formMethods.control })

  const enableSubmit = [
    customerPfDocumentWithPhotoBackWatch && customerPfDocumentWithPhotoBackWatch?.length > 0,
    customerPfDocumentWithPhotoFrontWatch && customerPfDocumentWithPhotoFrontWatch?.length > 0,
    customerPjSocialContractWatch && customerPjSocialContractWatch?.length > 0,
    customerPjDocumentCardWatch && customerPjDocumentCardWatch?.length > 0,
    energyBillWatch && energyBillWatch?.length > 0,
  ].some(item => item)

  const [updateIndicationFiles, { loading: updateIndicationFilesIsLoading }] = useUpdateIndicationProposalFilesMutation()

  const uploadAllFilesInIndication = useCallback(async (files: MultiFilesWithNoProposalReturn[] | null) => {
    if(!files || !(files?.length > 0)) return null
    
    for (let index = 0; index < files.length; index++) {

      await updateIndicationFiles({
        variables: {
          params: {
            id: selectedIndication?._id || '',
            type: files[index].type,
            file: {
              fileName: files[index].file.fileName,
              fileUrl: files[index].file.fileUrl,
              key: files[index].file.key,
              storageService: files[index].file.storageService,
            }
          }
        },
        onCompleted(data) {
          if(files.length - 1 === index && selectedProposal) {
            toast.success('Os documentos foram salvos com sucesso!')
            setSelectedProposal({ ...selectedProposal, files: data.updateIndicationProposalFiles.files })
            formMethods.clearErrors()
          }
        },
        onError(error) {
          toast.error(error.message)
        },
      })
    }
    
  }, [selectedIndication, selectedProposal])

  const energyBillFile = selectedProposal?.files?.find(item => item.type === EProposalFileType.energyBill)
  const customerPfDocumentWithPhotoFront = selectedProposal?.files?.find(item => item.type === EProposalFileType.customerPfDocumentWithPhotoFront)
  const customerPfDocumentWithPhotoBack = selectedProposal?.files?.find(item => item.type === EProposalFileType.customerPfDocumentWithPhotoBack)
  const customerPjSocialContract = selectedProposal?.files?.find(item => item.type === EProposalFileType.customerPjSocialContract)
  const customerPjDocumentCard = selectedProposal?.files?.find(item => item.type === EProposalFileType.customerPjDocumentCard)
 
  useEffect(() => {
    const energyBillFileIsPendency = Boolean(energyBillFile?.rejectedAt)
    
    if(energyBillFileIsPendency) {
      formMethods.setError(`energyBill`, { message: 'Este campo foi reprovado por um analista!' })
    }
      
    if(isPf) {
      const customerPfDocumentWithPhotoFrontIsPendency = Boolean(customerPfDocumentWithPhotoFront?.rejectedAt)
      const customerPfDocumentWithPhotoBackIsPendency = Boolean(customerPfDocumentWithPhotoBack?.rejectedAt)

      if(customerPfDocumentWithPhotoFrontIsPendency) {
        formMethods.setError(`customerPfDocumentWithPhotoFront`, { message: 'Este campo foi reprovado por um analista!' })
      }
          
      if(customerPfDocumentWithPhotoBackIsPendency) {
        formMethods.setError(`customerPfDocumentWithPhotoBack`, { message: 'Este campo foi reprovado por um analista!' })
      }
            
    } else {

      const customerPjSocialContractIsPendency = Boolean(customerPjSocialContract?.rejectedAt)
      const customerPjDocumentCardIsPendency = Boolean(customerPjDocumentCard?.rejectedAt)
      if(customerPjSocialContractIsPendency) {
        formMethods.setError(`customerPjSocialContract`, { message: 'Este campo foi reprovado por um analista!' })
      }
  
      if(customerPjDocumentCardIsPendency) {
        formMethods.setError(`customerPjDocumentCard`, { message: 'Este campo foi reprovado por um analista!' })
      }
    }
  }, [])

  const handleSubmit = useCallback(async (data: FormData) => {

    const filesToUpload: File[][] = []
    const fileTypes: EProposalFileType[] = []

    if (data.energyBill && data.energyBill.length > 0) {
      filesToUpload.push(data.energyBill)
      fileTypes.push(EProposalFileType.energyBill)
    }

    if (data.customerPfDocumentWithPhotoFront && data.customerPfDocumentWithPhotoFront.length > 0) {
      filesToUpload.push(data.customerPfDocumentWithPhotoFront)
      fileTypes.push(EProposalFileType.customerPfDocumentWithPhotoFront)
    }

    if (data.customerPfDocumentWithPhotoBack && data.customerPfDocumentWithPhotoBack.length > 0) {
      filesToUpload.push(data.customerPfDocumentWithPhotoBack)
      fileTypes.push(EProposalFileType.customerPfDocumentWithPhotoBack)
    }

    if (data.customerPjSocialContract && data.customerPjSocialContract.length > 0) {
      filesToUpload.push(data.customerPjSocialContract)
      fileTypes.push(EProposalFileType.customerPjSocialContract)
    }

    if (data.customerPjDocumentCard && data.customerPjDocumentCard.length > 0) {
      filesToUpload.push(data.customerPjDocumentCard)
      fileTypes.push(EProposalFileType.customerPjDocumentCard)
    }

    const filesToUpdate = await uploadMultiFiles({
      types: fileTypes,
      files: filesToUpload
    })

    if (filesToUpdate) {
      uploadAllFilesInIndication(filesToUpdate)
    }
  }, [])

  return (
    <>
      <FormProvider {...formMethods}>

        <form onSubmit={formMethods.handleSubmit((data) => handleSubmit(data))}>

          {isPf && (
            <>
              <Typography sx={{ padding: '1.6rem 0 .6rem 0' }} variant='h3' fontWeight={500} color={theme.palette.grey[800]}>RG ou CNH (Frente)</Typography>
              <DragAndDrop
                $variant='secondary'
                label='Anexar RG ou CNG (Frente)' 
                fileDescription='(JPG, JPEG, PNG, PDF. Tamanho máximo 1MB)' 
                name='customerPfDocumentWithPhotoFront'
                disabled={documentFileIsDisabled(customerPfDocumentWithPhotoFront)}
                status={customerPfDocumentWithPhotoFront?.status as EProposalFileStatus}
                sentAt={customerPfDocumentWithPhotoFront?.sendAt}
                $completed={customerPfDocumentWithPhotoFront?.sendAt}
                fileName={customerPfDocumentWithPhotoFront?.file?.fileName}
                fileUrl={customerPfDocumentWithPhotoFront?.file?.fileUrl}
              />

              <Typography sx={{ padding: '1.6rem 0 .6rem 0' }} variant='h3' fontWeight={500} color={theme.palette.grey[800]}>RH ou CNH (Verso)</Typography>
              <DragAndDrop
                $variant='secondary'
                label='Anexar RG ou CNH (Verso)' 
                fileDescription='(JPG, JPEG, PNG, PDF. Tamanho máximo 1MB)' 
                name='customerPfDocumentWithPhotoBack'
                disabled={documentFileIsDisabled(customerPfDocumentWithPhotoBack)}
                status={customerPfDocumentWithPhotoBack?.status as EProposalFileStatus}
                sentAt={customerPfDocumentWithPhotoBack?.sendAt}
                $completed={customerPfDocumentWithPhotoBack?.sendAt}
                fileName={customerPfDocumentWithPhotoBack?.file?.fileName}
                fileUrl={customerPfDocumentWithPhotoBack?.file?.fileUrl}
              />
            </>
          )}

          {!isPf && (
            <>
              <Typography sx={{ padding: '1.6rem 0 .6rem 0' }} variant='h3' fontWeight={500} color={theme.palette.grey[800]}>Contrato social</Typography>
              <DragAndDrop
                $variant='secondary'
                label='Anexar contrato social'
                fileDescription='(JPG, JPEG, PNG, PDF. Tamanho máximo 1MB)' 
                name='customerPjSocialContract'
                disabled={documentFileIsDisabled(customerPjSocialContract)}
                status={customerPjSocialContract?.status as EProposalFileStatus}
                sentAt={customerPjSocialContract?.sendAt}
                $completed={customerPjSocialContract?.sendAt}
                fileName={customerPjSocialContract?.file?.fileName}
                fileUrl={customerPjSocialContract?.file?.fileUrl}
              />

              <Typography sx={{ padding: '1.6rem 0 .6rem 0' }} variant='h3' fontWeight={500} color={theme.palette.grey[800]}>Cartão CNPJ</Typography>
              <DragAndDrop
                $variant='secondary'
                label='Anexar RG (Frente)' 
                fileDescription='(JPG, JPEG, PNG, PDF. Tamanho máximo 1MB)' 
                name='customerPjDocumentCard'
                disabled={documentFileIsDisabled(customerPjDocumentCard)}
                status={customerPjDocumentCard?.status as EProposalFileStatus}
                sentAt={customerPjDocumentCard?.sendAt}
                $completed={customerPjDocumentCard?.sendAt}
                fileName={customerPjDocumentCard?.file?.fileName}
                fileUrl={customerPjDocumentCard?.file?.fileUrl}
              />
            </>
          )}
          
          <Typography sx={{ padding: '1.6rem 0 .6rem 0' }} variant='h3' fontWeight={500} color={theme.palette.grey[800]}>Fatura de energia</Typography>
          <DragAndDrop
            $variant='secondary'
            label='Anexar fatura de energia' 
            fileDescription='(JPG, JPEG, PNG, PDF. Tamanho máximo 1MB)' 
            name='energyBill'
            disabled={documentFileIsDisabled(energyBillFile)}
            status={energyBillFile?.status as EProposalFileStatus}
            sentAt={energyBillFile?.sendAt}
            $completed={energyBillFile?.sendAt}
            fileName={energyBillFile?.file?.fileName}
            fileUrl={energyBillFile?.file?.fileUrl}
          />

          <Divider sx={{ margin: '2rem 0' }} />

          <Box sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
            <Link to='/app/indications'>
              <Button startIcon={<IconArrowLeft />} color='secondary'>
                  Voltar
              </Button>
            </Link>

            <Button
              type='submit'
              disabled={loadingFilesUpload || updateIndicationFilesIsLoading || !enableSubmit}
              startIcon={loadingFilesUpload || updateIndicationFilesIsLoading ? <CircularProgress size={24} color='inherit' /> : <IconCheck size={24} />}
            >
              Confirmar
            </Button>
          </Box>

        </form>
      </FormProvider>
    </>
  )
}
