/* eslint-disable no-unused-vars */
import { EAffiliatePartnerUserStatus, ECommissionStatus, EIndicationStatus, EProposalStatus } from '~/graphql/types'
import { theme } from './theme'
import { transparentize } from 'polished'

type StatusColor = {
  background: string,
  color: string
  border?: string
}

const USER_STATUS_COLORS: { [x in EAffiliatePartnerUserStatus]: StatusColor } = {
  pending: {
    color: '#A16F31',
    background: '#FEFCBF'
  },
  active: {
    color: '#367154',
    background: '#C6F6D5'
  },
  inactive: {
    color: '#8c8c8c',
    background: '#e6e6e6'
  }
}

export const colorUserStatus = (status?: EAffiliatePartnerUserStatus) => {
  if (!status) return {
    background: 'transparent',
    color: 'transparent'
  }
  return USER_STATUS_COLORS[status]
}

const REFERRALS_STATUS_COLORS: { [x in EIndicationStatus]: StatusColor } = {
  signedContract: {
    color: '#A16F31',
    background: '#FEFCBF'
  },
  activeContract: {
    color: '#367154',
    background: '#C6F6D5'
  },
  allocateDealership: {
    color: '#367154',
    background: 'transparent',
    border: '1px solid #367154'
  },
  pending: {
    color: '#A16F31',
    background: '#FEFCBF'
  },
  requestedProposal: {
    color: '#A16F31',
    background: '#FEFCBF'
  },
  expiredProposal: {
    color: '#8c8c8c',
    background: '#e6e6e6'
  },
  canceled: {
    color: '#703636',
    background: '#F5C4C4'
  },
  canceledContract: {
    color: '#703636',
    background: '#F5C4C4'
  },
  canceledProposal: {
    color: '#703636',
    background: '#F5C4C4'
  },
}

export const colorIndicationsStatus = (status?: EIndicationStatus) => {
  if (!status) return {
    background: 'transparent',
    color: 'transparent'
  }
  return REFERRALS_STATUS_COLORS[status]
}

const COMISSIONS_STATUS_COLORS: { [x in ECommissionStatus]: StatusColor } = {

  paid: {
    color: '#367154',
    background: '#C6F6D5'
  },
  late: {
    color: '#A16F31',
    background: '#FEFCBF'
  },
  notPaid: {
    color: '#703636',
    background: '#F5C4C4'
  },
  canceled: {
    color: '#8c8c8c',
    background: '#e6e6e6'
  },
  extension: {
    color: '#8c8c8c',
    background: '#e6e6e6'
  }
}

export const colorComissionsStatus = (status?: ECommissionStatus) => {
  if (!status) return {
    background: 'transparent',
    color: 'transparent'
  }
  return COMISSIONS_STATUS_COLORS[status]
}

const PROPOSAL_STATUS_COLORS: { [x in EProposalStatus]: StatusColor } = {
  formalization: {
    color: '#A16F31',
    background: '#FEFCBF'
  },
  finalized: {
    color: '#367154',
    background: '#C6F6D5'
  },
  documentation: {
    color: '#3591E5',
    background: transparentize('0.75', '#3591E5')
  },
  canceled: {
    color: '#BD3535',
    background: 'rgba(239, 68, 68, 0.1)'
  }
}

export function colorProposalStatus(status?: EProposalStatus) {
  if (!status) return {
    background: 'transparent',
    color: 'transparent'
  }
  return PROPOSAL_STATUS_COLORS[status]
}
