import { Box } from '@mui/material'
import { Card, ContentTitle } from '~/components'

export const Rewards: React.FC = () => {
  return (
    <Box sx={{ minHeight: '100vh', height: '100%' }}>
      <Card>
        <ContentTitle
          title='Recompensas'
          style={{ padding: '0px 0px 1.6rem 0px' }}
          breadcrumbLinks={{ currentLink: 'Indicações', links: [{ href: '/app/rewards', label: 'Área do Afiliado' }] }}
        />
      </Card>
    </Box>
  )
}
