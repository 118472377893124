import { useSearchParams } from 'react-router-dom'

export const usePaginationValues = () => {
  const [searchParams] = useSearchParams()

  const page = Number(searchParams.get('page') || '0') //currentPage
  const items = Number(searchParams.get('items')) //documentCounts
  const size = Number(searchParams.get('size') || '20') //pageSize

  return { items, page, size }
}
