/* eslint-disable no-unused-vars */
import { useState } from 'react'
import { MultiFilesWithNoProposalReturn, UploadMultiFilesWithNoProposalParams, uploadMultiFilesWithNoProposal } from '~/services/uploadFile'

type UploadFileWithNoProposalState = {
  loading: boolean
  data: MultiFilesWithNoProposalReturn[] | null
  response: 'success' | 'error' | null
}

export type UseUploadMultiFilesWithNoProposalOutput = [
  uploadMultiFiles: (params: UploadMultiFilesWithNoProposalParams) => Promise<MultiFilesWithNoProposalReturn[] | null>,
  uploadMultiFilesState: UploadFileWithNoProposalState
]
export const useUploadMultiFilesWithNoProposal = (): UseUploadMultiFilesWithNoProposalOutput => {
  const [uploadMultiFilesState, setUploadMultiFileState] = useState<UploadFileWithNoProposalState>({ data: null, loading: false, response: null })

  const uploadMultiFiles = async (params: UploadMultiFilesWithNoProposalParams) => {
    setUploadMultiFileState({ ...uploadMultiFilesState, loading: true })

    try {
      const filesUploaded = await uploadMultiFilesWithNoProposal(params)
      setUploadMultiFileState({
        loading: false,
        response: 'success',
        data: filesUploaded || null
      })

      return filesUploaded
    } catch (err) {
      setUploadMultiFileState({
        loading: false,
        response: 'error',
        data: null
      })

      return null
    }
  }

  return [uploadMultiFiles, uploadMultiFilesState]
}
