import { ChevronRight } from '@mui/icons-material'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'
import { ListItem, ListItemButton, ListItemIcon, ListItemProps, ListItemText, Typography, useMediaQuery } from '@mui/material'
import { theme } from '~/utils'
import { useSidebar } from '~/contexts'

export type SidebarListItemProps = ListItemProps & {
  isDrawerOpen: boolean,
  text: string
  path?: string
  icon: JSX.Element
  hideChevronIcon?: boolean
  toggleCollapsible?: () => void
  isCollapsibleOpen?: boolean
}

export const SidebarListItem: React.FC<SidebarListItemProps> = ({ isDrawerOpen, text, icon, sx, path, hideChevronIcon = false, toggleCollapsible, isCollapsibleOpen, ...props }) => {
  const { pathname } = useLocation()
  const currentPathPattern = matchPath({ path: path || '' }, pathname)
  const matches = Boolean(currentPathPattern)
  const navigate = useNavigate()
  const { toggleSidebar } = useSidebar()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const onClick = () => {
    if (toggleCollapsible) {
      toggleCollapsible()
      return
    }

    navigate(path || '')
    isMobile && toggleSidebar()
  }

  return (
    <ListItem
      onClick={onClick}
      disablePadding sx={{ display: 'block', ...sx }}
      {...props}
    >
      <ListItemButton
        sx={{
          px: 2.5,
          minHeight: 48,
          justifyContent: isDrawerOpen ? 'initial' : 'center',
          borderLeft: matches ? `6px solid ${theme.palette.yellow.main}` : '',
          background: matches ? 'linear-gradient(90deg, rgba(255, 238, 148, 0.25) 0%, rgba(255, 238, 148, 0) 135.87%)' : 'white'
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            justifyContent: 'center',
            mr: isDrawerOpen ? 3 : 'auto',
            ml: matches ? '-6px' : 0,
            color: matches ? theme.palette.yellow.main : theme.palette.black.light
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText primary={<Typography variant='body2' sx={{ fontWeight: matches ? 500 : 'normal' }}>{text}</Typography>} sx={{ opacity: isDrawerOpen ? 1 : 0 }} />
        {isDrawerOpen && !hideChevronIcon && (
          <ChevronRight
            sx={{
              fontSize: '24px',
              transition: 'all .2s ease-in-out',
              transform: isCollapsibleOpen ? 'rotate(90deg)' : 'rotate(0deg)',
              color: matches ? theme.palette.yellow.main : '#E6E6E6'
            }}
          />
        )}
      </ListItemButton>
    </ListItem>
  )
}
