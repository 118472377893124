import { useMemo, useState } from 'react'
import { Box, Button, FormControl, InputAdornment, InputLabel, OutlinedInput, Tooltip } from '@mui/material'
import { IconInfoCircle, IconPencil } from '@tabler/icons-react'
import { Column, useFilters, usePagination, useSortBy, useTable } from 'react-table'
import { useQueryString, useToggle } from '~/hooks'
import { TableApiV2 } from '~/components'
import { useTheme } from '@emotion/react'
import { DealershipCell, UpdateBenefitsModal } from './components'
import { usePaginateDealershipConditionsQuery } from '~/graphql/types'
import { DealershipPartnerData } from '~/contexts/dealershipsPartner/types'
import { toast } from 'react-toastify'

export const BenefitsPercentageTable: React.FC = () => {
  const theme = useTheme()
  const [selectedBenefit, setSelectedBenefit] = useState<DealershipPartnerData | null>(null)
  const [dealershipConditions, setDealershipConditions] = useState<DealershipPartnerData[]>([])
  const { isTrue: updateBenefitsModalIsOpen, setIsTrue: setUpdateBenefitsModalIsOpen } = useToggle(false)
  const { page, size, setSearchParams } = useQueryString()

  const { loading: dealershipIsLoading } = usePaginateDealershipConditionsQuery({
    variables: {
      params: {
        pageSize: size,
        pageNumber: page,
        sort: {
          field: 'createdAt',
          order: -1
        }
      }
    },
    onCompleted(result) {
      setDealershipConditions(result.paginateDealershipConditions.data)
      setSearchParams(state => {
        state.set('items', String(result.paginateDealershipConditions.meta.documentCounts))
        return state
      })
    },
    onError(error) {
      toast.error(error.message)
    },
    fetchPolicy: 'no-cache'
  })

  const columns = useMemo((): Column[] => {
    return [
      {
        Header: 'UF',
        accessor: 'uf',
      },
      {
        Header: 'Distribuidora',
        accessor: 'dealershipName',
        Cell: ({ value }) => <Box sx={{ maxWidth: '200px' }}>{value}</Box>
      },
      {
        Header: 'Benefício mínimo',
        accessor: 'customerDiscountMin',
        Cell: ({ row }: any) => <DealershipCell pfValue={row.original.customerPFDiscountMin as number} pjValue={row.original.customerPJDiscountMin as number} /> 
      },
      {
        Header: 'Benefício máximo',
        accessor: 'customerDiscountMax',
        Cell: ({ row }: any) => <DealershipCell pfValue={row.original.customerPFDiscountMax as number} pjValue={row.original.customerPJDiscountMax as number} /> 
      },
      {
        Header: 'Benefício sugerido',
        accessor: 'customerDiscountSuggested',
        Cell: ({ row }: any) => <DealershipCell pfValue={row.original.customerPFDiscountSuggested as number} pjValue={row.original.customerPJDiscountSuggested as number} /> 
      },
      {
        Header: (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            Benefício padrão
            <Tooltip title='Benefício base oferecido na indicação manual. Se esse campo não for preenchido, o benefício sugerido vai ser adotado.'>
              <IconInfoCircle size={24} color={theme.palette.info.dark} />
            </Tooltip>
          </Box>
        ),
        Cell: ({ row }: any) => {
          return (
            <Box sx={{ display: 'flex', gap: '.6rem', width: '240px' }}>
              <FormControl>
                <InputLabel htmlFor='outlined-adornment-amount'>PF</InputLabel>
                <OutlinedInput
                  disabled={true}
                  value={row.original.customerPFDiscountOffered || 0}
                  label='PF'
                  endAdornment={<InputAdornment position='end'>%</InputAdornment>}
                  name='pf'
                />
              </FormControl>
              <FormControl>
                <InputLabel htmlFor='outlined-adornment-amount'>PJ</InputLabel>
                <OutlinedInput
                  disabled={true}
                  value={row.original.customerPJDiscountOffered || 0}
                  label='PJ'
                  endAdornment={<InputAdornment position='end'>%</InputAdornment>}
                  name='pj'
                />
              </FormControl>
              <Button onClick={() => (setUpdateBenefitsModalIsOpen(true), setSelectedBenefit(row.original))} sx={{ padding: '0' }}>
                <IconPencil size={32} />
              </Button>
            </Box>
          )
        },
        accessor: 'customerDiscountOffered'
      },
    ]
  }, [])

  const dataWithMemo = useMemo(() => dealershipConditions || [], [dealershipConditions])

  const tableMethods = useTable(
    {
      columns,
      data: dataWithMemo,
      manualPagination: true,
      defaultColumn: {
        Filter: <></>,
      },
    },
    useFilters,
    useSortBy,
    usePagination,
  )

  return (
    <>
      <TableApiV2
        isLoading={dealershipIsLoading}
        columns={columns}
        tableMethods={tableMethods}
      />

      <UpdateBenefitsModal
        setDealershipConditions={setDealershipConditions}
        selectedBenefit={selectedBenefit}
        setSelectedBenefit={setSelectedBenefit}
        isVisible={updateBenefitsModalIsOpen}
        onClose={() => setUpdateBenefitsModalIsOpen(false)}
      />
    </>
  )
}
