import React, { useEffect, useState } from 'react'
import { useMediaQuery } from '@mui/material'
import { theme } from '~/utils'
import { useSidebar } from '~/contexts'
import { TSidebarItem } from '~/components/Layout'
import { ResponsiveSidebar, SidebarContent } from './components'

export type SidebarProps = {
  isDrawerOpen: boolean
  sidebarItems: TSidebarItem[]
  toggleSidebar: () => void
}

export type Collapsibles = {}

export const Sidebar: React.FC<SidebarProps> = ({ isDrawerOpen, sidebarItems, toggleSidebar }) => {
  const [collapsibles, setCollapsibles] = useState<Collapsibles>({})
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { setSidebar } = useSidebar()

  const toggleCollapsible = (collapsibleName: keyof Collapsibles) => {
    setCollapsibles(prevState => ({
      ...prevState,
      [collapsibleName]: !prevState[collapsibleName]
    }))
  }

  useEffect(() => {
    isMobile && setSidebar(false)
  }, [isMobile])

  return (
    <>
      {isMobile ? (
        <ResponsiveSidebar
          collapsibles={collapsibles}
          isDrawerOpen={isDrawerOpen}
          sidebarItems={sidebarItems}
          toggleSidebar={toggleSidebar}
          toggleCollapsible={toggleCollapsible}
        />
      ) : (
        <SidebarContent
          collapsibles={collapsibles}
          isDrawerOpen={isDrawerOpen}
          sidebarItems={sidebarItems}
          toggleCollapsible={toggleCollapsible}
        />
      )}
    </>
  )
}
