import React, { useState, useMemo, useCallback, PropsWithChildren, useContext } from 'react'
import { SidebarState } from './types'
import { useMediaQuery } from '@mui/material'
import { theme } from '~/utils'
import DespertaLogo from '~/assets/images/logo/desperta-logo.svg'
import DespertaLogoReduced from '~/assets/images/logo/desperta-logo-reduced.svg'

export const SidebarContext = React.createContext<SidebarState>({} as SidebarState)

export const SidebarProvider: React.FC<PropsWithChildren> = (props) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [isVisible, setIsVisible] = useState(isMobile ? false : true)
  const [openLogo, setOpenLogo] = useState(DespertaLogo)
  const [closeLogo, setCloseLogo] = useState(DespertaLogoReduced)

  const toggleSidebar = useCallback(() => setIsVisible(!isVisible), [isVisible])

  const providerValue = useMemo<SidebarState>(() => ({
    isVisible,
    toggleSidebar,
    setSidebar: setIsVisible,
    closeLogo,
    openLogo,
    setCloseLogo,
    setOpenLogo
  }), [isVisible, toggleSidebar, openLogo, closeLogo])

  return (
    <SidebarContext.Provider value={providerValue}>
      {props.children}
    </SidebarContext.Provider>
  )
}

export const useSidebar = () => useContext(SidebarContext)
