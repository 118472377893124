import styled from '@emotion/styled'
import { ECommissionStatus } from '~/graphql/types'
import { colorComissionsStatus } from '~/utils'

type ContainerProps = {
  status: ECommissionStatus;
}
export const Container = styled.div<ContainerProps>`
  padding: .6rem 0;
  span {
    ${(props) => colorComissionsStatus(props.status)}
    padding: .2rem .6rem;
    border-radius: .4rem;
    font-weight: 600;
    white-space: nowrap;
    font-size: .8rem;
  }
`
