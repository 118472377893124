import { Box, Typography } from '@mui/material'
import { Card, ContentTitle } from '~/components'
import { ProfilePicture, Permissions, PictureCroped } from './components'
import { GridColumn } from '~/styles'
import { useTheme } from '@emotion/react'
import { useAuth } from '~/contexts'
import { Navigate } from 'react-router-dom'

export const GeneralSettings: React.FC = () => {
  const { authUser } = useAuth()
  
  if(!authUser?.owner) {
    return  <Navigate to='/app/' />
  }

  const theme = useTheme()

  return (
    <Box sx={{ minHeight: '100vh', height: '100%' }}>
      <Card gap={5}>
        <ContentTitle
          title='Configurações gerais'
          style={{ padding: 0 }}
          description='Gestão do parceiro e usuários'
          breadcrumbLinks={{ currentLink: 'Geral', links: [{ href: '/app/settings', label: 'Configurações' }] }}
        />

        <ProfilePicture />
        
        <Box>
          <Typography sx={{ fontWeight: 500, paddingBottom: '1rem' }} variant='h3'>Marca exibida na plataforma</Typography>
          <GridColumn columns={2} responsive={[{ breakWidth: theme.breakpoints.values.md, columns: 1 }]}>
            <PictureCroped typeLogo='defaultLogo' />
            <PictureCroped typeLogo='reducedLogo' />
          </GridColumn>
        </Box>

        <Permissions />
      </Card>
    </Box>
  )
}
