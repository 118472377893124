/* eslint-disable no-unused-vars */
import { createTheme, Theme as MuiTheme, PaletteColorOptions } from '@mui/material/styles'
import { darken, lighten } from 'polished'

declare module '@mui/material/styles' {
  interface Palette {
    white: Palette['primary']
    black: Palette['primary']
    yellow: Palette['primary']
    purple: Palette['primary']
    tertiary: Palette['primary']
  }

  interface PaletteOptions {
    white: PaletteOptions['primary']
    black: PaletteOptions['primary']
    yellow: PaletteOptions['primary']
    purple: PaletteOptions['primary']
    tertiary: PaletteOptions['primary']
  }

  interface PaletteColor {
    darker?: string
    100?: string
    200?: string
    300?: string
    400?: string
    500?: string
    600?: string
    700?: string
    800?: string
    900?: string
  }

  interface SimplePaletteColorOptions {
    darker?: string
  }

  interface TypographyVariants {
    body3: React.CSSProperties
  }

  interface TypographyVariantsOptions {
    body3?: React.CSSProperties
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true
  }
}

declare module '@emotion/styled' {
  export interface Theme extends MuiTheme {}
}

declare module '@emotion/react' {
  export interface Theme extends MuiTheme {}
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    purple: true;
    tertiary: true;
  }
}

export const theme = createTheme({
  
  palette: {
    purple: {
      main: '#561885',
    },
    white: {
      main: '#FFFFFF'
    },
    black: {
      light: '#434343',
      main: '#292824',
      dark: '#231F20'
    },
    yellow: {
      100: '#fffdf1',
      light: '#FFEE94',
      main: '#F9DC45',
      dark: '#F3CC04',
      darker: '#E7BE00'
    },
    primary: {
      light: '#FFF7D0',
      main: '#F9DC45',
    },
    secondary: {
      light: '#f2edf6',
      main: '#561885'
    },
    tertiary: {
      light: '#E6E6E6',
      main: '#999'
    },
    grey: {
      100: '#FAFAFA',
      200: '#F7F8EF',
      300: '#E6E6E6',
      400: '#999999',
      700: '#434343'
    },
    success: {
      main: '#00A86B',
      light: '#DBFEE3',
      100: '#f2faf6'
    },
    info: {
      main: '#3ba1ff1a',
      dark: '#3BA1FF'
    },
    error: {
      main: '#EF4444',
      light: '#fdecec'
    }
  },
  typography: {
    h1: {
      fontSize: 40,
      fontWeight: 'normal'
    },
    h2: {
      fontSize: 32,
      fontWeight: 'normal'
    },
    h3: {
      fontSize: 24,
      fontWeight: 'normal'
    },
    h4: {
      fontSize: 20,
      fontWeight: 'normal'
    },
    h5: {
      fontSize: 18,
      fontWeight: 'normal'
    },
    body1: {
      fontSize: 16,
      fontWeight: 'normal'
    },
    body2: {
      fontSize: 14,
      fontWeight: 'normal'
    },
    body3: {
      fontSize: 12,
      fontWeight: 'normal'
    }
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body3: 'p'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input.Mui-disabled': {
            cursor: 'not-allowed !important',
            pointerEvents: 'all !important'
          },
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          borderRadius: 8,
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          textTransform: 'none',
          padding: '.6rem 1.6rem',
          borderRadius: '.6rem',
          ':disabled': {
            cursor: 'not-allowed',
            pointerEvents: 'all !important'
          },
        }
      },
      variants: [
        {
          props: { color: 'purple' },
          style: {
            color: '#fff',
            background: '#561885',
            fontWeight: 600,
            ':hover': {
              background: lighten('0.1', '#561885'),
            },
            ':focus': {
              background: darken('0.1', '#561885'),
            },
            ':disabled': {
              background: '#E6E6E6',
              color: '#999999',
            },
          }
        },
        {
          props: { color: 'primary' },
          style: {
            color: '#434343',
            background: '#F9DC45',
            fontWeight: 600,
            ':hover': {
              background: '#F3CC04',
            },
            ':focus': {
              background: '#E7BE00',
            },
            ':disabled': {
              background: '#E6E6E6',
              color: '#999999',
            },
          }
        },
        {
          props: { color: 'secondary' },
          style: {
            background: 'transparent',
            border: '2px solid #231F20',
            color: '#231F20',
            fontWeight: 600,
            ':hover': {
              background: '#F7F8EF',
            },
            ':focus': {
              background: '#E6E6E6',
            },
            ':disabled': {
              background: 'transparent',
              color: '#999999',
              border: '2px solid #999999',
            },
          },
        },
        {
          props: { color: 'info', variant: 'contained' },
          style: {
            background: '#3BA1FF',
            border: 'none',
            color: '#E6E6E6',
            fontWeight: 700,
            ':hover': {
              background: darken('0.08', '#3BA1FF'),
            },
            ':focus': {
              background: darken('0.08', '#3BA1FF'),
            },
            ':disabled': {
              background: 'transparent',
              color: '#999999',
            },
          }
        },
        {
          props: { color: 'error' },
          style: {
            background: 'transparent',
            border: '2px solid #EF4444',
            color: '#EF4444',
            fontWeight: 700,
            ':hover': {
              background: '#FDECEC',
            },
            ':focus': {
              background: '#E6E6E6',
            },
            ':disabled': {
              background: 'transparent',
              color: '#999999',
              border: '2px solid #999999',
            },
          }
        },
        {
          props: { color: 'error', variant: 'contained' },
          style: {
            background: '#EF4444',
            border: 'none',
            color: '#E6E6E6',
            fontWeight: 700,
            ':hover': {
              background: darken('0.08', '#EF4444'),
            },
            ':focus': {
              background: darken('0.08', '#EF4444'),
            },
            ':disabled': {
              background: 'transparent',
              color: '#999999',
            },
          }
        },
        { 
          props: { size: 'small' }, 
          style: {
            padding: '.4rem 1.2rem',
            fontWeight: 500,
            fontSize: '14px'
          }
        }
      ],
      defaultProps: {
        disableElevation: true
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          paddingLeft: 0
        }
      }
    }
  }
})
