import { Box, Button, Divider, IconButton, TextField, Typography, useMediaQuery } from '@mui/material'
import { IconCopy, IconKey, IconRefresh } from '@tabler/icons-react'
import { theme } from '~/utils'
import { Card } from '~/components'
import { useToggle } from '~/hooks'
import { ApiKeyRedefinitionModal } from './components'
import { usePartner } from '~/contexts/partner'
import { copyToClipboard } from '~/utils/clipboard'

export const ApiKeySectionCard: React.FC = () => {
  const { myPartner } = usePartner()
  const { isTrue, toggle } = useToggle()
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      <Card style={{
        padding: '24px',
        flexFlow: isLowerSm ? 'column' : 'row',
        gap: '28px',
        borderLeft: `6px solid ${theme.palette.yellow.light}`,
      }} >
        <Box sx={{
          display: 'flex',
          borderRadius: '50%',
          height: 40,
          width: 40,
          padding: 1,
          background: 'rgba(86, 24, 133, 0.06)'
        }}>
          <IconKey size={24} color='#9E78BC' />
        </Box>

        <Box sx={{
          display: 'flex',
          flexFlow: 'column',
          paddingTop: '8px',
          gap: 3,
          flexGrow: 1
        }}>
          <Typography fontWeight={500} variant={isLowerSm ? 'body2' : 'body1'}>
            Chave API
          </Typography>
          <Typography color='grey.400' variant={isLowerSm ? 'body2' : 'body1'}>
            Utilize o token de segurança na aplicação desejada para garantirmos a autenticidade das informações compartilhadas.
          </Typography>

          <Divider />

          <Typography fontWeight={500} variant={isLowerSm ? 'body2' : 'body1'}>
            Chave <span style={{ color: theme.palette.grey['400'] }}>(Token)</span>
          </Typography>

          <Box sx={{
            display: 'flex',
            gap: 3,
            flexFlow: isLowerSm ? 'column' : 'row',
            alignItems: 'center'
          }}>
            <TextField
              sx={{
                display: 'flex',
                flexGrow: 1
              }}
              disabled
              size='medium'
              value={myPartner?.configuration.apiKey}
              InputProps={{
                sx: {
                  background: theme.palette.grey['100'],
                },
                endAdornment: <IconButton><IconCopy onClick={() => copyToClipboard(myPartner?.configuration.apiKey || '')} size={24} /></IconButton>
              }}
            />
            <Button onClick={toggle} size={isLowerSm ? 'small' : 'medium'} startIcon={<IconRefresh size={24} />} color='secondary'>Redefinir chave API</Button>
          </Box>
        </Box>
      </Card>

      <ApiKeyRedefinitionModal isVisible={isTrue} onClose={toggle} />
    </>
  )
}
