import { Button, Typography, useMediaQuery } from '@mui/material'
import { IconGift, IconPlus, IconSolarPanel } from '@tabler/icons-react'
import { useNavigate } from 'react-router-dom'
import { ManWithForm } from '~/assets/illustrations'
import { Banner } from '~/components'
import { usePartner } from '~/contexts/partner'
import { theme } from '~/utils'

export const RequestProposalBanner: React.FC = () => {
  const navigate = useNavigate()
  const { myPartner } = usePartner()
  const isLowerLg = useMediaQuery(theme.breakpoints.down('lg'))

  return (
    <Banner
      minHeight={0}
      title={<Typography variant='h3' fontWeight={500}>Solicite proposta para seus clientes</Typography>}
      description={<Typography variant='h5'>Faça uma indicação manual solicitando proposta</Typography>}
      category={{
        icon: null,
        color: theme.palette.purple.main,
        text: 'Dificuldades com o link de indicação?'
      }}
      iconTextList={[
        {
          icon: <IconGift size={30} color='#9E78BC' />,
          text: <span>Recompensas por contato fechado.</span>
        },
        {
          icon: <IconSolarPanel size={30} color='#9E78BC' />,
          text: <span>Contribua para rede de consumo consciente.</span>
        }
      ]}
      buttonsContainer={myPartner?.configuration.allowManualProposal ? <Button onClick={() => navigate('/app/indications/create')} color='purple' sx={{ alignSelf: 'start' }} endIcon={<IconPlus size={20} />}>Solicitar proposta</Button> : <></>}
      rightElement={isLowerLg ? null : <ManWithForm style={{ width: 332, height: 281 }} />}
    />
  )
}
