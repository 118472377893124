import { MutableRefObject, useEffect } from 'react'

/**
 * @param ref react ref
 * @param callback function to execute if click outside ref.
 * @example useOnClickOutside(ElementRef, () => toggle())
 */
export const useOnClickOutside = (ref: MutableRefObject<any>, callback: Function) => {
  useEffect(
    () => {
      const listener = (event: MouseEvent | TouchEvent) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }
        callback(event)
      }
      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)
      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    },
    [ref, callback]
  )
}
