import { confirmable, ConfirmDialogProps } from 'react-confirm'
import { Box, Button, Divider, ButtonProps } from '@mui/material'
import { Dialog } from '../Dialog'
import { ThemeDialog } from './themeDialog'
import { IconAlertCircleFilled, IconInfoCircleFilled, IconQuestionMark } from '@tabler/icons-react'
import { theme } from '~/utils'

type DialogType = 'warning' | 'info' | 'error'

type ButtonStyle = {
  variant: ButtonProps['variant'],
  color: ButtonProps['color'],
}

type ButtonType = {
  proceed: ButtonStyle,
  cancel: ButtonStyle,
}

export type MyDialogProps = {
  title: string
  content?: JSX.Element
  proccedText?: string
  cancelText?: string
  type?: DialogType
}

// eslint-disable-next-line
const IconsTitle: { [x in DialogType]: JSX.Element } = {
  warning: <Box sx={{ 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', 
    width: '24px', 
    height: '24px', 
    borderRadius: '50%', 
    background: theme.palette.yellow.dark 
  }}>
    <IconQuestionMark size={16} color='#fff' />
  </Box>,
  info: <IconInfoCircleFilled size={24} style={{ color: theme.palette.info.dark }} />,
  error: <IconAlertCircleFilled size={24} style={{ color: theme.palette.error.main }} />,
}

// eslint-disable-next-line
const colorButtons: { [x in DialogType]: ButtonType } = {
  warning: {
    cancel: {
      color: 'secondary',
      variant: 'contained'
    },
    proceed: {
      color: 'primary',
      variant: 'contained'
    },
  },
  info: {
    cancel: {
      color: 'secondary',
      variant: 'contained'
    },
    proceed: {
      color: 'info',
      variant: 'contained'
    },
  },
  error: {
    cancel: {
      color: 'secondary',
      variant: 'contained'
    },
    proceed: {
      color: 'error',
      variant: 'contained'
    },
  },
}

const pickIconTitle = (type?: DialogType) => {
  if(!type) {
    return undefined
  }
  return IconsTitle[type]
}

const pickColorButtons = (type?: DialogType) => {
  if(!type) {
    return {
      cancel: {
        color: 'secondary',
        variant: 'contained'
      },
      proceed: {
        color: 'primary',
        variant: 'contained'
      },
    } as ButtonType
  }

  return colorButtons[type]
}

const MyDialog: React.FC<ConfirmDialogProps<MyDialogProps, boolean>> = ({ 
  show, 
  proceed, 
  title, 
  cancelText = 'Cancelar', 
  proccedText = 'Continuar',
  type,
  content
}) => {
  const selectedStyledButton = pickColorButtons(type)

  return (
    <ThemeDialog>
      <Dialog titleIcon={pickIconTitle(type)} title={title} onClose={() => proceed(false)} isVisible={show}>
        {content && (
          <>
            <Divider />
            <Box>
              {content}
            </Box>
          </>
        )}
        <Divider />
        <Box sx={{ paddingTop: '1.4rem', display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
          <Button variant={selectedStyledButton.cancel.variant} color={selectedStyledButton.cancel.color} onClick={() => proceed(false)}>{cancelText}</Button>
          <Button variant={selectedStyledButton.proceed.variant} color={selectedStyledButton.proceed.color} onClick={() => proceed(true)}>{proccedText}</Button>
        </Box>
      </Dialog>
    </ThemeDialog>
  )
}

export default confirmable(MyDialog)
