import './utils/yup'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { App } from './App'
import { ApolloProvider } from '@apollo/client'
import { client } from './services/graphql'
import * as Sentry from '@sentry/react'
import { theme } from './utils/theme'
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from './components/ErrorFallback'
import { SidebarProvider } from './contexts'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from '~/contexts'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { PartnerProvider } from './contexts/partner'
import { clarity } from 'react-microsoft-clarity'
import TagManager from 'react-gtm-module'

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DNS,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/parceiro.despertaenergia.\.com/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

if (import.meta.env.VITE_APP_ENV === 'production') {
  clarity.init('mqeg80cq6j')
}
const tagManagerArgs = {
  gtmId: 'G-8KC63EV2RG'
}
if (import.meta.env.VITE_APP_ENV === 'production') {
  TagManager.initialize(tagManagerArgs)
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <StyledEngineProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            <SidebarProvider>
              <AuthProvider>
                <PartnerProvider>
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <App />
                    <ToastContainer />
                  </ErrorBoundary>
                </PartnerProvider>
              </AuthProvider>
            </SidebarProvider>
          </BrowserRouter>
        </ThemeProvider>
      </StyledEngineProvider>
    </ApolloProvider>
  </React.StrictMode>,
)
