import { Box } from '@mui/material'
import { useAuth } from '~/contexts'
import { PartnerIndications, SubAffiliateIndications } from './components'

export const Indications: React.FC = () => {
  const { authUser } = useAuth()
  const isOwner = authUser?.owner

  return (
    <Box sx={{ minHeight: '100vh', height: '100%' }}>
      {isOwner ? <PartnerIndications /> : <SubAffiliateIndications />}
    </Box>
  )
}
