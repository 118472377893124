import React from 'react'
import { Box, Typography, Button } from '@mui/material'
import { SentimentVeryDissatisfied } from '@mui/icons-material'
import { theme } from '~/utils'

export const ErrorFallback: React.FC = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: 3
      }}
    >
      <SentimentVeryDissatisfied htmlColor={theme.palette.error.main} sx={{ fontSize: '120px' }} />
      <Typography>Oops! Alguma coisa deu errado!</Typography>
      <Button variant='contained' onClick={() => window.location.assign(window.location.origin)}>
        Voltar para a Home
      </Button>
    </Box>
  )
}
