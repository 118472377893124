import { useState } from 'react'
import { toast } from 'react-toastify'
import { restApi } from '~/services/api'
import { clearString } from '~/utils'

type ViaCepResponse = {
  cep: string,
  logradouro: string,
  complemento: string,
  bairro: string,
  localidade: string,
  uf: string,
  ibge: string,
  gia: string,
  ddd: string,
}

type ResponseFetchCep = {
  isSuccess: boolean
  data: null | ViaCepResponse
}

type useCepData = {
  showErrorMessage?: boolean
}

export const useCep = (options?: useCepData) => {
  const [isLoading, setIsLoading] = useState(false)
  
  const fetchCep = async (cep: string): Promise<ResponseFetchCep> => {
    const formatedCep = clearString(cep)
    setIsLoading(true)
    try {
      const fetchResponse = await restApi.get(`https://viacep.com.br/ws/${formatedCep}/json/`)
      if(fetchResponse?.data.erro === true && options?.showErrorMessage) {
        toast.error('Erro ao buscar o CEP informado.')
        return { isSuccess: false, data: null }
      }
      return { isSuccess: true, data: fetchResponse.data }
    } catch(err) {
      if(options?.showErrorMessage) {
        toast.error('Erro ao buscar o CEP informado.')
      }
      return { isSuccess: false, data: null }
    } finally {
      setIsLoading(false)
    }
  }

  return { fetchCep, isLoading }
}
