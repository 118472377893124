import { EAffiliatePartnerUserStatus, EBankAccountType, ECommissionStatus, EConsumerClassification, ECustomerPersonType, ECustomerTension, EIndicationOrigin, EIndicationStatus, EMaritalStatus, ETariffClassification } from '~/graphql/types'
import { translateComissionsStatus, translateIndicationsOriginStatus, translateIndicationsStatus } from './translate'

export type Option = {
  label: string,
  value: string
}

export const defaultOption: Option = {
  value: '', label: ''
}

export const personTypeOptions: Option[] = [
  { value: ECustomerPersonType.pf, label: 'Pessoa Física' },
  { value: ECustomerPersonType.pj, label: 'Pessoa Jurídica' }
]

export const maritalStatusOptions: Option[] = [
  { value: EMaritalStatus.married, label: 'Casado(a)' },
  { value: EMaritalStatus.single, label: 'Solteiro(a)' },
  { value: EMaritalStatus.divorced, label: 'Divorciado(a)' },
  { value: EMaritalStatus.widowed, label: 'Viúvo(a)' }
]

export const tariffClassificationOptions: Option[] = [
  { value: ETariffClassification.A4, label: 'A4' },
  { value: ETariffClassification.B1, label: 'B1' },
  { value: ETariffClassification.B2, label: 'B2' },
  { value: ETariffClassification.B3, label: 'B3' }
]

export const customerTensionOptions: Option[] = [
  { value: ECustomerTension.monofasico, label: 'Monofásico' },
  { value: ECustomerTension.bifasico, label: 'Bifásico' },
  { value: ECustomerTension.trifasico, label: 'Trifásico' }
]

export const getConsumerClassificationOptions = (tariffClassification: ETariffClassification): Option[] => {
  switch (tariffClassification) {
  case ETariffClassification.A4:
    return [
      { value: EConsumerClassification.industrial, label: 'Industrial' }
    ]
  case ETariffClassification.B1:
    return [
      { value: EConsumerClassification.residencial, label: 'Residencial' }
    ]
  case ETariffClassification.B2:
    return [
      { value: EConsumerClassification.rural, label: 'Rural' }
    ]
  case ETariffClassification.B3:
    return [
      { value: EConsumerClassification.comercial, label: 'Comercial' },
      { value: EConsumerClassification.industrial, label: 'Industrial' }
    ]
  default:
    return [defaultOption]
  }
}

export const bankAccountTypeOptions: Option[] = [
  { value: EBankAccountType.cc, label: 'Conta corrente' },
  { value: EBankAccountType.cp, label: 'Poupança' }
]

export const accountOwnerOptions: Option[] = [
  { value: 'company', label: 'Empresa' },
  { value: 'legalRepresentative', label: 'Representante legal' }
]

export const userStatusOptions: Option[] = [
  { value: EAffiliatePartnerUserStatus.active, label: 'Ativo' },
  { value: EAffiliatePartnerUserStatus.inactive, label: 'Inativo' },
  { value: EAffiliatePartnerUserStatus.pending, label: 'Pendente' }
]

export const indicationsStatusOptions: Option[] = [
  { value: EIndicationStatus.activeContract, label: translateIndicationsStatus(EIndicationStatus.activeContract) },
  { value: EIndicationStatus.allocateDealership, label: translateIndicationsStatus(EIndicationStatus.allocateDealership) },
  { value: EIndicationStatus.canceled, label: translateIndicationsStatus(EIndicationStatus.canceled) },
  { value: EIndicationStatus.canceledContract, label: translateIndicationsStatus(EIndicationStatus.canceledContract) },
  { value: EIndicationStatus.canceledProposal, label: translateIndicationsStatus(EIndicationStatus.canceledProposal) },
  { value: EIndicationStatus.expiredProposal, label: translateIndicationsStatus(EIndicationStatus.expiredProposal) },
  { value: EIndicationStatus.pending, label: translateIndicationsStatus(EIndicationStatus.pending) },
  { value: EIndicationStatus.requestedProposal, label: translateIndicationsStatus(EIndicationStatus.requestedProposal) },
  { value: EIndicationStatus.signedContract, label: translateIndicationsStatus(EIndicationStatus.signedContract) },
]

export const indicationOriginStatusOptions: Option[] = [
  { value: EIndicationOrigin.api, label: translateIndicationsOriginStatus(EIndicationOrigin.api) },
  { value: EIndicationOrigin.link, label: translateIndicationsOriginStatus(EIndicationOrigin.link) },
  { value: EIndicationOrigin.partnerArea, label: translateIndicationsOriginStatus(EIndicationOrigin.partnerArea) },
]

export const comissionStatusOptions: Option[] = [
  { value: ECommissionStatus.canceled, label: translateComissionsStatus(ECommissionStatus.canceled) },
  { value: ECommissionStatus.extension, label: translateComissionsStatus(ECommissionStatus.extension) },
  { value: ECommissionStatus.late, label: translateComissionsStatus(ECommissionStatus.late) },
  { value: ECommissionStatus.notPaid, label: translateComissionsStatus(ECommissionStatus.notPaid) },
  { value: ECommissionStatus.paid, label: translateComissionsStatus(ECommissionStatus.paid) },
]

