import styled from '@emotion/styled'
import { theme } from '~/utils'

export const DateRangeWrapper = styled.div`
  display: flex;
  gap: .6rem;
  flex-direction: row;
  align-items: center;
  position: relative;
  cursor: pointer;

  .rdrDateRangePickerWrapper {
    position: absolute;
    top: 0;
    right: 0;

    @media(max-width: ${theme.breakpoints.values.md}px) {
      left: 0;
      right: unset;

      .rdrDefinedRangesWrapper {
        display: none;
      }
    }
  }
`
