import { useTheme } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, CircularProgress, Divider, Grid, IconButton, Typography } from '@mui/material'
import { IconArrowLeft, IconCheck, IconSearch } from '@tabler/icons-react'
import { useCallback, useEffect } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { Input, Select } from '~/components'
import { brazilStates } from '~/constants'
import { useCep } from '~/hooks'
import { addressSchema } from '~/utils/yupSchema'
import * as yup from  'yup'
import { useIndicationFlow } from '~/contexts'
import { useGetProposalAndIndicationByIdLazyQuery, useUpdateIndicationMutation } from '~/graphql/types'
import { clearString } from '~/utils'
import { toast } from 'react-toastify'
import { AddressPendency, hasPendencyOrNullOnAddress } from '../utils'
import { Link } from 'react-router-dom'

type FormData = {
  address: {
    zipcode: string
    state: string
    city: string
    neighborhood: string
    street: string
    number: string
    complement?: string
  }
}

const schema = yup
  .object({
    address: addressSchema
  })
  .required()

export const Address: React.FC = () => {
  const theme = useTheme()
  const { selectedIndication, setSelectedIndication, selectedProposal, setSelectedProposal } = useIndicationFlow()
  const formMethods = useForm<FormData>({
    resolver: yupResolver(schema)
  })
  const { address } = useWatch({ control: formMethods.control })
  const { isLoading: fetchCepIsLoading, fetchCep } = useCep({ showErrorMessage: true })

  const onSearchCep = useCallback(async () => {
    if (address?.zipcode) {
      const { data, isSuccess } = await fetchCep(address.zipcode)
      if (isSuccess) {
        formMethods.setValue('address.state', data?.uf || '')
        formMethods.setValue('address.city', data?.localidade || '')
        formMethods.setValue('address.neighborhood', data?.bairro || '')
        formMethods.setValue('address.street', data?.logradouro || '')
      }
    }
  }, [address])

  useEffect(() => {
    if(selectedProposal) {
      formMethods.setValue('address.zipcode', selectedProposal.customerRef.address?.zipcode || '')
      formMethods.setValue('address.state', selectedProposal.customerRef.address?.state || '')
      formMethods.setValue('address.city', selectedProposal.customerRef.address?.city || '')
      formMethods.setValue('address.neighborhood', selectedProposal.customerRef.address?.neighborhood || '')
      formMethods.setValue('address.street', selectedProposal.customerRef.address?.street || '')
      formMethods.setValue('address.number', selectedProposal.customerRef.address?.number || '')
      formMethods.setValue('address.complement', selectedProposal.customerRef.address?.complement || '')

      const fieldsToSetError: AddressPendency[] = ['city', 'complement', 'neighborhood', 'number', 'state', 'street', 'zipcode']

      fieldsToSetError.map(item => {
        if(selectedProposal?.customerRef?.pendencies?.[item]?.rejectedAt) {
          formMethods.setError(`address.${item}`, { message: 'Este campo foi reprovado por um analista!' })
        }
      })
    }
  }, [])

  const [getProposalAndIndicationById, { loading: getProposalAndIndicationByIdIsLoading }] = useGetProposalAndIndicationByIdLazyQuery({
    onCompleted(data) {
      if(data.getIndicationProposalById._id && data.getIndicationById._id) {
        setSelectedIndication(data.getIndicationById)
        setSelectedProposal(data.getIndicationProposalById)
        toast.success('Endereço salvo com sucesso!')
      }
    },
  })

  const [updateAddressIndication, { loading: updateAddressIndicationIsLoading }] = useUpdateIndicationMutation({
    onError(error) {
      toast.error(error.message) 
    },
  })

  const handleSubmit = useCallback(async (data: FormData) => {
   
    await updateAddressIndication({
      variables: {
        params: {
          id: selectedIndication?._id || '',
          address: {
            country: 'Brasil',
            zipcode: clearString(data.address.zipcode),
            city: data.address.city,
            neighborhood: data.address.neighborhood,
            number: data.address.number,
            state: data.address.state,
            street: data.address.street,
            complement: data.address.complement
          }
        }
      }
    })

    await getProposalAndIndicationById({ variables: { id: selectedIndication?._id || '' } })
  }, [selectedIndication, selectedProposal])

  const enableSubmit = 
    clearString(address?.zipcode || '') !== clearString(selectedProposal?.customerRef.address?.zipcode || '') ||
    address?.state !== selectedProposal?.customerRef.address?.state ||
    address?.city !== selectedProposal?.customerRef.address?.city ||
    address?.neighborhood !== selectedProposal?.customerRef.address?.neighborhood ||
    address?.street !== selectedProposal?.customerRef.address?.street ||
    address?.number !== selectedProposal?.customerRef.address?.number ||
    address?.complement !== selectedProposal?.customerRef.address?.complement

  return (
    <>
      <FormProvider {...formMethods}>

        <form onSubmit={formMethods.handleSubmit((data) => handleSubmit(data))}>
          <Typography sx={{ paddingBottom: '1rem' }} variant='h3' fontWeight={500} color={theme.palette.grey[800]}>Endereço do cliente</Typography>

          <Grid container spacing={3}>
            <Grid xs={12} md={4} item>
              <Input
                icons={{ 
                  end: { element: fetchCepIsLoading ?
                    <CircularProgress size={18} color='inherit' /> :
                    <IconButton disabled={hasPendencyOrNullOnAddress('zipcode', selectedProposal?.customerRef.pendencies)} onClick={() => onSearchCep()}><IconSearch /></IconButton> }
                }}
                mask='cep' 
                name='address.zipcode' 
                label='CEP'
                disabled={hasPendencyOrNullOnAddress('zipcode', selectedProposal?.customerRef.pendencies)}
              />
            </Grid>

            <Grid item xs={12} md={4} sx={{ paddingTop: '0 !important' }}></Grid>
            <Grid item xs={12} md={4} sx={{ paddingTop: '0 !important' }}></Grid>

            <Grid item xs={12} md={4}>
              <Input disabled={hasPendencyOrNullOnAddress('street', selectedProposal?.customerRef.pendencies)} name='address.street' label='Rua' />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input disabled={hasPendencyOrNullOnAddress('number', selectedProposal?.customerRef.pendencies)} name='address.number' label='Número' />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input disabled={hasPendencyOrNullOnAddress('complement', selectedProposal?.customerRef.pendencies)} name='address.complement' label='Complemento (Opcional)' />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input disabled={hasPendencyOrNullOnAddress('neighborhood', selectedProposal?.customerRef.pendencies)} name='address.neighborhood' label='Bairro' />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input disabled={hasPendencyOrNullOnAddress('city', selectedProposal?.customerRef.pendencies)} name='address.city' label='Cidade' />
            </Grid>
            <Grid item xs={12} md={4}>
              <Select disabled={hasPendencyOrNullOnAddress('state', selectedProposal?.customerRef.pendencies)} name='address.state' label='UF' options={brazilStates} />
            </Grid>
          </Grid>

          <Divider sx={{ margin: '2rem 0' }} />

          <Box sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
            <Link to='/app/indications'>
              <Button startIcon={<IconArrowLeft />} color='secondary'>
                  Voltar
              </Button>
            </Link>

            <Button
              disabled={!enableSubmit || updateAddressIndicationIsLoading || getProposalAndIndicationByIdIsLoading}
              type='submit'
              startIcon={updateAddressIndicationIsLoading || getProposalAndIndicationByIdIsLoading ? <CircularProgress size={24} color='inherit' /> : <IconCheck size={24} />}
            >
              Confirmar
            </Button>
          </Box>

        </form>
      </FormProvider>
    </>
  )
}
