import { useTheme } from '@emotion/react'
import { Box, Typography } from '@mui/material'

type DealershipCellProps = {
  pfValue: number
  pjValue: number
}

export const DealershipCell: React.FC<DealershipCellProps> = ({ pfValue, pjValue }) => {
  const theme = useTheme()

  return (
    <Box sx={{ display: 'flex', gap: '.6rem' }}>
      <Box sx={{ display: 'flex', gap: '.2rem', alignItems: 'center' }}>
        <Typography style={{ fontSize: '14px', color: theme.palette.grey[400], fontWeight: 600 }}>PF:</Typography>
        <Typography style={{ fontSize: '14px', color: theme.palette.grey[800] }}>{pfValue}%</Typography>
        
      </Box>
      <Box sx={{ height: '16px', width: '1px', background: theme.palette.grey[300] }} />
      <Box sx={{ display: 'flex', gap: '.2rem', alignItems: 'center' }}>
        <Typography style={{ fontSize: '14px', color: theme.palette.grey[400], fontWeight: 600 }}>PJ:</Typography>
        <Typography style={{ fontSize: '14px', color: theme.palette.grey[800] }}>{pjValue}%</Typography>
      </Box>
    </Box>
  )
}
