import styled from '@emotion/styled'

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  @media(max-width: 400px) {
    gap: .6rem;
  }
`
