import { Container } from './styles'
import { descriptionByIndicationStatus, translateIndicationsStatus } from '~/utils'
import { EIndicationStatus } from '~/graphql/types'
import { Tooltip } from '@mui/material'

type IndicationsStatusProps = {
  status: EIndicationStatus
  errorMessage?: string
}

export const IndicationsStatus: React.FC<IndicationsStatusProps> = ({ status, errorMessage }) => {
  const isCanceled = status === EIndicationStatus.canceled
  const tooltipText = isCanceled ? errorMessage : descriptionByIndicationStatus(status)

  return (
    <Tooltip title={tooltipText}>
      <Container status={status || EIndicationStatus.pending}>
        <span>{translateIndicationsStatus(status)}</span>
      </Container>
    </Tooltip>
  )
}
