import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

export type SearchQueysData = {
  key: string,
  defaultValue?: any
  parseValue?: boolean
}

export const useQueryString = () => { 
  const [searchParams, setSearchParams] = useSearchParams()

  const searchPage = searchParams.get('page')
  const searchItems = searchParams.get('items')
  const searchSize = searchParams.get('size')

  const page = Number(searchPage || '0') //currentPage
  const items = Number(searchItems) //documentCounts
  const size = Number(searchSize || '20') //pageSize

  const paginationQuerySize = [searchPage, searchItems, searchSize].filter(item => Boolean(item)).length

  const searchQuerys = useCallback((querys: SearchQueysData[]) => {

    const data = querys.map(({ key, defaultValue, parseValue }) => {
      let value = searchParams.get(key) || defaultValue
      
      if(parseValue && value) {
        value = value.length > 0 && !Array.isArray(value) ? JSON.parse(value) : defaultValue
      }
      
      return {
        key,
        value
      }
    })

    const formatedData = Object.fromEntries(data.map((item) => [item.key, item.value]))
    
    return formatedData
  }, [searchParams])

  return { searchQuerys, searchParams, setSearchParams, paginationQuerySize, page, size, items }
}
