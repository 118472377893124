import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Divider, useMediaQuery, useTheme } from '@mui/material'
import { IconCheck } from '@tabler/icons-react'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { Card, ContentTitle, InfoBanner, Input, Radio } from '~/components'
import { FullPageLoader } from '~/components/FullPageLoader'
import { useUsers } from '~/contexts'
import { UserData } from '~/contexts/users/types'
import { AffiliatePartnerUserCreateInput, AffiliatePartnerUserUpdateInput, EAffiliatePartnerUserStatus, useAffiliatePartnerUserGetQuery, useCreateAffiliatePartnerUserMutation, useUpdateAffiliatePartnerUserMutation } from '~/graphql/types'
import { userStatusOptions } from '~/utils/options'
import { phoneSchema } from '~/utils/yupSchema'

type FormData = {
  name: string,
  email: string,
  phone: string,
  status: EAffiliatePartnerUserStatus,
}

const schema = yup
  .object({
    name: yup.string().required(),
    email: yup.string().email().required(),
    phone: phoneSchema,
    status: yup.string(),
  })
  .required()

export const UsersCreateEdit: React.FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const theme = useTheme()
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const { setSelectedUser, selectedUser, setUsers } = useUsers()

  const formMethods = useForm<FormData>({
    resolver: yupResolver(schema)
  })

  const { loading: getAffiliatePartnerUserIsLoading } = useAffiliatePartnerUserGetQuery({
    variables: {
      id: id || ''
    },
    skip: Boolean(!id),
    onCompleted(data) {
      setSelectedUser(data.affiliatePartnerUserGet as UserData)
    },
    onError(error) {
      toast.error(error.message)
    },
  })

  useEffect(() => {
    setSelectedUser(null)
  }, [id])

  const [createPowerGenerator, { loading: createIsLoading }] = useCreateAffiliatePartnerUserMutation({
    onCompleted: (data) => {
      setUsers(prev => [data.createAffiliatePartnerUser, ...prev])
      toast.success('Uma novo usuário foi cadastrado!')
      navigate('/app/users')
    },
    onError: (error) => {
      toast.error(error.message)
    }
  })
  const [updatePowerGenerator, { loading: updateIsLoading }] = useUpdateAffiliatePartnerUserMutation({
    onCompleted: (data) => {
      setUsers(prev => prev.map(item => item._id === data.updateAffiliatePartnerUser._id ? data.updateAffiliatePartnerUser : item))
      setSelectedUser(null)
      toast.success(`O usuário ${data.updateAffiliatePartnerUser.name} foi alterado!`)
      navigate('/app/users')
    },
    onError: (error) => {
      toast.error(error.message)
    }
  })

  function onSubmit(data: FormData) {
    const createUser: AffiliatePartnerUserCreateInput = {
      email: data.email,
      name: data.name,
      phone: data.phone
    }
    const updateUser: AffiliatePartnerUserUpdateInput = {
      email: data.email,
      name: data.name,
      phone: data.phone,
      status: data.status
    }

    if(id && selectedUser) {
      updatePowerGenerator({ variables: { params: updateUser, id } })
    } else {
      createPowerGenerator({ variables: { params: createUser } })
    }
  } 

  useEffect(() => {
    if(selectedUser) {
      formMethods.setValue('name', selectedUser.name)
      formMethods.setValue('email', selectedUser.email)
      formMethods.setValue('phone', selectedUser?.phone || '')
      formMethods.setValue('status', selectedUser.status)
    } else {
      formMethods.reset()
    }
  }, [selectedUser])

  const isEdit = Boolean(id && selectedUser?._id)
  const isPendingUser = selectedUser?.status === EAffiliatePartnerUserStatus.pending

  if(getAffiliatePartnerUserIsLoading) return <FullPageLoader />

  return (
    <Box>
      <Card>
        <ContentTitle
          title={isEdit ? `Alterar usuário ${selectedUser?.name}` : 'Cadastrar usuários'}
          style={{ padding: '0px 0px 1.6rem 0px' }}
          description={`Complete os dados para ${isEdit ? 'alterar' : 'cadastrar'} um usuário`}
          breadcrumbLinks={{ currentLink: isEdit ? 'Alterar usuários' : 'Cadastrar usuários', links: [{ href: '/app/users', label: 'Usuários' }] }}
        />
        {isPendingUser && 
          <InfoBanner type='warning' text='Usuário pendente não pode ser editado!' />
        }

        <Divider sx={{ marginBottom: '2rem' }} />

        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit((data) => onSubmit(data))} style={{ display: 'flex', flexFlow: 'column', gap: '1rem' }}>
            <Input label='Nome' name='name' />
            <Input label='E-Mail' name='email' />
            <Input mask='phone' label='Telefone' name='phone' />
            {isEdit && (
              <Box>
                <Radio row name='status' options={userStatusOptions.filter(item => item.value !== EAffiliatePartnerUserStatus.pending)} />
              </Box>
            )}

            <Box sx={{ 
              width: '100%', 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'space-between', 
              flexFlow: isLowerSm ? 'column-reverse' : 'row', 
              gap: isLowerSm ? '1rem' : '0' 
            }}>
              <Link style={{ display: 'block', maxWidth: isLowerSm ? '100%' : '160px', width: '100%' }} to='/app/users'>
                <Button 
                  disabled={createIsLoading || updateIsLoading} 
                  fullWidth
                  type='button' 
                  color='secondary'
                >
                  Cancelar
                </Button>
              </Link>
              <Button
                disabled={createIsLoading || updateIsLoading || isPendingUser}
                sx={{ maxWidth: isLowerSm ? '100%' : '220px' }} 
                fullWidth 
                type='submit' 
                startIcon={<IconCheck />}>
                {isEdit ? 'Alterar usuário' : 'Cadastrar usuário'}
              </Button>
            </Box>
          </form>
        </FormProvider>
      </Card>
    </Box>
  )
}
