import { Box } from '@mui/material'
import { SubAffiliateHome } from './components'

export const Home: React.FC = () => {
  return (
    <Box padding={0} display='flex' flexDirection='column'>
      <SubAffiliateHome />
    </Box>
  )
}
